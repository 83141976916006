import React, { useRef, useState } from "react";
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import { useTransition, useTransitionHistory } from 'react-route-transition';
import * as Yup from 'yup';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Popover,
  PopoverBody
} from "reactstrap";

import useAuth from '../../../hooks/useAuth';

import { FaRegEnvelope } from 'react-icons/fa';
import { FiLock } from 'react-icons/fi';

import { GlobalStyle, Logo } from './styles';

import LoginNavbar from '../../../components/Navbars/LoginNavbar';
import SimpleFooter from '../../../components/Footers/SimpleFooter'

import logoDefault from '../../../assets/img/logo/logo-orcoma-default.png';
import logoTaskDayV9 from '../../../assets/img/logo/logo-taskday-v9.png';

const Login = () => {

  const history = useTransitionHistory();

  const inputEmail    = useRef<HTMLInputElement>(null);
  const inputPassword = useRef<HTMLInputElement>(null);

  const [inputEmailErrorColor, setInputEmailErrorColor] = useState('');
  const [popoverEmailOpen, setPopoverEmailOpen] = useState(false);
  const [popoverEmailMessage, setPopoverEmailMessage] = useState('');

  const [inputPasswordErrorColor, setInputPasswordErrorColor] = useState('');
  const [popoverPasswordOpen, setPopoverPasswordOpen] = useState(false);
  const [popoverPasswordMessage, setPopoverPasswordMessage] = useState('');

  const [popoverSubmitOpen, setPopoverSubmitOpen] = useState(false);
  const [popoverSubmitText, setPopoverSubmitText] = useState('');

  const { signIn } = useAuth();

  interface SignInFormData {
    email: string;
    password: string;
  }
  
  useTransition({
    handlers: [
      {
        from: '/',
        to: '/dashboard',
        onLeave: async () => {
          await gsap.timeline().to('[data-login-container] > *, [data-login-links]', {
            duration: 0.6,
            stagger: 0.125,
            opacity: 0,
            y: -20,
          })
        }
      },
      {
        from: '/',
        to: '/resetar-senha',
        onLeave: async () => {
          await gsap.timeline().to('[data-login-container] > *, [data-login-links]', {
            duration: 0.3,
            stagger: 0.125,
            opacity: 0,
            xPercent: -100,
          })
        }
      },
      {
        from: '/resetar-senha',
        to: '/',
        onEnter: async () => {
          await gsap.timeline().to('[data-login-container] > *, [data-login-links]', {
            duration: 0.3,
            stagger: 0.125,
            opacity: 0,
            yPercent: 100,
          }).reverse(0.3);
        },
      },
      {
        from: '/registrar-usuario',
        to: '/',
        onEnter: async () => {
          await gsap.timeline().to('[data-login-container] > *, [data-login-links]', {
            duration: 0.3,
            stagger: 0.125,
            opacity: 0,
            yPercent: 100,
          }).reverse(0.3);
        },
      },
      {
        from: '/',
        to: '/registrar-usuario',
        onLeave: async () => {
          await gsap.timeline().to('[data-login-container] > *, [data-login-links]', {
            duration: 0.3,
            stagger: 0.125,
            opacity: 0,
            y: -20,
          });
        }
      },
    ],
  });

  async function onBlurEmail() {
    setPopoverEmailOpen(false);
  }

  async function onBlurPassword() {
    setPopoverPasswordOpen(false);
  }

  async function onChangeEmail() {

    const email = inputEmail.current?.value;

    try {
      const schema = Yup.string()
      .required('e-mail não pode ficar em branco')
      .email('parece que este não é um e-mail válido');

      await schema.validate(email);
      setInputEmailErrorColor('has-success');
      setPopoverEmailOpen(false);
      setPopoverEmailMessage('');

    } catch (err) {
      setInputEmailErrorColor('has-danger');
      setPopoverEmailOpen(true);
      setPopoverEmailMessage(err.message);
    }
  }

  async function onChangePassword() {

    const password     = inputPassword.current?.value;

    try {
      const schema = Yup.string()
      .min(6, 'a senha precisa ter, no mínimo, 6 caracteres');

      await schema.validate(password);
      setInputPasswordErrorColor('has-success');
      setPopoverPasswordOpen(false);
      setPopoverPasswordMessage('');

    } catch (err) {
      setInputPasswordErrorColor('has-danger');
      setPopoverPasswordOpen(true);
      setPopoverPasswordMessage(err.message);
    }
  }

  async function handleSubmit() {

    const email     = inputEmail.current!.value;
    const password  = inputPassword.current!.value;

    const data:SignInFormData = {
      email,
      password
    }

    try {
      const schema = Yup.object().shape({
        email: 
          Yup.string()
          .required('e-mail não pode ficar em branco')
          .email('parece que este não é um e-mail válido'),
        password: 
          Yup.string()
          .min(6, 'a senha precisa ter, no mínimo, 6 caracteres')
      });

      await schema.validate(data, {abortEarly: false} );

      setPopoverSubmitOpen(false);
      
      signIn(data);
      
    }catch (err) {
      setPopoverSubmitOpen(true);
      setPopoverSubmitText('Há campos não preenchidos corretamente!');
    }

  }

  async function handleResetPassword(e: React.FormEvent) {
    e.preventDefault();
    history.push('/resetar-senha');
  }

  async function handleRegisterUser(e: React.FormEvent) {
    e.preventDefault();
    history.push('/registrar-usuario');
  }

  return (
    <React.Fragment>
      <GlobalStyle />
      <LoginNavbar />
      <section className="section section-shaped section-lg">
        <Container data-login-container className="pt-lg-7">
          <Row className="justify-content-center">
            <Col lg="5">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-white pb-3">
                  <div className="text-center">
                    <Logo src={logoTaskDayV9} />
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form">
                    <FormGroup className={'mb-3 ' + inputEmailErrorColor}>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <FaRegEnvelope/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input 
                          id="inputEmail" 
                          innerRef={inputEmail} 
                          onBlur={onBlurEmail} 
                          onChange={onChangeEmail} 
                          name="email" 
                          placeholder="Email" 
                          type="email" 
                        />
                        <Popover 
                          placement="top" 
                          isOpen={popoverEmailOpen} 
                          target="inputEmail"
                        >
                          <PopoverBody>
                            {popoverEmailMessage}
                          </PopoverBody>
                        </Popover>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className={'mb-3 ' + inputPasswordErrorColor}>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <FiLock/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input 
                          id="inputPassword"
                          innerRef={inputPassword} 
                          onBlur={onBlurPassword} 
                          onChange={onChangePassword} 
                          name="password" 
                          placeholder="Senha" 
                          type="password" 
                          autoComplete="off" 
                        />
                        <Popover 
                          placement="top" 
                          isOpen={popoverPasswordOpen} 
                          target="inputEmail"
                        >
                          <PopoverBody>
                            {popoverPasswordMessage}
                          </PopoverBody>
                        </Popover>
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button onClick={handleSubmit}
                        id="submitButton"
                        className="my-4"
                        color="primary"
                        type="button"
                      >
                        Entrar
                      </Button>
                      <Popover 
                        onClick={() => {setPopoverSubmitOpen(false)}}
                        placement="top" 
                        isOpen={popoverSubmitOpen} 
                        target="submitButton"
                      >
                        <PopoverBody>
                          { popoverSubmitText }
                        </PopoverBody>
                      </Popover>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row data-login-links className="mt-3">
                <Col className="text-light d-none" xs="6">
                  <Link className="text-light" to="#" onClick={ handleResetPassword } >
                    <small>Perdeu sua senha?</small>
                  </Link>
                </Col>
                <Col className="text-right d-none" xs="6">
                  <Link className="text-light" to="#" onClick={ handleRegisterUser } >
                    <small>Criar uma nova conta</small>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <SimpleFooter/>
    </React.Fragment>
  );
}

export default Login;