/*
  Componente Navbar baseado no UI Kit Argon
  Convertido de Class para => Hooks 🔥
*/
import React , { useState } from 'react';
import { Link } from 'react-router-dom';
import { GoBrowser } from 'react-icons/go';
import { FiPhone } from 'react-icons/fi';
import { 
  FaApple, 
  FaGooglePlay, 
  FaInstagram, 
  FaFacebook, 
  FaYoutube, 
} from 'react-icons/fa';

import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import logoWhite from '../../../assets/img/logo/logo-orcoma-white.png';
import logoDefault from '../../../assets/img/logo/logo-orcoma-default.png';

const LoginNavbar = () => {
  
  const [collapseClasses, setCollapseClasses]       = useState('');

  const onExiting = () => {
    setCollapseClasses('collapsing-out');
  }

  const onExited = () => {
    setCollapseClasses('');
  }

  return (
    <>
      <header className="header-global">
        <Navbar
          className="navbar-main navbar-transparent navbar-light headroom"
          expand="lg"
          id="navbar-main"
        >
          <Container>
            <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
              <img
                alt="Logo"
                src={logoWhite}
              />
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar_global">
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse
              toggler="#navbar_global"
              navbar
              className={collapseClasses}
              onExiting={onExiting}
              onExited={onExited}
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">
                      <img
                        alt="..."
                        src={logoDefault}
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <Button className="navbar-toggler" id="navbar_global">
                      <span />
                      <span />
                    </Button>
                  </Col>
                </Row>
              </div>
              <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle nav>
                    <span className="nav-link-inner--text"> Institucional</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem href="https://orcoma.com.br" target="_blank" tag={'a'}>
                      <GoBrowser/> Website
                    </DropdownItem>
                    <DropdownItem href="https://orcoma.com.br/contato" target="_blank" tag={'a'}>
                      <FiPhone/> Contato
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
              <Nav className="align-items-lg-center ml-lg-auto" navbar>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="https://www.facebook.com/orcomacontabilidade"
                    id="tooltip333589074"
                    target="_blank"
                  >
                    <FaFacebook/>
                    <span className="nav-link-inner--text d-lg-none ml-2">
                      Facebook
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip333589074">
                    Siga-nos no Facebook
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="https://www.instagram.com/orcomacontabilidade"
                    id="tooltip356693867"
                    target="_blank"
                  >
                    <FaInstagram/>
                    <span className="nav-link-inner--text d-lg-none ml-2">
                      Instagram
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip356693867">
                    Siga-nos no Instagram
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="https://youtube.com/orcomacontabilidade"
                    id="tooltip184698705"
                    target="_blank"
                  >
                    <FaYoutube/>
                    <span className="nav-link-inner--text d-lg-none ml-2">
                      YouTube
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip184698705">
                    Siga-nos no YouTube
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem className="d-none d-lg-block ml-lg-4">
                  <Button
                    className="d-none btn-neutral btn-icon"
                    color="default"
                    href="#"
                  >
                    <span className="btn-inner--icon">
                      <FaApple />
                    </span>
                    <span className="nav-link-inner--text ml-1">
                      app iOS
                    </span>
                  </Button>
                  <Button
                    className="d-none btn-neutral btn-icon"
                    color="default"
                    href="#"
                    target="_blank"
                  >
                    <span className="btn-inner--icon">
                      <FaGooglePlay />
                    </span>
                    <span className="nav-link-inner--text ml-1">
                      Android
                    </span>
                  </Button>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
}

export default LoginNavbar;