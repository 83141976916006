import Axios from 'axios';

const mountBaseUrl = () => {
  const env = process.env.NODE_ENV;
  if ( env === 'development' ) {
    return 'http://localhost:3333'
  }
  return 'https://api.orcoma.com.br:8080'
}

const api = Axios.create({
  baseURL: mountBaseUrl()
});

export default api;