import React, { useCallback, useState, useEffect } from 'react';

import swal from 'sweetalert';

import AuthContext, { User } from '../context/AuthContext';

import api from '../services/api';

import history from '../utils/history';

interface AuthState {
  token: string;
  user: User;
}

const AuthProvider: React.FC = ({ children }) => {

  const [authenticated, setAuthenticated] = useState(false);
  const [profilePic, setProfilePic] = useState({ source: '' });

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@OrcomaApp:token');
    const user = localStorage.getItem('@OrcomaApp:user');

    if (token && user) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      setAuthenticated(true);
      return { token, user: JSON.parse(user) };
    }

    setAuthenticated(false);
    return {} as AuthState;

  });

  const signIn = useCallback(async ({ email, password }) => {
    
    try {

      const response = await api.post('auth', {
        email,
        password
      });
  
      const { token, user } = response.data;
  
      localStorage.setItem('@OrcomaApp:token', token);
      localStorage.setItem('@OrcomaApp:user', JSON.stringify(user));

      api.defaults.headers.Authorization = `Bearer ${token}`;

      setData({ token, user });
      setAuthenticated(true);
      
      history.push('/dashboard');

    } catch (err: any) {
      setAuthenticated(false);

      if (err.response) {
        if (err.response.status === 401) {
          swal("Ooops! 401!", "E-mail ou senha incorretos ou inexistentes.", "error");
        }else {
          swal(
            `Ooops! ${ err.response.status }!`, 
            `Ocorreu um erro do tipo ${ err.response.status }.`, 
            "error"
          );
        }
      }else {
        swal("Ooops! :(",`${err}`, "error");
      }

    }

  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@OrcomaApp:token');
    localStorage.removeItem('@OrcomaApp:user',);

    api.defaults.headers.Authorization = undefined;
    setAuthenticated(false);

    history.push('/');

    setData( {} as AuthState );
  }, []);

  const refreshToken = useCallback((newToken) => {

    localStorage.setItem('@OrcomaApp:token', newToken);
    api.defaults.headers.Authorization = `Bearer ${newToken}`;

    setData({ token: newToken, user: data.user });

  }, [data.user]);
  
  useEffect(() => {
    const getProfilePic = async() => {
      try {
        const response = await api.get(data.user.profile_pic, { responseType: 'arraybuffer' });
        const base64 = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
        );
        setProfilePic({ source: "data:;base64," + base64 });
        
        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }
      }catch(err: any) {
        if (err.response && err.response.status === 401) {
          signOut();
        }
      }
    }
    getProfilePic();
  }, [data, refreshToken, signOut]);

  return (
    <AuthContext.Provider value={
      { 
        token: data.token, 
        user: data.user, 
        authenticated, 
        profilePicBase64: profilePic,
        signIn, 
        signOut,
        refreshToken
      }
    }>
      { children }
    </AuthContext.Provider>
  );
};

export default AuthProvider;