import styled from 'styled-components';
import { Toast } from 'reactstrap';

interface IProps {
  isDone: boolean;
}

export const Task = styled.div`
  margin-bottom: 15px;
  cursor: pointer;
  opacity: ${({ isDone }:IProps) => isDone ? 0.6 : 1 };
`;

export const CustomToast = styled(Toast)`
  max-width: none; 
`;