import React from 'react';
import {
  Card
} from 'reactstrap';
import { IconType } from 'react-icons/lib';

import { ChecklistCardBody } from './styles';

interface IProps {
  icon: IconType,
  statusColor: string;
  children: JSX.Element;
}

const ChecklistTaskContainer = (props: IProps) => {

  return (
    <Card id="task-w" className="card-stats shadow bg-white mb-4 mb-xl-2">
      <ChecklistCardBody className="overflow-auto">

        { props.children }
        
      </ChecklistCardBody>
    </Card>
  );
  
};

export default ChecklistTaskContainer;