import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: #32325d;
  }
`;

export const Logo = styled.img`
  width: 170px;
  height: 34px;
`;