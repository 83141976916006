import React, { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { useTransition, useTransitionHistory } from 'react-route-transition';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  FormText,
  UncontrolledAlert
} from 'reactstrap';
import swal from 'sweetalert';

import axios from "axios";

import MUIDataTable from "mui-datatables";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from "date-fns/locale/pt-BR";

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import classnames from 'classnames';

import * as Yup from 'yup';

import useAuth from '../../../hooks/useAuth';
import api from '../../../services/api';
import { GlobalStyle } from './styles';

import DefaultNavbar from '../../../components/Navbars/DefaultNavbar';
import GradientFooter from '../../../components/Footers/GradientFooter';
import TasksNumbersCard from '../../../components/Cards/TasksNumbersCard';

import { FiUsers } from 'react-icons/fi';
import { BsPlusCircle, BsCardText } from 'react-icons/bs';
import { MdTitle } from 'react-icons/md';
import { SiGooglecalendar } from 'react-icons/si';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { User } from '../../../context/AuthContext';

interface Client {
  id: string;
  company_name: string;
  fantasy_name: string;
  cnpj: string;
  address: string;
  neighbourhood: string;
  logo: string;
  city: string;
  uf: string;
  person_type: string;
  client_type: string;
  created_at: Date;
  updated_at: Date;
}

interface PersonService {
  id: string;
  observations: string;
  created_at: Date;
  updated_at: Date;
  service: Service;
  person: Client;
  users: User[];
  customUsersStringList?: string;
  customServiceTitle?: string;
}

interface Service {
  id: string;
  title: string;
  created_at: Date;
  updated_at: Date;
}

interface IBGEUFResponse {
  sigla: string;
}

interface IBGECityResponse {
  nome: string;
}

interface SelectDataFormat {
  value: string;
  label: string;
}

const Clients = () => {

  const history = useTransitionHistory();

  const { signOut, refreshToken } = useAuth();

  const inputCompanyNameRef = useRef<HTMLInputElement>(null);
  const inputFantasyNameRef = useRef<HTMLInputElement>(null);
  const inputCNPJRef = useRef<HTMLInputElement>(null);
  const inputAddressRef = useRef<HTMLInputElement>(null);
  const inputNeighbourhoodRef = useRef<HTMLInputElement>(null);
  const inputPhoneRef = useRef<HTMLInputElement>(null);
  const inputEmailRef = useRef<HTMLInputElement>(null);
  const inputIsActiveRef = useRef<HTMLInputElement>(null);

  const inputServiceObservationsRef = useRef<HTMLInputElement>(null);

  const [clients, setClients] = useState<Client[]>([{}] as Client[]);

  const [services, setServices] = useState<SelectDataFormat[]>([] as SelectDataFormat[]);
  const [selectedService, setSelectedService] = useState<SelectDataFormat | null>(null);

  const [personServices, setPersonServices] = useState<PersonService[]>([] as PersonService[]);

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const [modalPersonServics, setModalPersonServices] = useState(false);
  const toggleModalPersonServices = () => setModalPersonServices(!modalPersonServics);
  
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [isUpdatePersonService, setIsUpdatePersonService] = useState(false);
  const [isSavingPersonService, setIsSavingPersonService] = useState(false);

  const [avaliableUsers, setAvaliableUsers] = useState<SelectDataFormat[]>([] as SelectDataFormat[]);
  const [selectedUsersPersonService, setSelectedUsersPersonService] = useState<SelectDataFormat[]>([] as SelectDataFormat[]);

  const [personId, setPersonId] = useState<string | null>(null);
  const [personServiceId, setPersonServiceId] = useState<string | null>(null);

  const [ufs, setUfs] = useState<SelectDataFormat[]>([{}] as SelectDataFormat[]);
  const [selectedUf, setSelectedUf] = useState<SelectDataFormat | null>(null);

  const [cities, setCities] = useState<SelectDataFormat[]>([{}] as SelectDataFormat[]);
  const [selectedCity, setSelectedCity] = useState<SelectDataFormat | null>(null);

  const [selectedPersonServiceStartDate, setSelectedPersonServiceStartDate] = useState<Date | null>(null);
  const [selectedPersonServiceEndDate, setSelectedPersonServiceEndDate] = useState<Date | null>(null);

  const [activeTab, setActiveTab] = useState('1');

  const [selectedFoundationDate, setSelectedFoundationDate] = useState<Date | null>(null);

  const [clientTypes, setClientsTypes] = useState<SelectDataFormat[]>([
    { value: 'camara-municipal', label: 'Câmara Municipal' }, { value: 'prefeitura', label: 'Prefeitura' }
  ] as SelectDataFormat[]);
  const [selectedClientType, setSelectedClientType] = useState<SelectDataFormat | null>(null);

  const animatedComponents = makeAnimated();

  const getClients = async() => {
    try{
      const response = await api.get('persons/types/cliente');
      
      setClients(response.data);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

    }catch(err: any){
      if (err.response && err.response.status === 401) {
        signOut();
      }
    }
  }

  const getUsers = async () => {

    try{

      const response = await api.get('users');

      setAvaliableUsers(() => {
        
        const data: User[] = response.data;
        const dataForUsersPersonService: SelectDataFormat[] = [];

        data.map(user => {
          dataForUsersPersonService.push( {value: user.id, label: `@${user.username}`} );
        });

        return dataForUsersPersonService;
      });

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

    }catch(err: any){
      if (err.response && err.response.status === 401) {
        signOut();
      }
    }

  }

  const getServices = async() => {
    try{
      const response = await api.get<Service[]>('services');

      const services: SelectDataFormat[] = [];
      response.data.map((service) => {
        services.push({value: service.id, label: service.title});
      });
      setServices(services);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

    }catch(err: any){
      if (err.response && err.response.status === 401) {
        signOut();
      }
    }
  }

  const getPersonServices = async(customPersonId?: String) => {

    if (customPersonId) {
      try {
        const response = await api.get<PersonService[]>(`person-services?person_id=${customPersonId}`);
        
        const data: PersonService[] = response.data;
        data.map((personService) => {
          personService.customServiceTitle = personService.service.title;
          personService.users.map((user) => {
            personService.customUsersStringList
            ? personService.customUsersStringList = personService.customUsersStringList + `@${user.username}; `
            : personService.customUsersStringList = `@${user.username}; `
          });
        });

        setPersonServices(data);
  
        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }
  
      }catch(err: any){
        if (err.response && err.response.status === 401) {
          signOut();
        }
      }

      return;
    }

    try {
      const response = await api.get<PersonService[]>(`person-services?person_id=${personId}`);

      const data: PersonService[] = response.data;
      data.map((personService) => {
        personService.customServiceTitle = personService.service.title;
        personService.users.map((user) => {
          personService.customUsersStringList
          ? personService.customUsersStringList = personService.customUsersStringList + `@${user.username}; `
          : personService.customUsersStringList = `@${user.username}; `
        });
      });

      setPersonServices(data);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

    }catch(err: any){
      if (err.response && err.response.status === 401) {
        signOut();
      }
    }
  }
  
  useEffect(() => {

    getClients();
    getServices();
    getUsers();

  }, [signOut, refreshToken]);

  useTransition({
    handlers: [
      {
        path: '/contatos-e-clientes/clientes',
        onEnter: async () => {
          await gsap.timeline().fromTo
          ( 
            '[data-clients-main]',  
            { opacity: 0, y: 20 },  
            { duration: 0.6, stagger: 0.125, y: 0, opacity: 1 }
          ) 
        },
        onLeave: async () => {
          await gsap.timeline().to('[data-clients-main]', {
            duration: 0.6,
            stagger: 0.125,
            opacity: 0,
            y: -20,
          })
        },
      },
    ],
  });

  const getUfsFromIBGE = () => {
    setCities([{}] as SelectDataFormat[]);
    setSelectedCity(null);
    axios
    .get<IBGEUFResponse[]>(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
    )
    .then((response) => {
      const ufs: SelectDataFormat[] = [];
      response.data.map((uf) => {
        ufs.push({value: uf.sigla, label: uf.sigla});
      });
      setUfs(ufs);
    });
  };

  const getCitiesFromIBGE = () => {
    if (ufs) {
      axios
      .get<IBGECityResponse[]>(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf?.value}/municipios`
      )
      .then((response) => {
        const cities: SelectDataFormat[] = [];
        response.data.map((city) => {
          cities.push({value: city.nome, label: city.nome});
        });
        setCities(cities);
      });
    }
  };

  const handleChangeSelectUf = (selectedOption: SelectDataFormat | null) => {
    if (selectedOption !== undefined && selectedOption !== null) {
      setSelectedUf(selectedOption);
    }
  }

  const handleChangeSelectCity = (selectedOption: SelectDataFormat | null) => {
    if (selectedOption !== undefined && selectedOption !== null) {
      setSelectedCity(selectedOption);
    }
  }

  const handleChangeSelectClientType = (selectedOption: SelectDataFormat | null) => {
    if (selectedOption !== undefined && selectedOption !== null) {
      setSelectedClientType(selectedOption);
    }
  }

  const toggle = (tab: string) => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const handleFoundationDateChange = (date: MaterialUiPickersDate) => {
    setSelectedFoundationDate(date);
  };

  const handlePersonServicesRowClicked = async (rowData: string[]) => {
    setIsUpdatePersonService(true);
    const rowPersonServiceId = rowData[0];

    setPersonServiceId(rowPersonServiceId);

    try {
      const response = await api.get(`person-services/${rowPersonServiceId}`);
      toggleModalPersonServices();

      const { id: serviceId, title: serviceTitle } = response.data.service;

      inputServiceObservationsRef.current!.value = response.data.observations;

      setSelectedService({ value: serviceId, label: serviceTitle });
      setSelectedPersonServiceStartDate(response.data.service_start_date);
      setSelectedPersonServiceEndDate(response.data.service_end_date);
      
      const selectedUsers = response.data.users;
      const dataForSelectedUsers: SelectDataFormat[] = [];
      selectedUsers.map((user: User) => {
        dataForSelectedUsers.push( {value: user.id, label: `@${user.username}`} );
      });
      setSelectedUsersPersonService(dataForSelectedUsers);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

    }catch(err: any) {
      console.log(err);
      swal("Oppss!", err, "error");
    }
  }

  const handleClientsRowClicked = async (rowData: string[]) => {
    setIsUpdate(true);
    const rowPersonId = rowData[0];
    setPersonId(rowPersonId);
    getPersonServices(rowData[0]);

    try {
      const response = await api.get(`persons/${rowPersonId}`);
      toggleModal();
      
      inputCompanyNameRef.current!.value = response.data.company_name;
      inputFantasyNameRef.current!.value = response.data.fantasy_name;
      inputCNPJRef.current!.value = response.data.cnpj;
      inputAddressRef.current!.value = response.data.address;
      inputNeighbourhoodRef.current!.value = response.data.neighbourhood;
      inputPhoneRef.current!.value = response.data.phone;
      inputEmailRef.current!.value = response.data.email;
      setSelectedCity({label: response.data.city, value: response.data.city});
      setSelectedUf({label: response.data.uf, value: response.data.uf});
      setSelectedFoundationDate(response.data.foundation_date);
      inputIsActiveRef.current!.checked = response.data.is_active;

      switch (response.data.client_type) {
        case 'prefeitura':
          setSelectedClientType({ value: 'prefeitura', label: 'Prefeitura' });
        break;
        case 'camara-municipal':
          setSelectedClientType({ value: 'camara-municipal', label: 'Câmara Municipal' });
        break;
      }

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }
    }catch(err: any) {
      swal("Oppss!", err, "error");
    }
  }

  const contacts = [{}];

  const handleNewClient = () => {
    setPersonServices([]);
    setIsUpdate(false);
    setSelectedUf(null);
    setSelectedCity(null);
    setSelectedFoundationDate(null);
    setSelectedClientType(null);
    toggleModal();
  }

  const handleNewService = () => {

    if (!isUpdate) {
      swal("Recurso sendo aprimorado :)", 'Por enquanto, só é possível adicionar serviços a uma entidade já existente (já cadastrada), mas logo será possível adicionar serviços durante o cadastro da entidade :)', "info");
      return;
    }

    toggleModalPersonServices();
    setIsUpdatePersonService(false);
    setSelectedService(null);
    setSelectedUsersPersonService([]);
    setSelectedPersonServiceStartDate(null);
    setSelectedPersonServiceEndDate(null);
  }

  const handleChangeSelectService = (selectedOption: SelectDataFormat | null) => {
    if (selectedOption !== undefined && selectedOption !== null) {
      setSelectedService(selectedOption);
    }
  }

  const handleChangeSelectUsersPersonService = (selectedOption: any) => {
    setSelectedUsersPersonService(selectedOption);
  }

  const handleSubmitPersonService = async() => {

    if (!selectedService) {
      swal("Oppss!", 'Nenhum serviço foi selecionado. Informe um serviço antes de salvar.', "error");
      return;
    }

    if (!selectedUsersPersonService.length) {
      swal("Oppss!", 'Você se esqueceu de informar quais pessoas realizam este serviço para esta entidade.', "error");
      return;
    }

    if (!selectedPersonServiceStartDate || !selectedPersonServiceEndDate){
      swal("Oppss!", 'As datas de início e / ou finalização destes serviços não foram configuradas. Por favor, informe-as e salve novamente :)', "error");
      return;
    }

    const users: [{ id?: string }] = [{}];
    selectedUsersPersonService.map((user) => {
      users.push({ id: user.value })
    })

    if (isUpdatePersonService) {

      const data = {
        observations: inputServiceObservationsRef.current!.value
      }

      setIsSavingPersonService(true);

      try {
        const response = await api.put(`person-services/${personServiceId}`, data);
        toggleModalPersonServices();
        swal("Tudo certo :)", "O serviço foi atualizado para este cliente.", "success");

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }
      }catch(err: any) {
        swal("Oppss!", err, "error");
      }
    }else{

      const data = {
        observations: inputServiceObservationsRef.current!.value,
        service: selectedService.value,
        person: personId,
        users,
        service_start_date: selectedPersonServiceStartDate,
        service_end_date: selectedPersonServiceEndDate
      }

      setIsSavingPersonService(true);

      try {
        const response = await api.post('person-services', data);
        toggleModalPersonServices();
        swal("Feito!", "O serviço foi configurado para este cliente!", "success");

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }
      }catch(err: any) {
        swal("Oppss!", err, "error");
      }
    }
    
    setIsSavingPersonService(false);

    getPersonServices();
    
  }

  const handleSubmit = async() => {

    setIsSaving(true);

    const data = {
      company_name: inputCompanyNameRef.current!.value,
      fantasy_name: inputFantasyNameRef.current!.value,
      cnpj: inputCNPJRef.current!.value,
      address: inputAddressRef.current!.value,
      neighbourhood: inputNeighbourhoodRef.current!.value,
      phone: inputPhoneRef.current!.value,
      email: inputEmailRef.current!.value,
      city: selectedCity?.value,
      uf: selectedUf?.value,
      foundation_date: selectedFoundationDate,
      person_type: "cliente",
      client_type: selectedClientType?.value,
      is_active: inputIsActiveRef.current!.checked
    }

    try {
      const schema = Yup.object().shape({
        company_name:
          Yup.string()
          .required('A Razão Social precisa ser informada'),
        fantasy_name:
          Yup.string()
          .required('O Nome Fantasia precisa ser informado'),
        cnpj:
          Yup.string()
          .required('Por favor, informe o CNPJ da entidade'),
        address:
          Yup.string()
          .required('Informe o endereço da entidade'),
        neighbourhood:
          Yup.string()
          .required('O bairro não foi informado'),
        uf:
          Yup.string()
          .required('É preciso informar a união federativa (UF) da entidade'),
        city:
          Yup.string()
          .required('É preciso informar a cidade da entidade'),
        foundation_date:
          Yup.date()
          .nullable(true)
          .required('A data de fundação da entidade não pode deixar de ser preenchida'),
        email: 
          Yup.string()
          .required('O e-mail não pode ficar em branco')
          .email('parece que este não é um e-mail válido'),
        phone: 
          Yup.string()
          .required('Um telefone precisa ser informado')
      });

      await schema.validate(data, {abortEarly: false} );

      if (isUpdate) {
        try {
          const response = await api.put(`persons/${personId}`, data);
          toggleModal();
          swal("Tudo certo :)", "O cliente foi atualizado!", "success");
  
          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }
        }catch(err: any) {
          swal("Oppss!", err, "error");
        }
      }else{
        try {
          const response = await api.post('persons', data);
          toggleModal();
          swal("Feito!", "O cliente foi incluído!", "success");
  
          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }
        }catch(err: any) {
          swal("Oppss!", err, "error");
        }
      }
      
      setIsSaving(false);
  
      getClients();
      
    }catch (err: any) {
      if (err.errors) {
        swal("Oppss!", err.errors[0], "error");
      }else {
        swal("Oppss! o servidor retornou o seguinte erro:", err, "error");
      }
      setIsSaving(false);
    }

  }

  const handlePersonServiceStartDateChange = (date: MaterialUiPickersDate) => {
    setSelectedPersonServiceStartDate(date);
  };

  const handlePersonServiceEndDateChange = (date: MaterialUiPickersDate) => {
    setSelectedPersonServiceEndDate(date);
  };

  return (
    <React.Fragment>
      <GlobalStyle /> 
      <DefaultNavbar />
      <div data-clients-main className="header pb-8 pt-5 pt-md-4">
        <Container fluid>

          <Modal isOpen={modalPersonServics} toggle={toggleModalPersonServices}>
            <ModalHeader toggle={toggleModalPersonServices}>
              { 
                isUpdatePersonService 
                ? 'Editar serviço para a entidade' 
                : 'Adicionar serviço para a entidade' 
              }
            </ModalHeader>
            <ModalBody>
              
              <Form role="form"> 
                <br/>

                <FormGroup className="mb-3">
                  <Select 
                    placeholder = "Selecione o serviço"
                    noOptionsMessage={() => "Sem mais opções"}
                    options={services} 
                    onChange={ handleChangeSelectService }
                    value={selectedService}
                    isDisabled={isUpdatePersonService}
                  />
                </FormGroup>

                <FormGroup className="mb-3">
                  <Select
                    options={avaliableUsers} 
                    placeholder="Quem realiza este serviço para esta entidade?" 
                    components={animatedComponents}
                    noOptionsMessage={ () => "Ninguém foi encontrado :(" }
                    isMulti
                    value={selectedUsersPersonService}
                    onChange={ handleChangeSelectUsersPersonService }
                    isDisabled={isUpdatePersonService}
                  />  
                </FormGroup>

                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <BsCardText/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      id="inputServiceObservations" 
                      innerRef={inputServiceObservationsRef} 
                      name="serviceObservations" 
                      placeholder="Faça observações ou instruções, se necessário." 
                      type="textarea"
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <SiGooglecalendar/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <MuiPickersUtilsProvider locale={ptBrLocale} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Este serviço inicia-se em..."
                        format="dd/MM/yyyy"
                        cancelLabel="Cancelar"
                        invalidDateMessage="Formato inválido"
                        value={selectedPersonServiceStartDate}
                        onChange={handlePersonServiceStartDateChange}
                        disabled={isUpdatePersonService}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <SiGooglecalendar/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <MuiPickersUtilsProvider locale={ptBrLocale} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="E será executado até..."
                        format="dd/MM/yyyy"
                        cancelLabel="Cancelar"
                        invalidDateMessage="Formato inválido"
                        value={selectedPersonServiceEndDate}
                        onChange={handlePersonServiceEndDateChange}
                        disabled={isUpdatePersonService}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </InputGroup>
                </FormGroup>

                {
                  isUpdatePersonService
                  ?
                  <UncontrolledAlert color="warning">
                    Por enquanto, só é possível editar as observações de um serviço executado, pois as informações aqui alteradas, alteram, também as tarefas criadas a partir dela. Mas em breve será possível fazer isto por aqui.
                  </UncontrolledAlert>
                  : null
                }

              </Form>

            </ModalBody>
            <ModalFooter>
              <Button color="primary" disabled={isSavingPersonService} onClick={handleSubmitPersonService}>
                { isSavingPersonService
                  ? <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> Salvando...
                    </>
                  : 'Salvar'
                }
              </Button>{' '}
              <Button color="secondary" onClick={toggleModalPersonServices}>Cancelar</Button>
            </ModalFooter>
          </Modal>

          <Modal className="modal-lg" isOpen={modal} toggle={toggleModal}>
            <ModalHeader className="bg-secondary" toggle={toggleModal}>
              { isUpdate ? 'Editar Cliente' : 'Adicionar Cliente' }
            </ModalHeader>
            <ModalBody className="bg-secondary">

              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    Cadastro
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    Contatos
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => { toggle('3'); }}
                  >
                    Serviços
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <Form role="form"> 
                        <br/>

                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <MdTitle/>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                              id="inputCompanyName"
                              innerRef={inputCompanyNameRef} 
                              name="company_name" 
                              placeholder="Razão Social" 
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <MdTitle/>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                              id="inputFantasyName"
                              innerRef={inputFantasyNameRef} 
                              name="fantasy_name" 
                              placeholder="Nome Fantasia" 
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <MdTitle/>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                              id="inputCNPJ"
                              innerRef={inputCNPJRef} 
                              name="cnpj" 
                              placeholder="CNPJ" 
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <MdTitle/>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                              id="inputAddress"
                              innerRef={inputAddressRef} 
                              name="address" 
                              placeholder="Endereço completo" 
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <MdTitle/>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                              id="inputNeighbourhood"
                              innerRef={inputNeighbourhoodRef} 
                              name="neighbourhood" 
                              placeholder="Bairro" 
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <MdTitle/>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                              id="inputPhone"
                              innerRef={inputPhoneRef} 
                              name="phone" 
                              placeholder="Telefone principal" 
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <MdTitle/>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                              id="inputEmail"
                              innerRef={inputEmailRef} 
                              name="email" 
                              placeholder="Email principal" 
                              type="email"
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup className="mb-3">
                          <Select 
                            placeholder = "Selecione o tipo de cliente"
                            noOptionsMessage={() => "Sem mais opções"}
                            options={clientTypes} 
                            onChange={ handleChangeSelectClientType }
                            value={selectedClientType}
                          />
                        </FormGroup>  

                        <FormGroup className="mb-3">
                          <Select 
                            placeholder = "Selecione o estado"
                            noOptionsMessage={() => "Sem mais opções"}
                            onFocus={getUfsFromIBGE}
                            options={ufs} 
                            onChange={handleChangeSelectUf}
                            value={selectedUf}
                          />
                        </FormGroup>

                        <FormGroup className="mb-3">
                          <Select 
                            placeholder = "Selecione a cidade"
                            noOptionsMessage={() => "UF não selecionada ou cidade não encontrada."}
                            onFocus={getCitiesFromIBGE}
                            options={cities} 
                            onChange={handleChangeSelectCity}
                            value={selectedCity}
                          />
                        </FormGroup>

                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <SiGooglecalendar/>
                              </InputGroupText>
                            </InputGroupAddon>
                            <MuiPickersUtilsProvider locale={ptBrLocale} utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                minDate="1500-01-01"
                                label="Data de fundação"
                                format="dd/MM/yyyy"
                                cancelLabel="Cancelar"
                                invalidDateMessage="Formato inválido"
                                value={selectedFoundationDate}
                                onChange={handleFoundationDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </InputGroup>
                        </FormGroup>

                        {
                          isUpdate
                          ? 
                          <FormGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <Label check>
                                <Input
                                  type="checkbox"
                                  innerRef={inputIsActiveRef}
                                />{' '}
                                <strong>Cliente habilitado?</strong> (desabilita-lo impede algumas funções)
                              </Label>
                            </InputGroup>
                          </FormGroup>
                          : null
                        }

                      </Form>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <MUIDataTable
                        title={"Contatos da Entidade"}
                        data={contacts}
                        columns={
                          [
                            {
                              name: "id",
                              label: "id",
                              options: {
                                filter: false,
                                display: "excluded"
                              }
                            },
                            {
                              name: "company_name",
                              label: "Nome",
                              options: {
                                filter: false,
                                sort: true
                              }
                            },
                            {
                              name: "cnpj",
                              label: "Telefone",
                              options: {
                              filter: false,
                              sort: false
                              }
                            },
                            {
                              name: "uf",
                              label: "Email",
                              options: {
                              filter: true,
                              sort: true
                              }
                            },
                            {
                              name: "city",
                              label: "Whatsapp",
                              options: {
                              filter: true,
                              sort: true
                              }
                            }
                          ]
                        }
                        options={
                          {
                            selectableRows: 'none',
                            onRowClick: (rowData: string[]) => {console.log(rowData)},
                            textLabels: {
                              body: {
                                noMatch: "Desculpe, nenhum registro encontrado",
                                toolTip: "Ordernar",
                                columnHeaderTooltip: (column: any) => `Ordernar por ${column.label}`
                              },
                              pagination: {
                                next: "Próxima Página",
                                previous: "Página Anterior",
                                rowsPerPage: "Registros por página:",
                                displayRows: "de",
                              },
                              toolbar: {
                                search: "Buscar",
                                downloadCsv: "Baixar CSV",
                                print: "Imprimir",
                                viewColumns: "Visualizar Colunas",
                                filterTable: "Filtrar Tabela",
                              },
                              filter: {
                                all: "Tudo",
                                title: "FILTROS",
                                reset: "LIMPAR",
                              },
                              viewColumns: {
                                title: "Exibir Colunas",
                                titleAria: "Exibir/Ocultar Colunas da Tabela",
                              },
                              selectedRows: {
                                text: "registro(s) selecionados",
                                delete: "Apagar",
                                deleteAria: "Apagar registros selecionados",
                              },
                            }
                          }
                        }
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <br/>
                      <Button size="sm" onClick={handleNewService} color="primary" type="button">
                        Informar serviço
                      </Button>
                      <br/><br/>
                      <MUIDataTable
                        title={"Serviços realizados nesta entidade"}
                        data={personServices}
                        columns={
                          [
                            {
                              name: "id",
                              label: "id",
                              options: {
                                filter: false,
                                display: "excluded"
                              }
                            },
                            {
                              name: "customServiceTitle",
                              label: "Serviço",
                              options: {
                                filter: true,
                                sort: true
                              }
                            },
                            {
                              name: "customUsersStringList",
                              label: "Executado por",
                              options: {
                              filter: true,
                              sort: false
                              }
                            }
                          ]
                        }
                        options={
                          {
                            selectableRows: 'none',
                            onRowClick: (rowData: string[]) => {handlePersonServicesRowClicked(rowData)},
                            textLabels: {
                              body: {
                                noMatch: "Desculpe, nenhum registro encontrado",
                                toolTip: "Ordernar",
                                columnHeaderTooltip: (column: any) => `Ordernar por ${column.label}`
                              },
                              pagination: {
                                next: "Próxima Página",
                                previous: "Página Anterior",
                                rowsPerPage: "Registros por página:",
                                displayRows: "de",
                              },
                              toolbar: {
                                search: "Buscar",
                                downloadCsv: "Baixar CSV",
                                print: "Imprimir",
                                viewColumns: "Visualizar Colunas",
                                filterTable: "Filtrar Tabela",
                              },
                              filter: {
                                all: "Tudo",
                                title: "FILTROS",
                                reset: "LIMPAR",
                              },
                              viewColumns: {
                                title: "Exibir Colunas",
                                titleAria: "Exibir/Ocultar Colunas da Tabela",
                              },
                              selectedRows: {
                                text: "registro(s) selecionados",
                                delete: "Apagar",
                                deleteAria: "Apagar registros selecionados",
                              },
                            }
                          }
                        }
                      />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </ModalBody>
            <ModalFooter className="bg-secondary">
              <Button color="primary" disabled={isSaving} onClick={handleSubmit}>
                { isSaving
                  ? <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> Salvando...
                    </>
                  : 'Salvar'
                }
              </Button>{' '}
              <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
            </ModalFooter>
          </Modal>

          <div className="header-body">
            <Row>
              <Col lg="9" xl="9">
              <MUIDataTable
                title={"Clientes"}
                data={clients}
                columns={
                  [
                    {
                      name: "id",
                      label: "id",
                      options: {
                        filter: false,
                        display: "excluded"
                      }
                    },
                    {
                      name: "company_name",
                      label: "Razão Social",
                      options: {
                        filter: false,
                        sort: true
                      }
                    },
                    {
                      name: "cnpj",
                      label: "CNPJ",
                      options: {
                       filter: false,
                       sort: false
                      }
                    },
                    {
                      name: "uf",
                      label: "UF",
                      options: {
                       filter: true,
                       sort: true
                      }
                    },
                    {
                      name: "city",
                      label: "Cidade",
                      options: {
                       filter: true,
                       sort: true
                      }
                    }
                  ]
                }
                options={
                  {
                    selectableRows: 'none',
                    onRowClick: (rowData: string[]) => {handleClientsRowClicked(rowData)},
                    textLabels: {
                      body: {
                        noMatch: "Desculpe, nenhum registro encontrado",
                        toolTip: "Ordernar",
                        columnHeaderTooltip: (column: any) => `Ordernar por ${column.label}`
                      },
                      pagination: {
                        next: "Próxima Página",
                        previous: "Página Anterior",
                        rowsPerPage: "Registros por página:",
                        displayRows: "de",
                      },
                      toolbar: {
                        search: "Buscar",
                        downloadCsv: "Baixar CSV",
                        print: "Imprimir",
                        viewColumns: "Visualizar Colunas",
                        filterTable: "Filtrar Tabela",
                      },
                      filter: {
                        all: "Tudo",
                        title: "FILTROS",
                        reset: "LIMPAR",
                      },
                      viewColumns: {
                        title: "Exibir Colunas",
                        titleAria: "Exibir/Ocultar Colunas da Tabela",
                      },
                      selectedRows: {
                        text: "registro(s) selecionados",
                        delete: "Apagar",
                        deleteAria: "Apagar registros selecionados",
                      },
                    }
                  }
                }
              />
              </Col>
              <Col lg="3" xl="3">
                <Button color="primary" onClick={handleNewClient}>
                  <BsPlusCircle/> Incluir Cliente
                </Button>
                <br/><br/>
                <TasksNumbersCard
                  onClick={() => { history.push('/meu-perfil') }}
                  title="Clientes" 
                  description="Clientes cadastrados no sistema"
                  icon={FiUsers} 
                  circleColor="primary"
                  tasksQuantity={ clients.length }
                />
              </Col>
            </Row>

          </div>
        </Container>
      </div>
      <GradientFooter/>
    </React.Fragment>
  );
}

export default Clients;