import React, { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { useTransition } from 'react-route-transition';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from 'reactstrap';
import swal from 'sweetalert';

import { Editor } from '@tinymce/tinymce-react';
import Select from 'react-select';

import MUIDataTable from "mui-datatables";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from "date-fns/locale/pt-BR";

import * as Yup from 'yup';

import useAuth from '../../hooks/useAuth';
import api from '../../services/api';
import { GlobalStyle } from './styles';

import DefaultNavbar from '../../components/Navbars/DefaultNavbar';
import GradientFooter from '../../components/Footers/GradientFooter';
import TasksNumbersCard from '../../components/Cards/TasksNumbersCard';

import { CgFileDocument } from 'react-icons/cg';
import { BsPlusCircle } from 'react-icons/bs';
import { MdTitle } from 'react-icons/md';
import { SiGooglecalendar } from 'react-icons/si';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface SelectDataFormat {
  value: string;
  label: string;
}

interface Client {
  id: string;
  fantasy_name: string;
}

interface VisitReport {
  id: string;
  title: string;
  report: string;
  visit_start_date: Date;
  visit_end_date: Date;
  visitStartDateLabel: string;
  visitEndDateLabel: string;
  created_at: string;
  updated_at: string;
  client: Client;
  clientName: string;
}

const VisitsReports = () => {

  const { signOut, refreshToken } = useAuth();

  const inputTitleRef = useRef<HTMLInputElement>(null);
  const inputEditorReportRef = useRef<Editor | null>(null);

  const [visitsReports, setVisitsReports] = useState<VisitReport[]>([{}] as VisitReport[]);
  const [clients, setClients] = useState<SelectDataFormat[]>([{}] as SelectDataFormat[]);
  const [selectedClient, setSelectedClient] = useState<SelectDataFormat | null>(null);

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [visitReportId, setVisitReportId] = useState<string | null>(null);

  const [selectedVisitStartDateHour, setSelectedVisitStartDateHour] = useState<Date | null>(null);
  const [selectedVisitEndDateHour, setSelectedVisitEndDateHour] = useState<Date | null>(null);

  const getVisitsReports = async() => {
    try{
      const response = await api.get('visits-reports');

      const data: VisitReport[] = response.data;

      data.map(visitReport => {
        visitReport.visitStartDateLabel = `${
          new Date(visitReport.visit_start_date).toLocaleDateString('pt-BR', {day: 'numeric', weekday: 'long', month: 'long', year: 'numeric'})} 
          às 
          ${new Date(visitReport.visit_start_date).toLocaleTimeString('pt-BR')}`;

          visitReport.visitEndDateLabel = `${
          new Date(visitReport.visit_end_date).toLocaleDateString('pt-BR', {day: 'numeric', weekday: 'long', month: 'long', year: 'numeric'})} 
          às 
          ${new Date(visitReport.visit_end_date).toLocaleTimeString('pt-BR')}`;

          visitReport.clientName = visitReport.client.fantasy_name;
      });
      
      setVisitsReports(data);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

    }catch(err: any){
      if (err.response && err.response.status === 401) {
        signOut();
      }
    }
  }

  const getClients = async() => {
    try{
      const response = await api.get<Client[]>('persons/types/cliente');

      const clients: SelectDataFormat[] = [];
      response.data.map((client) => {
        clients.push({value: client.id, label: client.fantasy_name});
      });
      setClients(clients);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

    }catch(err: any){
      if (err.response && err.response.status === 401) {
        signOut();
      }
    }
  }

  const handleChangeSelectClient = (selectedOption: SelectDataFormat | null) => {
    if (selectedOption !== undefined && selectedOption !== null) {
      setSelectedClient(selectedOption);
    }
  }
  
  useEffect(() => {

    getVisitsReports();

  }, [signOut, refreshToken]);

  useTransition({
    handlers: [
      {
        path: '/relatorios-de-visitas',
        onEnter: async () => {
          await gsap.timeline().fromTo
          ( 
            '[data-visits-reports-main]',  
            { opacity: 0, y: 20 },  
            { duration: 0.6, stagger: 0.125, y: 0, opacity: 1 }
          ) 
        },
        onLeave: async () => {
          await gsap.timeline().to('[data-visits-reports-main]', {
            duration: 0.6,
            stagger: 0.125,
            opacity: 0,
            y: -20,
          })
        },
      },
    ],
  });

  const handleVisitReportStartDateChange = (date: MaterialUiPickersDate) => {
    setSelectedVisitStartDateHour(date);
  };

  const handleVisitReportEndDateChange = (date: MaterialUiPickersDate) => {
    setSelectedVisitEndDateHour(date);
  };

  const handleVisitsReportsRowClicked = async (rowData: string[]) => {
    setIsUpdate(true);

    const rowVisitReportId = rowData[0];
    setVisitReportId(rowVisitReportId);

    try {
      const response = await api.get(`visits-reports/${rowVisitReportId}`);
      toggleModal();
      
      inputTitleRef.current!.value = response.data.title;
      setSelectedVisitStartDateHour(response.data.visit_start_date);
      setSelectedVisitEndDateHour(response.data.visit_end_date);
      setSelectedClient({ value: response.data.client.id, label: response.data.client.fantasy_name  });
      inputEditorReportRef.current!.editor?.setContent(response.data.report);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }
    }catch(err: any) {
      swal("Oppss!", err, "error");
    }
  }

  const handleNewVisitReport = () => {
    setIsUpdate(false);
    setSelectedVisitStartDateHour(null);
    setSelectedVisitEndDateHour(null);
    setSelectedClient(null);
    toggleModal();
  }

  const handleSubmit = async() => {

    setIsSaving(true);

    const data = {
      title: inputTitleRef.current!.value,
      report: inputEditorReportRef.current!.editor?.getContent({format: 'html'}),
      visit_start_date: selectedVisitStartDateHour,
      visit_end_date: selectedVisitEndDateHour,
      client: selectedClient?.value
    }

    console.log(data);

    try {

      const schema = Yup.object().shape({
        title:
          Yup.string()
          .required('O título (objetivo da visita) não pode ficar vazio.'),
        client:
          Yup.string()
          .required('O campo cliente precisa corresponder a um cliente'),
        report:
          Yup.string()
          .required('O relatório precisa ser preenchido'),
        visit_start_date:
          Yup.date()
          .nullable(true)
          .required('A data e hora do início do atendimento não podem deixar de ser informados.'),
        visit_end_date:
          Yup.date()
          .nullable(true)
          .required('A data e hora do fim do atendimento não podem deixar de ser informados.')
      });

      await schema.validate(data, {abortEarly: false} );

    }catch (err: any) {
      if (err.errors) {
        swal("Oppss!", err.errors[0], "error");
      }else {
        swal("Oppss! o servidor retornou o seguinte erro:", err, "error");
      }
      setIsSaving(false);
    }

    if (isUpdate) {
      try {
        const response = await api.put(`visits-reports/${visitReportId}`, data);
        toggleModal();
        swal("Tudo certo :)", "O relatório foi atualizado!", "success");

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }
      }catch(err: any) {
        swal("Oppss!", err, "error");
      }
    }else{
      try {
        const response = await api.post('visits-reports', data);
        toggleModal();
        swal("Feito!", "O relatório foi adicionado!", "success");

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }
      }catch(err: any) {
        swal("Oppss!", err, "error");
      }
    }
    
    setIsSaving(false);

    getVisitsReports();

  }

  return (
    <React.Fragment>
      <GlobalStyle /> 
      <DefaultNavbar />
      <div data-visits-reports-main className="header pb-8 pt-5 pt-md-4">
        <Container fluid>

          <Modal className="modal-lg" isOpen={modal} toggle={toggleModal}>
            <ModalHeader className="bg-secondary" toggle={toggleModal}>
              { isUpdate ? 'Editar Relatório de Visita' : 'Adicionar Relatório de Visita' }
            </ModalHeader>
            <ModalBody className="bg-secondary">

              <Row>
                <Col sm="12">
                  <Form role="form">

                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <MdTitle/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input 
                          id="inputTitle"
                          innerRef={inputTitleRef} 
                          name="title" 
                          placeholder="Qual o objetivo (título) da visita?" 
                          type="text"
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <Select 
                        placeholder = "Selecione o cliente visitado"
                        noOptionsMessage={() => "Sem mais opções"}
                        onFocus={getClients}
                        options={clients} 
                        onChange={handleChangeSelectClient}
                        value={selectedClient}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <SiGooglecalendar/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <MuiPickersUtilsProvider locale={ptBrLocale} utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Data de início"
                            format="dd/MM/yyyy"
                            cancelLabel="Cancelar"
                            invalidDateMessage="Formato inválido"
                            value={selectedVisitStartDateHour}
                            onChange={handleVisitReportStartDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <SiGooglecalendar/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <MuiPickersUtilsProvider locale={ptBrLocale} utils={DateFnsUtils}>
                          <KeyboardTimePicker
                            ampm={false}
                            margin="normal"
                            id="time-picker"
                            label="Horário de início"
                            cancelLabel="Cancelar"
                            invalidDateMessage="Formato inválido"
                            value={selectedVisitStartDateHour}
                            onChange={handleVisitReportStartDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <MdTitle/> | <strong>Editor de texto avançado</strong>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Editor
                          ref={inputEditorReportRef}
                          apiKey="6dz9v69p98t071mdjzgc7dfpxun4pii9ghb8rbaq8vavkm0m"
                          init={{
                            height: 500,
                            menubar: false,
                            placeholder: "Escreva aqui o seu relatório",
                            language: "pt_BR",
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                              'undo redo | formatselect | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat | fullscreen'
                          }}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <SiGooglecalendar/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <MuiPickersUtilsProvider locale={ptBrLocale} utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Data de conclusão"
                            format="dd/MM/yyyy"
                            cancelLabel="Cancelar"
                            invalidDateMessage="Formato inválido"
                            value={selectedVisitEndDateHour}
                            onChange={handleVisitReportEndDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <SiGooglecalendar/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <MuiPickersUtilsProvider locale={ptBrLocale} utils={DateFnsUtils}>
                          <KeyboardTimePicker
                            ampm={false}
                            margin="normal"
                            id="time-picker"
                            label="Horário de conclusão"
                            cancelLabel="Cancelar"
                            invalidDateMessage="Formato inválido"
                            value={selectedVisitEndDateHour}
                            onChange={handleVisitReportEndDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </InputGroup>
                    </FormGroup>

                  </Form>
                </Col>
              </Row>
              
            </ModalBody>
            <ModalFooter className="bg-secondary">
              <Button color="primary" disabled={isSaving} onClick={handleSubmit}>
                { isSaving
                  ? <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> Salvando...
                    </>
                  : 'Salvar'
                }
              </Button>{' '}
              <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
            </ModalFooter>
          </Modal>

          <div className="header-body">
            <Row>
              <Col lg="9" xl="9">
              <MUIDataTable
                title={"Meus relatórios de visitas"}
                data={visitsReports}
                columns={
                  [
                    {
                      name: "id",
                      label: "id",
                      options: {
                        filter: false,
                        display: "excluded"
                      }
                    },
                    {
                      name: "title",
                      label: "Objetivo da visita (título)",
                      options: {
                        filter: false,
                        sort: true
                      }
                    },
                    {
                      name: "visitStartDateLabel",
                      label: "Visita realizada em",
                      options: {
                       filter: false,
                       sort: false
                      }
                    },
                    {
                      name: "visitEndDateLabel",
                      label: "Visita finalizada em",
                      options: {
                       filter: false,
                       sort: false
                      }
                    },
                    {
                      name: "clientName",
                      label: "Entidade atendida",
                      options: {
                       filter: false,
                       sort: false
                      }
                    }
                  ]
                }
                options={
                  {
                    selectableRows: 'none',
                    onRowClick: (rowData: string[]) => {handleVisitsReportsRowClicked(rowData)},
                    textLabels: {
                      body: {
                        noMatch: "Desculpe, nenhum registro encontrado",
                        toolTip: "Ordernar",
                        columnHeaderTooltip: (column: any) => `Ordernar por ${column.label}`
                      },
                      pagination: {
                        next: "Próxima Página",
                        previous: "Página Anterior",
                        rowsPerPage: "Registros por página:",
                        displayRows: "de",
                      },
                      toolbar: {
                        search: "Buscar",
                        downloadCsv: "Baixar CSV",
                        print: "Imprimir",
                        viewColumns: "Visualizar Colunas",
                        filterTable: "Filtrar Tabela",
                      },
                      filter: {
                        all: "Tudo",
                        title: "FILTROS",
                        reset: "LIMPAR",
                      },
                      viewColumns: {
                        title: "Exibir Colunas",
                        titleAria: "Exibir/Ocultar Colunas da Tabela",
                      },
                      selectedRows: {
                        text: "registro(s) selecionados",
                        delete: "Apagar",
                        deleteAria: "Apagar registros selecionados",
                      },
                    }
                  }
                }
              />
              </Col>
              <Col lg="3" xl="3">
                <Button color="primary" onClick={handleNewVisitReport}>
                  <BsPlusCircle/> Incluir Relatório
                </Button>
                <br/><br/>
                <TasksNumbersCard 
                  title="Relatórios" 
                  description="Relatórios de visita"
                  icon={CgFileDocument} 
                  circleColor="primary"
                  tasksQuantity={ visitsReports.length }
                />
              </Col>
            </Row>

          </div>
        </Container>
      </div>
      <GradientFooter/>
    </React.Fragment>
  );
}

export default VisitsReports;