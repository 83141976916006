import React from "react";
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import { useTransition, useTransitionHistory } from 'react-route-transition';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import InputMask from "react-input-mask";

import { FaRegEnvelope } from 'react-icons/fa';
import { FiLock, FiPhone } from 'react-icons/fi';
import { AiOutlineUser } from 'react-icons/ai';

import { GlobalStyle, Logo } from './styles';

import LoginNavbar from '../../../components/Navbars/LoginNavbar';
import SimpleFooter from '../../../components/Footers/SimpleFooter'

import logoDefault from '../../../assets/img/logo/logo-orcoma-default.png';

const RegisterUser = () => {

  const history = useTransitionHistory();

  useTransition({
    handlers: [
      {
        path: '/registrar-usuario',
        onEnter: async () => {
          await gsap.timeline().to('[data-login-container] > *, [data-login-links]', {
            duration: 0.3,
            stagger: 0.125,
            opacity: 0,
            y: -20,
          }).reverse(0.3);
        },
        onLeave: async () => {
          await gsap.timeline().to('[data-login-container] > *, [data-login-links]', {
            duration: 0.3,
            stagger: 0.125,
            opacity: 0,
            yPercent: -100,
          })
        }
      }
    ],
  });
  
  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    history.push('/dashboard');
  }

  async function handleGoBackLogin(e: React.FormEvent) {
    e.preventDefault();
    history.push('/');
  }

  return (
    <React.Fragment>
      <GlobalStyle />
      <LoginNavbar />
      <section className="section section-shaped section-lg">
        <Container data-login-container className="pt-lg-7">
          <Row className="justify-content-center">
            <Col lg="5">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-white pb-3">
                  <div className="text-center">
                    <Logo src={logoDefault} />
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <p>
                    Nosso sistema é exclusivo para clientes e colaboradores. Se você é nosso cliente ou colaborador, solicite seu cadastro abaixo.
                  </p>
                  <Form onSubmit={handleSubmit} role="form">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <AiOutlineUser/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Nome Completo" type="text" />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <FaRegEnvelope/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email" type="email" />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <FiPhone/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input 
                          placeholder="Telefone"
                          type="tel"
                          mask="+5\5 (999)99999-9999"
                          maskChar=" "
                          tag={InputMask}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <FiLock/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Senha"
                          type="password"
                          autoComplete="off"
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <FiLock/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Confirmar senha"
                          type="password"
                          autoComplete="off"
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button onClick={handleSubmit}
                        className="my-4"
                        color="primary"
                        type="button"
                      >
                        Solicitar cadastro
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row data-login-links className="mt-3">
                <Col className="text-light" xs="6">
                  <Link className="text-light" to="#" onClick={ handleGoBackLogin } >
                    <small>Já tenho uma conta</small>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <SimpleFooter/>
    </React.Fragment>
  );
}

export default RegisterUser;