import React, { useState, useEffect } from 'react';
import gsap from 'gsap';
import { useTransition, useTransitionHistory } from 'react-route-transition';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from 'reactstrap';

import useAuth from '../../../hooks/useAuth';
import api from '../../../services/api';

import DefaultNavbar from '../../../components/Navbars/DefaultNavbar';
import GradientFooter from '../../../components/Footers/GradientFooter';
import TasksNumbersCard from '../../../components/Cards/TasksNumbersCard';

import { GlobalStyle } from './styles';
import { FiUsers, FiSearch } from 'react-icons/fi';
import { BsPlusCircle } from 'react-icons/bs';
import { MdTitle } from 'react-icons/md';

interface Stats {
  tasksWaiting: number;
  tasksInProgress: number;
  tasksArchived: number;
  tasksDone: number;
}

interface ChartTasksData {
  status: string;
  quantity: number;
}

interface ChartPrioritiesData {
  priority: string;
  quantity: number;
}

const MainDashboard = () => {

  const { signOut, refreshToken } = useAuth();

  const history = useTransitionHistory();

  const [stats, setStats] = useState<Stats>({} as Stats);
  const [chartTasks, setChartTasks] = useState<ChartTasksData[]>([]);
  const [chartPriorities, setChartPriorities] = useState<ChartPrioritiesData[]>([]);

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
  
    const getStats = async() => {
      try{
        const response = await api.get('tasks/stats');
        
        setStats(response.data.info);
        setChartTasks(response.data.byStatus);
        setChartPriorities(response.data.byPriority);

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }

      }catch(err){
        if (err.response && err.response.status === 401) {
          signOut();
        }
      }
    }

    getStats();

  }, [signOut, refreshToken]);

  useTransition({
    handlers: [
      {
        path: '/dashboard',
        onEnter: async () => {
          await gsap.timeline().fromTo
          ( 
            '[data-dashboard-main]',  
            { opacity: 0, y: 20 },  
            { duration: 0.6, stagger: 0.125, y: 0, opacity: 1 }
          ) 
        },
        onLeave: async () => {
          await gsap.timeline().to('[data-dashboard-main]', {
            duration: 0.6,
            stagger: 0.125,
            opacity: 0,
            y: -20,
          })
        },
      },
    ],
  });

  const data = [
    {id: 1, companyName: 'Lorem Ipsum Dolor', cnpj: '00.000.000/0000-00', uf: 'BA', city: 'Itaberaba'},
    {id: 2, companyName: 'Lorem Ipsum Dolor', cnpj: '00.000.000/0000-00', uf: 'BA', city: 'Itaberaba'},
    {id: 3, companyName: 'Lorem Ipsum Dolor', cnpj: '00.000.000/0000-00', uf: 'BA', city: 'Itaberaba'},
    {id: 4, companyName: 'Lorem Ipsum Dolor', cnpj: '00.000.000/0000-00', uf: 'BA', city: 'Itaberaba'},
    {id: 5, companyName: 'Lorem Ipsum Dolor', cnpj: '00.000.000/0000-00', uf: 'BA', city: 'Itaberaba'},
    {id: 6, companyName: 'Lorem Ipsum Dolor', cnpj: '00.000.000/0000-00', uf: 'BA', city: 'Itaberaba'},
    {id: 7, companyName: 'Lorem Ipsum Dolor', cnpj: '00.000.000/0000-00', uf: 'BA', city: 'Itaberaba'},
    {id: 8, companyName: 'Lorem Ipsum Dolor', cnpj: '00.000.000/0000-00', uf: 'BA', city: 'Itaberaba'},
    {id: 9, companyName: 'Lorem Ipsum Dolor', cnpj: '00.000.000/0000-00', uf: 'BA', city: 'Itaberaba'},
    {id: 10, companyName: 'Lorem Ipsum Dolor', cnpj: '00.000.000/0000-00', uf: 'BA', city: 'Itaberaba'},
    {id: 11, companyName: 'Lorem Ipsum Dolor', cnpj: '00.000.000/0000-00', uf: 'BA', city: 'Itaberaba'},
    {id: 12, companyName: 'Lorem Ipsum Dolor', cnpj: '00.000.000/0000-00', uf: 'BA', city: 'Itaberaba'},
  ];

  const columns = [
    {
      name: 'Razão Social',
      selector: 'companyName',
      sortable: true
    },
    {
      name: 'CNPJ',
      selector: 'cnpj',
      sortable: false
    },
    {
      name: 'UF',
      selector: 'uf',
      sortable: true,
    },
    {
      name: 'Cidade',
      selector: 'city',
      sortable: true,
      right: true
    },
  ];

  return (
    <React.Fragment>
      <GlobalStyle /> 
      <DefaultNavbar />
      <div data-dashboard-main className="header pb-8 pt-5 pt-md-4">
        <Container fluid>

          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader className="bg-secondary" toggle={toggleModal}>
              { isUpdate ? 'Editar Contato' : 'Adicionar Contato' }
            </ModalHeader>
            <ModalBody className="bg-secondary">
              <Form role="form">

                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <MdTitle/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      id="inputCompanyName"
                      name="company_name" 
                      placeholder="Razão Social" 
                      type="text"
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <MdTitle/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      id="inputFantasyName"
                      name="fantasy_name" 
                      placeholder="Nome Fantasia" 
                      type="text"
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <MdTitle/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      id="inputCNPJ"
                      name="cnpj" 
                      placeholder="CNPJ" 
                      type="text"
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <MdTitle/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      id="inputAddress"
                      name="address" 
                      placeholder="Endereço completo" 
                      type="text"
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <MdTitle/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      id="inputUF"
                      name="uf" 
                      placeholder="UF" 
                      type="text"
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <MdTitle/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      id="inputCity"
                      name="city" 
                      placeholder="Cidade" 
                      type="text"
                    />
                  </InputGroup>
                </FormGroup>

              </Form>
            </ModalBody>
            <ModalFooter className="bg-secondary">
              <Button color="primary" disabled={isSaving}>
                { isSaving
                  ? <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> Salvando...
                    </>
                  : 'Salvar'
                }
              </Button>{' '}
              <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
            </ModalFooter>
          </Modal>

          <div className="header-body">
            <Row>
              <Col lg="9" xl="9">
                ...
              </Col>
              <Col lg="3" xl="3">
                <Button color="primary" onClick={toggleModal}>
                  <BsPlusCircle/> Incluir
                </Button>
                <br/><br/>
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FiSearch/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input 
                        id="inputSearch"
                        name="search" 
                        placeholder="Buscar contato" 
                        type="text"
                      />
                    </InputGroup>
                  </FormGroup>
                </Form>
                <TasksNumbersCard 
                  title="Outros Contatos" 
                  description="Contatos cadastrados no sistema"
                  icon={FiUsers} 
                  circleColor="primary"
                  tasksQuantity={ 12 }
                />
              </Col>
            </Row>

          </div>
        </Container>
      </div>
      <GradientFooter/>
    </React.Fragment>
  );
}

export default MainDashboard;