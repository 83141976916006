import React from 'react';
import DOMPurify from "dompurify";
import {
  Card,
  CardBody
} from 'reactstrap';

import { AiOutlineUser } from 'react-icons/ai';

import { Comment } from './styles';

interface IProps {
  username: string;
  comment: string;
  openAtLabel: string;
  onClick(): void;
}

const CommentCard = (props: IProps) => {

  const cleanHTML = DOMPurify.sanitize(props.comment, {
    USE_PROFILES: { html: true },
  });

  return (
    <Comment onClick={ props.onClick } >
      <Card className="card-stats shadow mb-4 mb-xl-0">
        <CardBody>
          <small className="text-muted text-sm">
            <AiOutlineUser/>
            <i>
              <strong> @{ props.username } </strong> comentou { props.openAtLabel }:
            </i>
          </small>
          <br/>
          <small className="text-muted text-sm">

          <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />

          </small>
        </CardBody>
      </Card>
    </Comment>
  );

}

export default CommentCard;