import React, { useState, useEffect } from 'react';
import gsap from 'gsap';
import { useTransition, useTransitionHistory } from 'react-route-transition';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody
} from 'reactstrap';

import {
  Chart,
  Interval,
  Tooltip,
  Coordinate,
  Axis
} from 'bizcharts';

import useAuth from '../../../hooks/useAuth';
import api from '../../../services/api';

import DefaultNavbar from '../../../components/Navbars/DefaultNavbar';
import GradientFooter from '../../../components/Footers/GradientFooter';
import TasksNumbersCard from '../../../components/Cards/TasksNumbersCard';

import { GlobalStyle } from './styles';
import { BsCheck, BsCheckAll } from 'react-icons/bs';
import { IoIosHourglass } from 'react-icons/io';

interface TaskStats {
  tasksWaiting: number;
  tasksInProgress: number;
  tasksArchived: number;
  tasksDone: number;
}

interface ChartPrioritiesTaskData {
  priority: string;
  quantity: number;
}

const ClientReportDashboard = () => {

  const { signOut, refreshToken } = useAuth();

  const history = useTransitionHistory();

  const [tasksStats, setTasksStats] = useState<TaskStats>({} as TaskStats);
  const [chartPrioritiesTasks, setChartPrioritiesTasks] = useState<ChartPrioritiesTaskData[]>([]);
  const [remindersExpiredAndNotMuted, setRemindersExpiredAndNotMuted] = useState(0);

  useEffect(() => {
  
    const getStats = async() => {
      try{
        const response = await api.get('stats');
        
        setTasksStats(response.data.tasksInfo);
        setChartPrioritiesTasks(response.data.tasksByPriority);
        setRemindersExpiredAndNotMuted(response.data.remindersInfo.remindersExpiredAndNotMuted);

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }

      }catch(err: any){
        if (err.response && err.response.status === 401) {
          signOut();
        }
      }
    }

    getStats();

  }, [signOut, refreshToken]);

  useTransition({
    handlers: [
      {
        path: '/dashboard',
        onEnter: async () => {
          await gsap.timeline().fromTo
          ( 
            '[data-dashboard-main]',  
            { opacity: 0, y: 20 },  
            { duration: 0.6, stagger: 0.125, y: 0, opacity: 1 }
          ) 
        },
        onLeave: async () => {
          await gsap.timeline().to('[data-dashboard-main]', {
            duration: 0.6,
            stagger: 0.125,
            opacity: 0,
            y: -20,
          })
        },
      },
    ],
  });

  const data = [
    { year: 'Administração', population: 4 },
    { year: 'Finanças', population: 7 },
    { year: 'Educação', population: 45 },
    { year: 'Infra Estrutura', population: 12 },
    { year: 'Saúde', population: 26 },
    { year: 'Demais Secretarias', population: 6 }
  ];

  const data2 = [
    { year: 'Receitas', population: 17004500.95 },
    { year: 'Despesas', population: 13228485.14 }
  ];

  return (
    <React.Fragment>
      <GlobalStyle /> 
      <DefaultNavbar />
      <div data-dashboard-main className="header pb-8 pt-5 pt-md-4">
        <Container fluid>
          <h2>Prefeitura Municipal de Demonstração</h2>
          <div className="header-body">
            <Row>
              <Col lg="6" xl="4">
                <TasksNumbersCard 
                  onClick={() => { history.push('/meu-perfil') }}
                  title="Empenhado (mês)" 
                  description="Total empenhado no mês atual"
                  icon={IoIosHourglass} 
                  circleColor="yellow"
                  tasksQuantity={ 3544326.41 }
                />
              </Col>
              <Col lg="6" xl="4">
                <TasksNumbersCard 
                  onClick={() => { history.push('/meu-perfil') }}
                  title="Liquidado (mês)" 
                  description="Total liquidado no mês atual"
                  icon={BsCheck} 
                  circleColor="primary"
                  tasksQuantity={ 3811541.08 }
                />
              </Col>
              <Col lg="6" xl="4">
                <TasksNumbersCard 
                  onClick={() => { history.push('/meu-perfil') }}
                  title="Pago (mês)" 
                  description="Total pago no mês atual"
                  icon={BsCheckAll} 
                  circleColor="success"
                  tasksQuantity={ 3802422.27 }
                />
              </Col>
            </Row>

            <br/>

            <Row>
              <Col lg="6" xl="4">
                <TasksNumbersCard 
                  onClick={() => { history.push('/meu-perfil') }}
                  title="Empenhado (até o mês)" 
                  description="Total empenhado até o mês atual"
                  icon={IoIosHourglass} 
                  circleColor="yellow"
                  tasksQuantity={ 31731835.64 }
                />
              </Col>
              <Col lg="6" xl="4">
                <TasksNumbersCard 
                  onClick={() => { history.push('/meu-perfil') }}
                  title="Liquidado (até o mês)" 
                  description="Total liquidado até mês atual"
                  icon={BsCheck} 
                  circleColor="primary"
                  tasksQuantity={ 13260749.38 }
                />
              </Col>
              <Col lg="6" xl="4">
                <TasksNumbersCard 
                  onClick={() => { history.push('/meu-perfil') }}
                  title="Pago (até o mês)" 
                  description="Total pago até o mês atual"
                  icon={BsCheckAll} 
                  circleColor="success"
                  tasksQuantity={ 13058528.77 }
                />
              </Col>
            </Row>

            <Row className="pt-md-4">
              <Col xl="8">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                          Despesas %
                        </h6>
                        <h4 className="mb-0">Percentual de despesas (empenhos) por secretária até o mês </h4>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* Chart */}
                    <div className="chart">
                      <Chart height={200} data={data} autoFit>
                        <Coordinate type="polar" />
                        <Axis visible={false} />
                        <Tooltip showTitle={false} />
                        <Interval
                          position="year*population"
                          adjust="stack"
                          element-highlight
                          color={"year"}
                          style={{
                            lineWidth: 1,
                            stroke: '#fff',
                          }}
                          label={['priority', {
                            offset: -15,
                          }]}
                        />
                      </Chart>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col className="mb-5 mb-xl-0" xl="4">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                          Despesas %
                        </h6>
                        <h6 className="mb-0">Percentual de despesas (empenhos) por secretária até o mês </h6>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* Chart */}
                    <div className="chart">
                      <Chart height={200} data={data2} autoFit>
                        <Coordinate type="rect" />
                        <Axis visible={false} />
                        <Tooltip showTitle={false} />
                        <Interval
                          position="year*population"
                          adjust="stack"
                          element-highlight
                          color={"year"}
                          style={{
                            lineWidth: 1,
                            stroke: '#fff',
                          }}
                          label={['priority', {
                            offset: -15,
                          }]}
                        />
                      </Chart>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>
        </Container>
      </div>
      <GradientFooter/>
    </React.Fragment>
  );
}

export default ClientReportDashboard;