import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { RouteTransitionProvider } from 'react-route-transition';

import useAuth from '../hooks/useAuth';

import Login from '../views/Users/Login';
import Users from '../views/Users/Users';
import TasksOfUser from '../views/Users/TasksOfUser';
import MainDashboard from '../views/Dashboards/MainDashboard';
import ClientReportDashboard from '../views/Dashboards/ClientReportDashboard';
import RegisterUser from '../views/Users/Register';
import ResetPassword from '../views/Users/ResetPassword';
import MyProfile from '../views/Users/MyProfile';
import MyTasks from '../views/Tasks/MyTasks';
import TaskTemplates from '../views/Tasks/TaskTemplates';
import Clients from '../views/Persons/Clients';
import OtherContacts from '../views/Persons/Others';
import Reminders from '../views/Reminders';
import VisitsReports from '../views/VisitsReports';
import Page404 from '../views/Errors/404';

interface CustomRouteProps {
  isPrivate?: boolean;
  exact?: boolean;
  path?: string;
  component: React.ComponentType;
}

function CustomRoute({ isPrivate, ...rest }:CustomRouteProps) {

  const { authenticated } = useAuth();

  if (isPrivate && !authenticated) {
    return <Redirect to="/" />
  }

  if (!isPrivate && authenticated) {
    return <Redirect to="/dashboard" />
  }

  return <Route {...rest} />;
}

const Routes = () => {
  
  return (
    <>
      
      <RouteTransitionProvider>
        <Switch>
          <CustomRoute component={Login} path='/' exact/>
          <CustomRoute component={RegisterUser} path='/registrar-usuario'/>
          <CustomRoute component={ResetPassword} path='/resetar-senha'/>
          <CustomRoute isPrivate component={Users} path='/usuarios'/>
          <CustomRoute isPrivate component={TasksOfUser} path='/tarefas-do-usuario/:username' exact/>
          <CustomRoute isPrivate component={MyProfile} path='/meu-perfil'/>
          <CustomRoute isPrivate component={MainDashboard} path='/dashboard'/>
          <CustomRoute isPrivate component={ClientReportDashboard} path='/dashboard-cliente-relatorios'/>
          <CustomRoute isPrivate component={MyTasks} path='/tarefas/minhas-tarefas'/>
          <CustomRoute isPrivate component={TaskTemplates} path='/tarefas/templates'/>
          <CustomRoute isPrivate component={Clients} path='/contatos-e-clientes/clientes'/>
          <CustomRoute isPrivate component={OtherContacts} path='/contatos-e-clientes/outros'/>
          <CustomRoute isPrivate component={Reminders} path='/meus-lembretes-e-compromissos'/>
          <CustomRoute isPrivate component={VisitsReports} path='/relatorios-de-visitas'/>
          <CustomRoute component={Page404} />
        </Switch>

      </RouteTransitionProvider>
      
    </>
  )
}
  
export default Routes;