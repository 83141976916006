import React, { useState, useEffect } from 'react';
import gsap from 'gsap';
import { useTransition, useTransitionHistory } from 'react-route-transition';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';

import {
  Chart,
  Interval,
  Tooltip,
  Coordinate,
  Axis
} from 'bizcharts';

import useAuth from '../../../hooks/useAuth';
import api from '../../../services/api';

import DefaultNavbar from '../../../components/Navbars/DefaultNavbar';
import GradientFooter from '../../../components/Footers/GradientFooter';
import TasksNumbersCard from '../../../components/Cards/TasksNumbersCard';

import { GlobalStyle } from './styles';
import { BsStopwatch, BsArchive, BsHourglassTop, BsCheckSquare, BsExclamationTriangleFill } from 'react-icons/bs';
import { AiOutlineSync } from 'react-icons/ai';
import classnames from 'classnames';
import { getMonth } from 'date-fns';

interface TaskStats {
  tasksOverdue: number;
  tasksWaiting: number;
  tasksInProgress: number;
  tasksArchived: number;
  tasksDone: number;
}

interface ChartPrioritiesTaskData {
  priority: string;
  quantity: number;
}

const MainDashboard = () => {

  const { signOut, user, refreshToken } = useAuth();

  const history = useTransitionHistory();

  const [tasksStatsData, setTasksStatsData] = useState<TaskStats>({} as TaskStats);
  const [chartPrioritiesTasksData, setChartPrioritiesTasksData] = useState<ChartPrioritiesTaskData[]>([]);
  const [remindersExpiredAndNotMuted, setRemindersExpiredAndNotMuted] = useState(0);

  const [activeTab, setActiveTab] = useState('1');
  const [monthName, setMonthName] = useState(() => {
    
    const currentMonth = getMonth(new Date()) + 1;

    switch (currentMonth) {
      case 1:
        return 'Janeiro';
      case 2:
        return 'Fevereiro';
      case 3:
        return 'Março';
      case 4:
        return 'Abril';
      case 5:
        return 'Maio';
      case 6:
        return 'Junho';
      case 7:
        return 'Julho';
      case 8:
        return 'Agosto';
      case 9:
        return 'Setembro';
      case 10:
        return 'Outubro';
      case 11:
        return 'Novembro';
      case 12:
        return 'Dezembro';
      default:
        return 'Janeiro';
    }
  });

  useEffect(() => {
  
    const getStats = async() => {

      const username = user.username;

      try{
        const response = await api.get(`stats/${username}`);

        setRemindersExpiredAndNotMuted(response.data.remindersInfo.remindersExpiredAndNotMuted);

        if (activeTab === '1'){
          setTasksStatsData(response.data.tasksInfo.thisMonth);
          setChartPrioritiesTasksData(response.data.tasksByPriority.thisMonth);
        }else{
          setTasksStatsData(response.data.tasksInfo.total);
          setChartPrioritiesTasksData(response.data.tasksByPriority.total);
        }

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }

      }catch(err){
        if (err.response && err.response.status === 401) {
          signOut();
        }
      }
    }

    getStats();

  }, [signOut, refreshToken, activeTab]);

  useTransition({
    handlers: [
      {
        path: '/dashboard',
        onEnter: async () => {
          await gsap.timeline().fromTo
          ( 
            '[data-dashboard-main]',  
            { opacity: 0, y: 20 },  
            { duration: 0.6, stagger: 0.125, y: 0, opacity: 1 }
          ) 
        },
        onLeave: async () => {
          await gsap.timeline().to('[data-dashboard-main]', {
            duration: 0.6,
            stagger: 0.125,
            opacity: 0,
            y: -20,
          })
        },
      },
    ],
  });

  const toggle = (tab: string) => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <React.Fragment>
      <GlobalStyle /> 
      <DefaultNavbar />
      <div data-dashboard-main className="header pb-8 pt-5 pt-md-4">
        <Container fluid>
          <div className="header-body">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { toggle('1'); }}
                >
                  Este mês ({`${monthName}`})
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { toggle('2'); }}
                >
                  Todas
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <p></p>
                <p>Exibindo informações com base na data de entrega prevista configurada para cada tarefa ou na data de conclusão para o caso de tarefas concluídas.</p>
                <Row>
                  <Col lg="6" xl="3">
                    <TasksNumbersCard
                      onClick={() => { history.push('tarefas/minhas-tarefas') }}
                      title="Em aberto" 
                      description="Minhas tarefas a fazer"
                      icon={BsHourglassTop} 
                      circleColor="yellow"
                      tasksQuantity={ tasksStatsData.tasksWaiting }
                    />
                  </Col>
                  <Col lg="6" xl="3">
                    <TasksNumbersCard
                      onClick={() => { history.push('tarefas/minhas-tarefas') }}
                      title="Atrasadas" 
                      description="Tarefas atrasadas"
                      icon={BsExclamationTriangleFill} 
                      circleColor="warning"
                      tasksQuantity={ tasksStatsData.tasksOverdue }
                    />
                  </Col>
                  <Col lg="6" xl="3">
                    <TasksNumbersCard
                      onClick={() => { history.push('tarefas/minhas-tarefas') }}
                      title="Em andamento" 
                      description="Minhas tarefas em andamento"
                      icon={AiOutlineSync} 
                      circleColor="info"
                      tasksQuantity={ tasksStatsData.tasksInProgress }
                    />
                  </Col>
                  <Col lg="6" xl="3">
                    <TasksNumbersCard 
                      onClick={() => { history.push('tarefas/minhas-tarefas') }}
                      title="Concluídas" 
                      description="Tarefas concuídas com êxito"
                      icon={BsCheckSquare} 
                      circleColor="primary"
                      tasksQuantity={ tasksStatsData.tasksDone }
                    />
                  </Col>
                </Row>

                <Row className="pt-md-4">
                  <Col xl="12">
                    <a href="#">
                    Veja também arquivadas ou canceladas {`(${tasksStatsData.tasksArchived})`} <BsArchive/>
                    </a>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tabId="2">
                <p></p>
                <p>Exibindo informações considerando todas as tarefas que você está incluído, incluindo tarefas futuras para tarefas em aberto.</p>
                <Row>
                  <Col lg="6" xl="3">
                    <TasksNumbersCard
                      onClick={() => { history.push('tarefas/minhas-tarefas') }}
                      title="Em aberto" 
                      description="Minhas tarefas a fazer"
                      icon={BsHourglassTop} 
                      circleColor="yellow"
                      tasksQuantity={ tasksStatsData.tasksWaiting }
                    />
                  </Col>
                  <Col lg="6" xl="3">
                    <TasksNumbersCard
                      onClick={() => { history.push('tarefas/minhas-tarefas') }}
                      title="Atrasadas" 
                      description="Tarefas atrasadas"
                      icon={BsExclamationTriangleFill} 
                      circleColor="warning"
                      tasksQuantity={ tasksStatsData.tasksOverdue }
                    />
                  </Col>
                  <Col lg="6" xl="3">
                    <TasksNumbersCard
                      onClick={() => { history.push('tarefas/minhas-tarefas') }}
                      title="Em andamento" 
                      description="Minhas tarefas em andamento"
                      icon={AiOutlineSync} 
                      circleColor="info"
                      tasksQuantity={ tasksStatsData.tasksInProgress }
                    />
                  </Col>
                  <Col lg="6" xl="3">
                    <TasksNumbersCard 
                      onClick={() => { history.push('tarefas/minhas-tarefas') }}
                      title="Concluídas" 
                      description="Tarefas concuídas com êxito"
                      icon={BsCheckSquare} 
                      circleColor="primary"
                      tasksQuantity={ tasksStatsData.tasksDone }
                    />
                  </Col>
                </Row>

                <Row className="pt-md-4">
                  <Col xl="12">
                    <a href="#">
                    Veja também arquivadas ou canceladas {`(${tasksStatsData.tasksArchived})`} <BsArchive/>
                    </a>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>

            <Row className="pt-md-4">
              <Col xl="7">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                          Prioridades
                        </h6>
                        <h4 className="mb-0">
                          { activeTab === '1' ? `Minhas tarefas por prioridade (este mês)` : `Minhas tarefas por prioridade`}
                        </h4>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-nowrap">Inclui somente dados de tarefas não concluídas e não arquivadas</span>
                        </p>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* Chart */}
                    <div className="chart">
                      <Chart height={200} data={chartPrioritiesTasksData} autoFit>
                        <Coordinate type="theta" />
                        <Axis visible={false} />
                        <Tooltip showTitle={false} />
                        <Interval
                          position="quantity"
                          adjust="stack"
                          element-highlight
                          color={["priority", ["#3bd08a", "#ffd700", "#f5345c"]]}
                          style={{
                            lineWidth: 1,
                            stroke: '#fff',
                          }}
                          label={['priority', {
                            content: ''
                          }]}
                        />
                      </Chart>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col className="mb-5 mb-xl-0" xl="5">
                <TasksNumbersCard 
                  onClick={() => { history.push('/meus-lembretes-e-compromissos') }}
                  title="Lembretes vencidos" 
                  description="Lembretes vencidos não mudos"
                  icon={BsStopwatch} 
                  circleColor="default"
                  tasksQuantity={ remindersExpiredAndNotMuted }
                />
              </Col>
            </Row>

          </div>
        </Container>
      </div>
      <GradientFooter/>
    </React.Fragment>
  );
}

export default MainDashboard;