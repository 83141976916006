import React from 'react';
import {
  Card,
  CardHeader
} from 'reactstrap';
import { IconType } from 'react-icons/lib';

import { TasksCardBody } from './styles';

interface IProps {
  title: string,
  icon: IconType,
  statusColor: string;
  children: JSX.Element;
}

const StatusTaskContainer = (props: IProps) => {

  const Icon = props.icon;
  const statusColor = `text-center bg-${props.statusColor}`;

  return (
    <Card id="task-w" className="card-stats shadow bg-white mb-4 mb-xl-2">
      <CardHeader tag="h5" className={ statusColor }>
        <strong>{ props.title }</strong> <Icon/>
      </CardHeader>
      <TasksCardBody className="overflow-auto">

        { props.children }
        
      </TasksCardBody>
    </Card>
  );
  
};

export default StatusTaskContainer;