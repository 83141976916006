import React, { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import { useTransition } from 'react-route-transition';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  ModalFooter,
  Spinner,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledAlert,
  Alert
} from 'reactstrap';
import swal from 'sweetalert';
import * as Yup from 'yup';

import useAuth from '../../../hooks/useAuth';

import api from '../../../services/api';

import DefaultNavbar from '../../../components/Navbars/DefaultNavbar';
import GradientFooter from '../../../components/Footers/GradientFooter';

import { GlobalStyle } from './styles';
import { BsCardText } from 'react-icons/bs';
import { RiLockPasswordLine, RiLockPasswordFill } from 'react-icons/ri';

interface UserData {
  id: string;
  email: string;
  fullname: string;
  phone: string;
  username: string;
  is_active: boolean;
  is_sup: boolean;
  is_admin: boolean;
  profession: string;
  profile_pic: string;
  bio: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

const MyProfile = () => {

  const { signOut, refreshToken, user, profilePicBase64 } = useAuth();

  const inputBioRef = useRef<HTMLInputElement>(null);
  const inputRedefinePasswordRef = useRef<HTMLInputElement>(null);
  const inputRedefinePasswordRepeatRef = useRef<HTMLInputElement>(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [tasksQuantity, setTasksQuantity] = useState(0);

  const [popoverRedefinePasswordOpen, setPopoverRedefinePasswordOpen] = useState(false);
  const toggleRedefinePasswordPopover = () => setPopoverRedefinePasswordOpen(!popoverRedefinePasswordOpen);

  const toggleModal = () => {
    if (modalIsOpen){
      setPopoverRedefinePasswordOpen(false);
    }
    setModalIsOpen(!modalIsOpen);
  }

  useEffect(() => {
  
    const getStats = async() => {
      try{
        const response = await api.get('stats');

        const totalTasksNumber = response.data.tasksInfo.tasksWaiting 
        + response.data.tasksInfo.tasksInProgress 
        + response.data.tasksInfo.tasksArchived 
        + response.data.tasksInfo.tasksDone;

        setTasksQuantity(totalTasksNumber);

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }

      }catch(err){
        if (err.response && err.response.status === 401) {
          signOut();
        }
      }
    }

    getStats();

  }, [signOut, refreshToken]);

  useTransition({
    handlers: [
      {
        path: '/meu-perfil',
        onEnter: async () => {
          await gsap.timeline().fromTo
          ( 
            '[data-profile-page]',  
            { opacity: 0, y: 20 },  
            { duration: 0.6, stagger: 0.125, y: 0, opacity: 1 }
          ) 
        },
        onLeave: async () => {
          await gsap.timeline().to('[data-profile-page]', {
            duration: 0.6,
            stagger: 0.125,
            opacity: 0,
            y: -20,
          })
        },
      },
    ],
  });

  const handleEditProfile = async () => {
    try {
      const response = await api.get(`users/${user.username}`);
      toggleModal();
      
      inputBioRef.current!.value = response.data.bio;

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }
    }catch(err: any) {
      swal("Oppss!", err, "error");
    }
  };

  const handleProfilePicClick = () => {
    swal("Estamos trabalhando nisso. Em breve será possível visualizar e alterar sua foto de perfil :)");
  }

  const handleSubmit = async () => {
    const data = {
      bio: inputBioRef.current!.value
    }

    try {
      const response = await api.put(`users/${user.username}`, data);
      toggleModal();
      swal("Tudo certo :)", "Seus dados foram atualizados!", "success");

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }
    }catch(err: any) {
      swal("Oppss!", err, "error");
    }
  }

  const handleSubmitPasswordChange = async() => {

    const data = {
      password: inputRedefinePasswordRef.current?.value,
      password_repeat: inputRedefinePasswordRepeatRef.current?.value
    }

    try {

      const schema = Yup.object().shape({
        password: 
        Yup.string()
        .min(6, 'a senha precisa ter, no mínimo, 6 caracteres'),
        password_repeat: 
          Yup.string()
          .min(6, 'a confirmação da senha precisa ter, no mínimo, 6 caracteres e ser igual a senha')
      });
      await schema.validate(data, {abortEarly: false} );

      if (data.password !== data.password_repeat){
        swal("Oppss!", 'Parece que a senha a confirmação da senha não são iguais. Por favor, tente novamente.', "error");
        setIsSaving(false);
        return;
      }
      
      const response = await api.put(`users/${user.username}`, data);
      toggleModal();
      swal("Tudo certo :)", "A sua senha foi atualizada!", "success");

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }
    }catch (err: any) {
      if (err.errors) {
        swal("Oppss!", err.errors[0], "error");
      }else {
        swal("Oppss! o servidor retornou o seguinte erro:", err, "error");
      }
    }
  }

  return (
    <React.Fragment>
      <GlobalStyle /> 
      <DefaultNavbar />

      <Modal isOpen={modalIsOpen} toggle={toggleModal}>
        <ModalHeader className="bg-secondary" toggle={toggleModal}>
          Meu Perfil
        </ModalHeader>
        <ModalBody className="bg-secondary">
          <Form role="form">

            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <BsCardText/>
                  </InputGroupText>
                </InputGroupAddon>
                <Input 
                  rows="6"
                  id="inputDescription" 
                  innerRef={inputBioRef} 
                  name="description" 
                  placeholder="Fale um pouco sobre você ou insira algo que você gosta. Essa é a sua breve biografia ou algo que ajude a lhe definir :)" 
                  type="textarea"
                />
              </InputGroup>
            </FormGroup>

            <Alert color="warning">
              É necessário fazer login novamente para visualizar as alterações em seu próprio perfil.
            </Alert>

            <Button id="PopoverRedefinePassword" color="link" type="button">
              Redefinir senha?
            </Button>
            <Popover placement="auto" isOpen={popoverRedefinePasswordOpen} target="PopoverRedefinePassword" toggle={toggleRedefinePasswordPopover}>
              <PopoverHeader>Alterar senha 
                <a onClick={toggleRedefinePasswordPopover}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                x
                </a>
              </PopoverHeader>
              <PopoverBody>

                <UncontrolledAlert color="warning">
                  Sua nova senha também precisa ter pelo menos seis caracteres.
                </UncontrolledAlert>

                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <RiLockPasswordLine/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input 
                        id="inputRedefinePassword"
                        innerRef={inputRedefinePasswordRef} 
                        name="item" 
                        placeholder="Digite a nova senha" 
                        type="password"
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <RiLockPasswordFill/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input 
                        id="inputRedefinePasswordRepeat"
                        innerRef={inputRedefinePasswordRepeatRef} 
                        name="item" 
                        placeholder="Confirme a nova senha" 
                        type="password"
                      />
                    </InputGroup>
                  </FormGroup>
                </Form>

                <br/>
                <Button size="sm" onClick={handleSubmitPasswordChange} color="primary" type="button">
                  Atualizar senha
                </Button>
              </PopoverBody>
            </Popover>

          </Form>
        </ModalBody>
        <ModalFooter className="bg-secondary">
          <Button color="primary" disabled={isSaving} onClick={handleSubmit}>
            { isSaving
              ? <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  /> Salvando...
                </>
              : 'Atualizar minhas informações'
            }
          </Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
        </ModalFooter>
      </Modal>
      
      <main data-profile-page className="profile-page">
        <section className="section">
          <Container>
            <Card className="card-profile shadow mt-5">
              <div className="px-4">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <img
                        onClick={handleProfilePicClick}
                        alt="foto de perfil"
                        className="rounded-circle"
                        src={ profilePicBase64.source }
                      />
                    </div>
                  </Col>
                  <Col
                    className="order-lg-3 text-lg-right align-self-lg-center"
                    lg="4"
                  >
                    <div className="card-profile-actions py-4 mt-lg-0">
                      <Button
                        className="float-right"
                        color="primary"
                        onClick={handleEditProfile}
                        size="sm"
                      >
                        Editar minha bio ou redefinir senha
                      </Button>
                    </div>
                  </Col>
                  <Col className="order-lg-1" lg="4">
                    <div className="card-profile-stats d-flex justify-content-center">
                      <div>
                        <span className="heading">{ tasksQuantity }</span>
                        <span className="description">Tarefas</span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="text-center mt-5">
                  <h3>
                    { user.show_name }
                  </h3>
                  <div className="h6 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    @{ user.username }
                  </div>
                  <div className="h6 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    { user.profession }
                  </div>
                </div>
                <div className="mt-5 py-5 border-top text-center">
                  <Row className="justify-content-center">
                    <Col lg="9">
                      <p>
                        { user.bio }
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </Container>
        </section>
      </main>

      <GradientFooter/>
    </React.Fragment>
  );
}

export default MyProfile;