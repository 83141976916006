import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTransitionHistory } from 'react-route-transition';

import {
  UncontrolledCollapse,
  NavbarBrand,
  Nav,
  Row,
  Col,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Badge,
  Popover,
  PopoverHeader,
  PopoverBody,
  Button,
  Tooltip
} from "reactstrap";

import useAuth from '../../../hooks/useAuth';

import { CustomNavbar } from './styles';

import { GoTasklist, GoCalendar, GoOrganization } from 'react-icons/go';
import { CgFileDocument } from 'react-icons/cg';
import { MdDashboard } from 'react-icons/md';
import { IoIosNotifications } from 'react-icons/io';
import { BiFullscreen, BiTask } from 'react-icons/bi';

import logoWhite from '../../../assets/img/logo/logo-orcoma-white.png';
import logoDefault from '../../../assets/img/logo/logo-orcoma-default.png';
import logoTaskDayV10 from '../../../assets/img/logo/logo-taskday-v10.png';

const DefaultNavbar = () => {

  const history = useTransitionHistory();

  const { signOut, user, profilePicBase64 } = useAuth();

  const [popoverNotificationsOpen, setPopoverNotificationsOpen] = useState(false);

  const [tooltipDashboardMenuOpen, setTooltipDashboardMenuOpen] = useState(false);
  const [tooltipTasksMenuOpen, setTooltipTasksMenuOpen] = useState(false);
  const [tooltipClientsMenuOpen, setTooltipClientsMenuOpen] = useState(false);
  const [tooltipRemindersMenuOpen, setTooltipRemindersMenuOpen] = useState(false);
  const [tooltipVisitsMenuOpen, setTooltipVisitsMenuOpen] = useState(false);
  const toggleTooltip = (menuItem: string) => {

    switch (menuItem) {
      case 'dashboard':
        setTooltipDashboardMenuOpen(!tooltipDashboardMenuOpen);
        break;
      case 'tasks':
        setTooltipTasksMenuOpen(!tooltipTasksMenuOpen);
        break;
      case 'clients':
        setTooltipClientsMenuOpen(!tooltipClientsMenuOpen);
        break;
      case 'reminders':
        setTooltipRemindersMenuOpen(!tooltipRemindersMenuOpen);
      break;
      case 'visits':
        setTooltipVisitsMenuOpen(!tooltipVisitsMenuOpen);
      break;
      default:
        break;
    }
  }

  const toggleNotificationsPopover = () => setPopoverNotificationsOpen(!popoverNotificationsOpen);

  const requestFullScreen = () => {
    if (document.fullscreenElement){
      document.exitFullscreen();
      return;
    }
    document.documentElement.requestFullscreen();
  }

  /*

  <UncontrolledDropdown nav inNavbar>
    <DropdownToggle className="text-white" nav caret>
      <GoTasklist/> <b>Tarefas</b>
    </DropdownToggle>
    <DropdownMenu right>
      <DropdownItem onClick={() => { history.push('/dashboard') }}>
        Visão Geral
      </DropdownItem>
      <DropdownItem onClick={() => { history.push('/tarefas/minhas-tarefas') }}>
        Minhas Tarefas
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>

  */

  return (
    <React.Fragment>
      <CustomNavbar
        className="shadow navbar-horizontal navbar-dark bg-gradient-primary"
        expand="lg"
      >

        <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
          <img alt="Logo" src={logoTaskDayV10} />
        </NavbarBrand>
        <button
          aria-controls="navbar-primary"
          aria-expanded={false}
          aria-label="Toggle navigation"
          className="navbar-toggler"
          data-target="#navbar-primary"
          data-toggle="collapse"
          id="navbar-primary"
          type="button"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <UncontrolledCollapse navbar toggler="#navbar-primary">
          
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <Link to="/">
                  <img
                    alt="logo"
                    src={logoDefault}
                  />
                </Link>
              </Col>
              <Col className="collapse-close" xs="6">
                <button
                  aria-controls="navbar-primary"
                  aria-expanded={false}
                  aria-label="Toggle navigation"
                  className="navbar-toggler"
                  data-target="#navbar-primary"
                  data-toggle="collapse"
                  id="navbar-primary"
                  type="button"
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Nav className="ml-lg-auto" navbar>

            <UncontrolledDropdown nav inNavbar id="TooltipDashboardMenu">
              <DropdownToggle
                onClick={() => { history.push('/dashboard') }} 
                className="text-white"
                nav
              >
                <MdDashboard/> <b>Visão geral</b>
              </DropdownToggle>
            </UncontrolledDropdown>
            <Tooltip placement="right" isOpen={tooltipDashboardMenuOpen} target="TooltipDashboardMenu" toggle={() => toggleTooltip('dashboard')}>
              Visualizar dados e estatísticas de tarefas, clientes, lembretes, compromissos e outras informações.
            </Tooltip>

            <UncontrolledDropdown nav inNavbar id="TooltipTasksMenu">
              <DropdownToggle nav caret>
                <GoTasklist/> <b>Tarefas</b>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => { history.push('/tarefas/minhas-tarefas') }} 
                  nav
                >
                  <GoTasklist/> Minhas tarefas
                </DropdownItem>
                <DropdownItem
                  onClick={() => { history.push('/tarefas/templates') }} 
                  nav
                >
                  <BiTask/> Modelos de Tarefas
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <Tooltip placement="right" isOpen={tooltipTasksMenuOpen} target="TooltipTasksMenu" toggle={() => toggleTooltip('tasks')}>
              Gerir suas tarefas, criar novas atividades, modelos e tipos serviços.
            </Tooltip>

            <UncontrolledDropdown nav inNavbar id="TooltipClientsMenu">
              <DropdownToggle
                onClick={() => { history.push('/contatos-e-clientes/clientes') }} 
                className="text-white"
                nav
              >
                <GoOrganization/> <b>Clientes</b>
              </DropdownToggle>
            </UncontrolledDropdown>
            <Tooltip placement="right" isOpen={tooltipClientsMenuOpen} target="TooltipClientsMenu" toggle={() => toggleTooltip('clients')}>
              Acessar clientes, contatos e serviços realizados para cada entidade.
            </Tooltip>

            <UncontrolledDropdown nav inNavbar id="TooltipRemindersMenu">
              <DropdownToggle
                onClick={() => { history.push('/meus-lembretes-e-compromissos') }} 
                className="text-white"
                nav
              >
                <GoCalendar/> <b>Lembretes</b>
              </DropdownToggle>
            </UncontrolledDropdown>
            <Tooltip placement="right" isOpen={tooltipRemindersMenuOpen} target="TooltipRemindersMenu" toggle={() => toggleTooltip('reminders')}>
              Visualizar seus lembretes, edita-los, adicioná-los ou ativar o silenciamento do lembrete.
            </Tooltip>

            <UncontrolledDropdown nav inNavbar id="TooltipVisitsMenu">
              <DropdownToggle
                onClick={() => { history.push('/relatorios-de-visitas') }} 
                className="text-white"
                nav
              >
                <CgFileDocument/> <b>Visitas</b>
              </DropdownToggle>
            </UncontrolledDropdown>
            <Tooltip placement="right" isOpen={tooltipVisitsMenuOpen} target="TooltipVisitsMenu" toggle={() => toggleTooltip('visits')}>
              Gerir suas visitas e adicionar relatórios de uma viagem realizada para atender um cliente.
            </Tooltip>

            {
              /*
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle
                id="PopoverListNotifications"
                className="text-white"
                nav
              >
                <Badge color="warning" pill>
                  <IoIosNotifications/> <strong>3</strong>
                </Badge>
                <Popover placement="bottom" isOpen={popoverNotificationsOpen} target="PopoverListNotifications" toggle={toggleNotificationsPopover}>
                  <PopoverHeader>Notificações</PopoverHeader>
                  <PopoverBody>

                    <p><small><strong>@example</strong> marcou você em um comentário de tarefa.</small></p>
                    <p><small><strong>@example</strong> o incluiu em uma tarefa.</small></p>
                    <p><small><strong>@example</strong> concluiu alterou o status de uma tarefa que você está incluso para 'concluída'</small></p>

                    <p className="text-center"><a href="#">ver todas</a></p>
                    
                  </PopoverBody>
                </Popover>

              </DropdownToggle>
            </UncontrolledDropdown>]
            */
            }

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle
                onClick={requestFullScreen} 
                className="text-white"
                nav
              >
                <BiFullscreen/>
              </DropdownToggle>
            </UncontrolledDropdown>
            
          </Nav>

          <Nav className="align-items-left d-md-flex" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle className="text-white" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="Minha foto de perfil"
                      src={ profilePicBase64.source }
                    />
                  </span>
                  <Media className="ml-2 d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      <b>{ user.show_name }</b>
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => { history.push('/meu-perfil') }}>
                  Meu Perfil
                </DropdownItem>
                {
                  user.is_sup
                  ? <DropdownItem onClick={() => { history.push('/usuarios') }}>
                      Gerenciar usuários
                    </DropdownItem>
                  : null
                }
                <DropdownItem divider />
                <DropdownItem onClick={ signOut } >
                  Desconectar
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>

        </UncontrolledCollapse>

      </CustomNavbar>
    </React.Fragment>
  );
}

export default DefaultNavbar;