import React, { useState, useEffect } from 'react';
import gsap from 'gsap';
import { useTransition, useTransitionHistory } from 'react-route-transition';
import { useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody
} from 'reactstrap';

import {
  Chart,
  Interval,
  Tooltip,
  Coordinate,
  Axis
} from 'bizcharts';

import useAuth from '../../../hooks/useAuth';
import api from '../../../services/api';

import DefaultNavbar from '../../../components/Navbars/DefaultNavbar';
import GradientFooter from '../../../components/Footers/GradientFooter';
import TasksNumbersCard from '../../../components/Cards/TasksNumbersCard';

import { GlobalStyle } from './styles';
import { BsStopwatch, BsArchive, BsCheckSquare, BsExclamationTriangleFill } from 'react-icons/bs';
import { AiOutlineSync } from 'react-icons/ai';

interface TaskStats {
  tasksOverdue: number;
  tasksWaiting: number;
  tasksInProgress: number;
  tasksArchived: number;
  tasksDone: number;
}

interface ChartPrioritiesTaskData {
  priority: string;
  quantity: number;
}

const DashboardFromUsername = () => {

  const { signOut, refreshToken } = useAuth();

  const { username } = useParams<{ username: string }>();

  const history = useTransitionHistory();

  const [tasksStats, setTasksStats] = useState<TaskStats>({} as TaskStats);
  const [chartPrioritiesTasks, setChartPrioritiesTasks] = useState<ChartPrioritiesTaskData[]>([]);
  const [remindersExpiredAndNotMuted, setRemindersExpiredAndNotMuted] = useState(0);

  useEffect(() => {
  
    const getStats = async() => {
      try{
        const response = await api.get('stats');
        
        setTasksStats(response.data.tasksInfo);
        setChartPrioritiesTasks(response.data.tasksByPriority);
        setRemindersExpiredAndNotMuted(response.data.remindersInfo.remindersExpiredAndNotMuted);

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }

      }catch(err){
        if (err.response && err.response.status === 401) {
          signOut();
        }
      }
    }

    getStats();

  }, [signOut, refreshToken]);

  useTransition({
    handlers: [
      {
        path: '/dashboard',
        onEnter: async () => {
          await gsap.timeline().fromTo
          ( 
            '[data-dashboard-main]',  
            { opacity: 0, y: 20 },  
            { duration: 0.6, stagger: 0.125, y: 0, opacity: 1 }
          ) 
        },
        onLeave: async () => {
          await gsap.timeline().to('[data-dashboard-main]', {
            duration: 0.6,
            stagger: 0.125,
            opacity: 0,
            y: -20,
          })
        },
      },
    ],
  });

  return (
    <React.Fragment>
      <GlobalStyle /> 
      <DefaultNavbar />
      <br/>
      <h3>&nbsp;&nbsp;&nbsp;Você transportou-se para a dashboard de <strong>@{username}</strong></h3>
      <div data-dashboard-main className="header pb-8 pt-5 pt-md-4">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col lg="6" xl="3">
                <TasksNumbersCard
                  
                  title="Em atraso" 
                  description="Minhas tarefas atrasadas"
                  icon={BsExclamationTriangleFill} 
                  circleColor="yellow"
                  tasksQuantity={ tasksStats.tasksOverdue }
                />
              </Col>
              <Col lg="6" xl="3">
                <TasksNumbersCard
                  
                  title="Em andamento" 
                  description="Minhas tarefas em andamento"
                  icon={AiOutlineSync} 
                  circleColor="info"
                  tasksQuantity={ tasksStats.tasksInProgress }
                />
              </Col>
              <Col lg="6" xl="3">
                <TasksNumbersCard
                  
                  title="Arquivadas" 
                  description="Arquivadas ou canceladas"
                  icon={BsArchive} 
                  circleColor="warning"
                  tasksQuantity={ tasksStats.tasksArchived }
                />
              </Col>
              <Col lg="6" xl="3">
                <TasksNumbersCard 
                  
                  title="Concluídas" 
                  description="Tarefas concuídas com êxito"
                  icon={BsCheckSquare} 
                  circleColor="primary"
                  tasksQuantity={ tasksStats.tasksDone }
                />
              </Col>
            </Row>

            <Row className="pt-md-4">
              <Col xl="7">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                          Prioridades
                        </h6>
                        <h4 className="mb-0">Minhas tarefas por prioridade </h4>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* Chart */}
                    <div className="chart">
                      <Chart height={200} data={chartPrioritiesTasks} autoFit>
                        <Coordinate type="polar" />
                        <Axis visible={false} />
                        <Tooltip showTitle={false} />
                        <Interval
                          position="priority*quantity"
                          adjust="stack"
                          element-highlight
                          color={["priority", ["#00cff2", "#ffe000", "#ff492e"]]}
                          style={{
                            lineWidth: 1,
                            stroke: '#fff',
                          }}
                          label={['priority', {
                            offset: -15,
                          }]}
                        />
                      </Chart>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col className="mb-5 mb-xl-0" xl="5">
                <TasksNumbersCard 
                  title="Lembretes vencidos" 
                  description="Lembretes vencidos não mudos"
                  icon={BsStopwatch} 
                  circleColor="default"
                  tasksQuantity={ remindersExpiredAndNotMuted }
                />
              </Col>
            </Row>

          </div>
        </Container>
      </div>
      <GradientFooter/>
    </React.Fragment>
  );
}

export default DashboardFromUsername;