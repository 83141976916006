import React from 'react';
import { Card, CardTitle, CardBody, Row, Col } from 'reactstrap';
import { IconType } from 'react-icons/lib';
import { TaskNumber } from './styles';

interface IProps {
  title: string,
  description: string,
  tasksQuantity: number,
  icon: IconType,
  circleColor: string;
  onClick?(): void;
}

const TasksNumbersCard = (props: IProps) => {
  
  const Icon = props.icon;
  const customCircleClass = `icon icon-shape bg-${props.circleColor} text-white rounded-circle shadow`;

  return (
    <TaskNumber onClick={ props.onClick }>
      <Card className="card-stats shadow mb-4 mb-xl-0">
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle
                tag="h5"
                className="text-uppercase text-muted mb-0"
              >
                { props.title }
              </CardTitle>
              <span className="h2 font-weight-bold mb-0">
                { props.tasksQuantity }
              </span>
            </div>
            <Col className="col-auto">
              <div className={ customCircleClass }>
                <Icon/>
              </div>
            </Col>
          </Row>
          <p className="mt-3 mb-0 text-muted text-sm">
            <span className="text-nowrap">{ props.description }</span>
          </p>
        </CardBody>
      </Card>
    </TaskNumber>
  );
};

export default TasksNumbersCard;