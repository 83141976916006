import React, { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { useTransition, useTransitionHistory } from 'react-route-transition';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Popover,
  PopoverHeader,
  PopoverBody,
  Label,
  Tooltip
} from 'reactstrap';
import swal from 'sweetalert';

import { v4 as uuidv4 } from 'uuid';

import MUIDataTable from "mui-datatables";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from "date-fns/locale/pt-BR";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import classnames from 'classnames';

import * as Yup from 'yup';

import useAuth from '../../../hooks/useAuth';
import api from '../../../services/api';
import { GlobalStyle } from './styles';

import DefaultNavbar from '../../../components/Navbars/DefaultNavbar';
import GradientFooter from '../../../components/Footers/GradientFooter';
import TasksNumbersCard from '../../../components/Cards/TasksNumbersCard';
import ChecklistTaskContainer from '../../../components/Containers/ChecklistTaskContainer';

import { 
  BsCheckSquare, 
  BsPlusCircle, 
  BsCardText
} from 'react-icons/bs';
import { AiOutlineSync, AiOutlineBlock } from 'react-icons/ai';
import { MdTitle } from 'react-icons/md';
import { GrStatusInfoSmall } from 'react-icons/gr';
import { BiCog } from 'react-icons/bi';
import { FaComments, FaRegCalendarCheck } from 'react-icons/fa';

import { User } from '../../../context/AuthContext';

interface TaskTemplateData {
  id: string;
  title: string;
  description: string;
  status: string;
  priority: string;
  private_task: boolean;
  created_at: Date;
  createdAtLabel: string;
  updated_at: Date;
  deleted_at?: Date;
  priorityColor: string;
  users: User[];
  completion_deadline: Date;
  completionDeadlineLabel: string;
  recurrent_type: string;
  taskChecklists: TemplateChecklistItem[];
}

interface TemplateChecklistItem {
  id: string;
  item: string;
  item_order?: number;
  created_at?: Date;
}

interface SelectDataFormat {
  value: string;
  label: string;
}

interface Service {
  id: string;
  title: string;
}

const TaskTemplates = () => {

  const history = useTransitionHistory();

  const animatedComponents = makeAnimated();

  const { signOut, refreshToken } = useAuth();

  const [isUpdate, setIsUpdate] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isRecurrentTask, setIsRecurrentTask] = useState(false);

  const inputTitleRef = useRef<HTMLInputElement>(null);
  const inputDescriptionRef = useRef<HTMLInputElement>(null);

  const inputTemplateChecklistItemRef = useRef<HTMLInputElement>(null);
  const inputServiceTitleRef = useRef<HTMLInputElement>(null);

  const [taskTemplates, setTaskTemplates] = useState<TaskTemplateData[]>([{}] as TaskTemplateData[]);
  const [taskTemplateId, setTaskTemplateId] = useState<string | null>(null);

  const [services, setServices] = useState<Service[]>([{}] as Service[]);

  const [servicesTaskTemplate, setServicesTaskTemplate] = useState<SelectDataFormat[]>([] as SelectDataFormat[]);
  const [selectedServicesTaskTemplate, setSelectedServicesTaskTemplate] = useState<SelectDataFormat[]>([] as SelectDataFormat[]);

  const [taskTemplateChecklist, setTaskTemplateChecklist] = useState<TemplateChecklistItem[]>([] as TemplateChecklistItem[]);

  const [popoverTemplateChecklistOpen, setPopoverTemplateChecklistOpen] = useState(false);
  const toggleTemplateChecklistPopover = () => setPopoverTemplateChecklistOpen(!popoverTemplateChecklistOpen);

  const [popoverServiceOpen, setPopoverServiceOpen] = useState(false);
  const toggleServicePopover = () => setPopoverServiceOpen(!popoverServiceOpen);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    if (modal){
      setTaskTemplateChecklist([]);
    }
    setModal(!modal)
  };

  const [modalServices, setModalServices] = useState(false);
  const toggleModalServices = () => {
    setModalServices(!modalServices)
  };

  const [selectedCompletionDateHour, setSelectedCompletionDateHour] = useState<Date | null>(null);

  const [dropdownRepetitionOpen, setDropdownRepetitionOpen] = useState(false);
  const [dropdownRepetitionValue, setDropdownRepetitionValue] = useState('no-selected');
  const [dropdownRepetitionLabel, setDropdownRepetitionLabel] = useState('selecione a recorrência');
  const toggleDropdownRepetition = () => setDropdownRepetitionOpen(prevState => !prevState);

  const [dropdownPriorityOpen, setDropdownPriorityOpen] = useState(false);
  const [dropdownPriorityValue, setDropdownPriorityValue] = useState('normal');
  const toggleDropdownPriority = () => setDropdownPriorityOpen(prevState => !prevState);

  const [tooltipCompletionDeadlineOpen, setTooltipCompletionDeadlineOpen] = useState(false);

  const toggleTooltip = (item: string) => {

    switch (item) {
      case 'completion_deadline':
        setTooltipCompletionDeadlineOpen(!tooltipCompletionDeadlineOpen);
        break;
      default:
        break;
    }
  }

  const getTaskTemplates = async() => {
    try{
      const response = await api.get('task-templates');
      
      setTaskTemplates(response.data);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

    }catch(err: any){
      if (err.response && err.response.status === 401) {
        signOut();
      }
    }
  }

  const getServices = async() => {
    try{
      const response = await api.get('services');
      setServices(response.data);

      const dataForServicesTaskTemplate: SelectDataFormat[] = [];
      response.data.map((service: Service) => {
        dataForServicesTaskTemplate.push( {value: service.id, label: `${service.title}`} );
      });

      setServicesTaskTemplate(dataForServicesTaskTemplate);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

    }catch(err: any){
      if (err.response && err.response.status === 401) {
        signOut();
      }
    }
  }

  useEffect(() => {

    getTaskTemplates();
    getServices();

  }, [signOut, refreshToken]);

  useTransition({
    handlers: [
      {
        path: '/tarefas/templates',
        onEnter: async () => {
          await gsap.timeline().fromTo
          ( 
            '[data-clients-main]',  
            { opacity: 0, y: 20 },  
            { duration: 0.6, stagger: 0.125, y: 0, opacity: 1 }
          ) 
        },
        onLeave: async () => {
          await gsap.timeline().to('[data-clients-main]', {
            duration: 0.6,
            stagger: 0.125,
            opacity: 0,
            y: -20,
          })
        },
      },
    ],
  });

  const handleCompletionDateChange = (date: MaterialUiPickersDate) => {
    setSelectedCompletionDateHour(date);
  };

  const handleTaskTemplatesRowClicked = async (rowData: string[]) => {

    setIsUpdate(true);

    const rowTaskTemplateId = rowData[0];
    setTaskTemplateId(rowTaskTemplateId);

    try {
      const response = await api.get(`task-templates/${rowTaskTemplateId}`);
      toggleModal();
      
      setIsUpdate(true);
      setTaskTemplateId(rowTaskTemplateId);
      inputTitleRef.current!.value = response.data.title;
      inputDescriptionRef.current!.value = response.data.description;
      setDropdownPriorityValue(response.data.priority);
      setSelectedCompletionDateHour(response.data.completion_deadline);
      setDropdownRepetitionValue(response.data.recurrent_type);

      switch (response.data.recurrent_type) {
        case 'no':
          setDropdownRepetitionLabel('não é recorrente');
          setIsRecurrentTask(false);
        break;
        case '7d':
          setDropdownRepetitionLabel('repete-se a cada 7 dias');
          setIsRecurrentTask(true);
        break;
        case '15d':
          setDropdownRepetitionLabel('repete-se a cada 15 dias');
          setIsRecurrentTask(true);
        break;
        case '30d':
          setDropdownRepetitionLabel('repete-se a cada 30 dias');
          setIsRecurrentTask(true);
        break;
        case '2m':
          setDropdownRepetitionLabel('repete-se a cada 2 meses');
          setIsRecurrentTask(true);
        break;
        case '4m':
          setDropdownRepetitionLabel('repete-se a cada 4 meses');
          setIsRecurrentTask(true);
        break;
        case '6m':
          setDropdownRepetitionLabel('repete-se a cada 6 meses');
          setIsRecurrentTask(true);
        break;
        case '12m':
          setDropdownRepetitionLabel('repete-se a cada 12 meses');
          setIsRecurrentTask(true);
        break;
        default:
        break;
      }

      const dataForServicesInTaskTemplate: SelectDataFormat[] = [];
      response.data.services.map((service: Service) => {
        dataForServicesInTaskTemplate.push( {value: service.id, label: `@${service.title}`} );
      });
      setSelectedServicesTaskTemplate(dataForServicesInTaskTemplate);

      setTaskTemplateChecklist(response.data.taskTemplateChecklists);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }
    }catch(err: any) {
      swal("Oppss!", err, "error");
    }
  }

  const handleNewTaskTemplate = () => {
    setSelectedCompletionDateHour(null);
    setTaskTemplateChecklist([]);
    setSelectedServicesTaskTemplate([]);
    setDropdownRepetitionValue('no-selected');
    setDropdownRepetitionLabel('selecione a recorrência');

    setIsUpdate(false);
    toggleModal();
  }

  const handleServices = () => {
    toggleModalServices();
  }

  const handleSubmit = async() => {

    const services: [{ id?: string }] = [{}];
    selectedServicesTaskTemplate.map((service) => {
      services.push({ id: service.value })
    })

    const data = {
      title: inputTitleRef.current!.value,
      description: inputDescriptionRef.current!.value,
      priority: dropdownPriorityValue,
      services,
      completion_deadline: selectedCompletionDateHour,
      recurrent_type: dropdownRepetitionValue,
      task_checklists: taskTemplateChecklist
    }

    try {
      const schema = Yup.object().shape({
        title:
          Yup.string()
          .required('O título precisa ser informado'),
        description:
          Yup.string()
          .required('A descrição precisa ser informada'),
        completion_deadline:
          Yup.date()
          .required('Uma data prevista de entrega precisa ser infomada').nullable()
      });

      await schema.validate(data, {abortEarly: false} );

      if (dropdownRepetitionValue === 'no-selected') {
        swal("Oppss!", 'O tipo de recorrência não foi selecionado.', "error");
        return;
      }

      if (!selectedServicesTaskTemplate.length){
        swal("Oppss!", 'Você não informou nenhum serviço.', "error");
        return;
      }

      setIsSaving(true);

      if (isUpdate) {
        try {
          const response = await api.put(`task-templates/${taskTemplateId}`, data);
          toggleModal();
          swal("Tudo certo :)", "O modelo de tarefa foi atualizado!", "success");
  
          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }
        }catch(err: any) {
          swal("Oppss!", err, "error");
        }
      }else{
        try {
          const response = await api.post('task-templates', data);
          toggleModal();
          swal("Feito!", "O modelo de tarefa foi incluído!", "success");
  
          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }
        }catch(err: any) {
          swal("Oppss!", err, "error");
        }
      }
      
      setIsSaving(false);
  
      getTaskTemplates();
      
    }catch (err: any) {
      if (err.errors) {
        swal("Oppss!", err.errors[0], "error");
      }else {
        swal("Oppss! o servidor retornou o seguinte erro:", err, "error");
      }
      setIsSaving(false);
    }

  }

  const handleTemplateChecklistCheckboxItemChange = () => {
    swal("Os itens de checkist num modelo de tarefas são apenas representações e não é possível marca-los aqui, mas você poderá fazer isto nas tarefas reais que serão criadas automaticamente a partir deste modelo :)");
  }

  const handleSubmitTaskTemplateChecklistItem = async() => { 
    
    const item = inputTemplateChecklistItemRef.current!.value;

    if (!item.length){
      swal("Oppss!", 'O campo de descrição da etapa não pode ficar vazio. Descreva-o em uma única frase.', "error");
      return;
    }

    if (!isUpdate) {
      const checklistItems = taskTemplateChecklist;
      checklistItems.push(
        { 
          id: uuidv4(), 
          item
        }
      );
      setTaskTemplateChecklist(checklistItems);
      toggleTemplateChecklistPopover();
      return;
    }

    try {

      const data = {
        item,
        checklisted_task_template: taskTemplateId
      }

      const response = await api.post(`task-template-checklists`, data);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

      if (response.data){
        const { id, item } = response.data;
        const checklistItems = taskTemplateChecklist;
        checklistItems.push(
          { id, 
            item
          }
        );
        setTaskTemplateChecklist(checklistItems);
        toggleTemplateChecklistPopover();
      }

    }catch(err: any) {
      if (err.errors) {
        swal("Oppss!", err.errors[0], "error");
      }else {
        swal("Oppss! ocorreu o seguinte erro:", err, "error");
      }
    }

  }

  const handleSubmitService = async() => { 
    
    const title = inputServiceTitleRef.current!.value;

    if (!title.length){
      swal("Oppss!", 'O campo de descrição do serviço não pode ficar vazio. Descreva-o em uma única frase curta.', "error");
      return;
    }

    try {

      const data = {
        title
      }

      const response = await api.post(`services`, data);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

      if (response.data){
        getServices();
        toggleServicePopover();
        inputServiceTitleRef.current!.value = '';
      }

    }catch(err: any) {
      if (err.errors) {
        swal("Oppss!", err.errors[0], "error");
      }else {
        swal("Oppss! ocorreu o seguinte erro:", err, "error");
      }
    }

  }

  const handleChangeSelectServicesTaskTemplate = (selectedOption: any) => {
    setSelectedServicesTaskTemplate(selectedOption);
  }

  return (
    <React.Fragment>
      <GlobalStyle /> 
      <DefaultNavbar />
      <div data-clients-main className="header pb-8 pt-5 pt-md-4">
        <Container fluid>

          <Modal className="modal-lg" isOpen={modal} toggle={toggleModal}>
            <ModalHeader className="bg-secondary" toggle={toggleModal}>
              { isUpdate ? 'Editar Tarefa' : 'Adicionar Tarefa' }
            </ModalHeader>
            <ModalBody className="bg-secondary">
              <Row>
                <Col xs="7" sm="7">
                  <Form role="form">

                    <h5> <strong>Dados da tarefa </strong> </h5>

                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <MdTitle/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input 
                          id="inputTitle"
                          innerRef={inputTitleRef} 
                          name="title" 
                          placeholder="Dê um título a esta tarefa" 
                          type="text"
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <BsCardText/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input 
                          id="inputDescription" 
                          innerRef={inputDescriptionRef} 
                          name="description" 
                          placeholder="Fale um pouco mais sobre o que precisa ser feito. Mais detalhes ajudarão a entender essa tarefa mais tarde." 
                          type="textarea"
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <Select
                        options={servicesTaskTemplate} 
                        placeholder="Para quais serviços esta tarefa se aplica?" 
                        components={animatedComponents}
                        noOptionsMessage={ () => "Nada foi encontrado :(" }
                        isMulti
                        value={selectedServicesTaskTemplate}
                        onChange={ handleChangeSelectServicesTaskTemplate }
                      />  
                    </FormGroup>

                    <h5> <strong>Prioridade, repetição e entrega </strong> </h5>

                    <FormGroup className="mb-3">
                      <InputGroup>

                        &nbsp;&nbsp;
                        <Dropdown isOpen={dropdownPriorityOpen} toggle={toggleDropdownPriority}>
                          <DropdownToggle caret>
                            { dropdownPriorityValue === 'baixa' ? <GrStatusInfoSmall className="text-success"/> : undefined }
                            { dropdownPriorityValue === 'normal' ? <GrStatusInfoSmall className="text-yellow"/> : undefined }
                            { dropdownPriorityValue === 'alta' ? <GrStatusInfoSmall className="text-danger"/> : undefined }
                            &nbsp;{ dropdownPriorityValue }
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              className="text-muted" 
                              header
                            >
                              selecione a prioridade
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => { setDropdownPriorityValue('baixa') }}
                            >
                              <GrStatusInfoSmall className="text-success"/>baixa
                            </DropdownItem>
                            <DropdownItem 
                              onClick={() => { setDropdownPriorityValue('normal') }}
                            >
                              <GrStatusInfoSmall className="text-yellow"/>normal
                            </DropdownItem>
                            <DropdownItem 
                              onClick={() => { setDropdownPriorityValue('alta') }}
                            >
                              <GrStatusInfoSmall className="text-danger"/>alta
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>

                        <br/><br/>
                        <Dropdown isOpen={dropdownRepetitionOpen} toggle={toggleDropdownRepetition}>
                          <DropdownToggle caret>
                            { dropdownRepetitionValue === 'no-selected' ? <BsCheckSquare className="text-primary"/> : undefined }
                            { dropdownRepetitionValue === 'no' ? <BsCheckSquare className="text-danger"/> : undefined }
                            { dropdownRepetitionValue === '7d' ? <AiOutlineSync className="text-success"/> : undefined }
                            { dropdownRepetitionValue === '15d' ? <AiOutlineSync className="text-success"/> : undefined }
                            { dropdownRepetitionValue === '30d' ? <AiOutlineSync className="text-success"/> : undefined }
                            { dropdownRepetitionValue === '2m' ? <AiOutlineSync className="text-success"/> : undefined }
                            { dropdownRepetitionValue === '4m' ? <AiOutlineSync className="text-success"/> : undefined }
                            { dropdownRepetitionValue === '6m' ? <AiOutlineSync className="text-success"/> : undefined }
                            { dropdownRepetitionValue === '12m' ? <AiOutlineSync className="text-success"/> : undefined }
                            &nbsp;{ dropdownRepetitionLabel }
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              className="text-muted" 
                              header
                            >
                              selecione a recorrência
                            </DropdownItem>
                            <DropdownItem
                              disabled={isUpdate}
                              onClick={() => { setDropdownRepetitionValue('no'); setDropdownRepetitionLabel('não é recorrente'); }}
                            >
                              <BsCheckSquare className="text-danger"/>não é recorrente
                            </DropdownItem>
                            <DropdownItem 
                              disabled={isUpdate}
                              onClick={() => { setDropdownRepetitionValue('7d'); setDropdownRepetitionLabel('repete-se a cada 7 dias'); }}
                            >
                              <AiOutlineSync className="text-success"/>repete-se a cada 7 dias
                            </DropdownItem>
                            <DropdownItem 
                              disabled={isUpdate}
                              onClick={() => { setDropdownRepetitionValue('15d'); setDropdownRepetitionLabel('repete-se a cada 15 dias'); }}
                            >
                              <AiOutlineSync className="text-success"/>repete-se a cada 15 dias
                            </DropdownItem>
                            <DropdownItem 
                              disabled={isUpdate}
                              onClick={() => { setDropdownRepetitionValue('30d'); setDropdownRepetitionLabel('repete-se a cada 30 dias'); }}
                            >
                              <AiOutlineSync className="text-success"/>repete-se a cada 30 dias
                            </DropdownItem>
                            <DropdownItem 
                              disabled={isUpdate}
                              onClick={() => { setDropdownRepetitionValue('2m'); setDropdownRepetitionLabel('repete-se a cada 2 meses (bimestral)'); }}
                            >
                              <AiOutlineSync className="text-success"/>repete-se a cada 2 meses (bimestral)
                            </DropdownItem>
                            <DropdownItem 
                              disabled={isUpdate}
                              onClick={() => { setDropdownRepetitionValue('4m'); setDropdownRepetitionLabel('repete-se a cada 4 meses (quadrimestral)'); }}
                            >
                              <AiOutlineSync className="text-success"/>repete-se a cada 4 meses (quadrimestral)
                            </DropdownItem>
                            <DropdownItem 
                              disabled={isUpdate}
                              onClick={() => { setDropdownRepetitionValue('6m'); setDropdownRepetitionLabel('repete-se a cada 6 meses (semestral)'); }}
                            >
                              <AiOutlineSync className="text-success"/>repete-se a cada 6 meses (semestral)
                            </DropdownItem>
                            <DropdownItem 
                              disabled={isUpdate}
                              onClick={() => { setDropdownRepetitionValue('12m'); setDropdownRepetitionLabel('repete-se a cada 12 meses (anual)'); }}
                            >
                              <AiOutlineSync className="text-success"/>repete-se a cada 12 meses (anual)
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </InputGroup>
                    </FormGroup>

                    <Row id="TooltipCompletionDeadline" form>
                      <Col md={7}>
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <MuiPickersUtilsProvider locale={ptBrLocale} utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disabled={isRecurrentTask}
                                margin="normal"
                                id="date-picker-dialog"
                                label="Até quando deve ser feito?"
                                format="dd/MM"
                                cancelLabel="Cancelar"
                                invalidDateMessage="Formato inválido"
                                value={selectedCompletionDateHour}
                                onChange={handleCompletionDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={5}>
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <MuiPickersUtilsProvider locale={ptBrLocale} utils={DateFnsUtils}>
                              <KeyboardTimePicker
                                disabled={isRecurrentTask}
                                ampm={false}
                                margin="normal"
                                id="time-picker"
                                label="Em qual horário?"
                                cancelLabel="Cancelar"
                                invalidDateMessage="Formato inválido"
                                value={selectedCompletionDateHour}
                                onChange={handleCompletionDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Tooltip placement="auto" isOpen={tooltipCompletionDeadlineOpen} target="TooltipCompletionDeadline" toggle={() => toggleTooltip('completion_deadline')}>
                      É aqui que configuramos até que dia e horário essa tarefa deve ser realizada. Em caso de tarefas recorrentes, deve-se selecionar a primeira entrega e o sistema cuida do restante :). O ano, nesse caso, é indiferente, pois será levado em consideração o serviço associado configurado no cadastro do cliente.
                    </Tooltip>
                   
                  </Form>
                </Col>
                <Col xs="5" sm="5">
                <React.Fragment>
                    <h5> <strong>Checklist | lista de etapas</strong> </h5>
                    <ChecklistTaskContainer 
                      icon={FaComments} 
                      statusColor="primary"
                    >
                      <React.Fragment>

                        {!taskTemplateChecklist.length 
                          ? <i><small>ainda não existem itens de checklist para exibir...</small></i>
                          : taskTemplateChecklist.map((checklistItem) => (
                          <FormGroup check>
                            <Label check>
                              <Input 
                                key={ checklistItem.id } 
                                type="checkbox" 
                                checked={false}
                                onChange={() => {handleTemplateChecklistCheckboxItemChange()}} 
                              />{' '}
                              { checklistItem.item }
                            </Label>
                          </FormGroup>
                        ))}

                      </React.Fragment>

                    </ChecklistTaskContainer>

                    <Button id="PopoverWriteChecklist" size="sm" color="primary" type="button">
                      Adicionar etapa
                    </Button>
                    <Popover placement="bottom" isOpen={popoverTemplateChecklistOpen} target="PopoverWriteChecklist" toggle={toggleTemplateChecklistPopover}>
                      <PopoverHeader>Adicionar etapa 
                        <a onClick={toggleTemplateChecklistPopover}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        x
                        </a>
                      </PopoverHeader>
                      <PopoverBody>
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <MdTitle/>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                              id="inputChecklistItem"
                              innerRef={inputTemplateChecklistItemRef} 
                              name="item" 
                              placeholder="Descreva em uma frase" 
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>
                        <br/>
                        <Button size="sm" onClick={handleSubmitTaskTemplateChecklistItem} color="primary" type="button">
                          Confirmar etapa
                        </Button>
                      </PopoverBody>
                    </Popover>
                  </React.Fragment>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="bg-secondary">
              <Button color="primary" disabled={isSaving} onClick={handleSubmit}>
                { isSaving
                  ? <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> Salvando...
                    </>
                  : 'Salvar'
                }
              </Button>{' '}
              <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
            </ModalFooter>
          </Modal>

          <Modal className="modal-lg" isOpen={modalServices} toggle={toggleModalServices}>
            <ModalHeader className="bg-secondary" toggle={toggleModalServices}>
              Lista de serviços disponíveis
            </ModalHeader>
            <ModalBody className="bg-secondary">
              <Row>
                <Col xs="12" sm="12">
                  <MUIDataTable
                    title={"Serviços"}
                    data={services}
                    columns={
                      [
                        {
                          name: "id",
                          label: "id",
                          options: {
                            filter: false,
                            display: "excluded"
                          }
                        },
                        {
                          name: "title",
                          label: "Título",
                          options: {
                            filter: false,
                            sort: true
                          }
                        }
                      ]
                    }
                    options={
                      {
                        selectableRows: 'none',
                        onRowClick: (rowData: string[]) => {handleTaskTemplatesRowClicked(rowData)},
                        textLabels: {
                          body: {
                            noMatch: "Desculpe, nenhum registro encontrado",
                            toolTip: "Ordernar",
                            columnHeaderTooltip: (column: any) => `Ordernar por ${column.label}`
                          },
                          pagination: {
                            next: "Próxima Página",
                            previous: "Página Anterior",
                            rowsPerPage: "Registros por página:",
                            displayRows: "de",
                          },
                          toolbar: {
                            search: "Buscar",
                            downloadCsv: "Baixar CSV",
                            print: "Imprimir",
                            viewColumns: "Visualizar Colunas",
                            filterTable: "Filtrar Tabela",
                          },
                          filter: {
                            all: "Tudo",
                            title: "FILTROS",
                            reset: "LIMPAR",
                          },
                          viewColumns: {
                            title: "Exibir Colunas",
                            titleAria: "Exibir/Ocultar Colunas da Tabela",
                          },
                          selectedRows: {
                            text: "registro(s) selecionados",
                            delete: "Apagar",
                            deleteAria: "Apagar registros selecionados",
                          },
                        }
                      }
                    }
                  />  
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="bg-secondary">
              <Button id="PopoverWriteService" color="primary" type="button">
                <BsPlusCircle/> Incluir Serviço
              </Button>
              <Popover placement="bottom" isOpen={popoverServiceOpen} target="PopoverWriteService" toggle={toggleServicePopover}>
                <PopoverHeader>Adicionar Serviço 
                  <a onClick={toggleServicePopover}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;
                  x
                  </a>
                </PopoverHeader>
                <PopoverBody>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <MdTitle/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input 
                        id="inputServiceTitle"
                        innerRef={inputServiceTitleRef} 
                        name="title" 
                        placeholder="Descreva em uma frase" 
                        type="text"
                      />
                    </InputGroup>
                  </FormGroup>
                  <br/>
                  <Button size="sm" onClick={handleSubmitService} color="primary" type="button">
                    Incluir serviço
                  </Button>
                </PopoverBody>
              </Popover>
              <Button color="secondary" onClick={toggleModalServices}>Fechar</Button>
            </ModalFooter>
          </Modal>

          <div className="header-body">
            <Row>
              <Col lg="9" xl="9">
                <MUIDataTable
                  title={"Modelos de tarefas"}
                  data={taskTemplates}
                  columns={
                    [
                      {
                        name: "id",
                        label: "id",
                        options: {
                          filter: false,
                          display: "excluded"
                        }
                      },
                      {
                        name: "title",
                        label: "Título",
                        options: {
                          filter: false,
                          sort: true
                        }
                      },
                      {
                        name: "priority",
                        label: "Prioridade padrão",
                        options: {
                        filter: false,
                        sort: false
                        }
                      },
                      {
                        name: "completion_deadline",
                        label: "Primeiro prazo de entrega",
                        options: {
                        filter: true,
                        sort: true
                        }
                      },
                      {
                        name: "recurrent_type",
                        label: "Tipo de repetição",
                        options: {
                        filter: true,
                        sort: true
                        }
                      }
                    ]
                  }
                  options={
                    {
                      selectableRows: 'none',
                      onRowClick: (rowData: string[]) => {handleTaskTemplatesRowClicked(rowData)},
                      textLabels: {
                        body: {
                          noMatch: "Desculpe, nenhum registro encontrado",
                          toolTip: "Ordernar",
                          columnHeaderTooltip: (column: any) => `Ordernar por ${column.label}`
                        },
                        pagination: {
                          next: "Próxima Página",
                          previous: "Página Anterior",
                          rowsPerPage: "Registros por página:",
                          displayRows: "de",
                        },
                        toolbar: {
                          search: "Buscar",
                          downloadCsv: "Baixar CSV",
                          print: "Imprimir",
                          viewColumns: "Visualizar Colunas",
                          filterTable: "Filtrar Tabela",
                        },
                        filter: {
                          all: "Tudo",
                          title: "FILTROS",
                          reset: "LIMPAR",
                        },
                        viewColumns: {
                          title: "Exibir Colunas",
                          titleAria: "Exibir/Ocultar Colunas da Tabela",
                        },
                        selectedRows: {
                          text: "registro(s) selecionados",
                          delete: "Apagar",
                          deleteAria: "Apagar registros selecionados",
                        },
                      }
                    }
                  }
                />
              </Col>
              <Col lg="3" xl="3">
                <Button color="primary" onClick={handleNewTaskTemplate}>
                  <BsPlusCircle/> Incluir Modelo
                </Button>
                <Button color="primary" onClick={handleServices}>
                  <BiCog/> Configurar serviços
                </Button>
                <br/><br/>
                <TasksNumbersCard
                  onClick={() => { history.push('/tarefas/minhas-tarefas') }}
                  title="Modelos de tarefas" 
                  description="Modelos de tarefas cadastrados"
                  icon={AiOutlineBlock} 
                  circleColor="primary"
                  tasksQuantity={ taskTemplates.length }
                />
              </Col>
            </Row>

          </div>
        </Container>
      </div>
      <GradientFooter/>
    </React.Fragment>
  );
}

export default TaskTemplates;