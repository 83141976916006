import React, { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { useTransition } from 'react-route-transition';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Label
} from 'reactstrap';
import swal from 'sweetalert';

import MUIDataTable from "mui-datatables";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from "date-fns/locale/pt-BR";

import * as Yup from 'yup';

import useAuth from '../../hooks/useAuth';
import api from '../../services/api';
import { GlobalStyle } from './styles';

import DefaultNavbar from '../../components/Navbars/DefaultNavbar';
import GradientFooter from '../../components/Footers/GradientFooter';
import TasksNumbersCard from '../../components/Cards/TasksNumbersCard';

import { BsPlusCircle, BsAlarm } from 'react-icons/bs';
import { MdTitle } from 'react-icons/md';
import { SiGooglecalendar } from 'react-icons/si';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface Reminder {
  id: string;
  title: string;
  reminder_date: Date;
  reminderDateLabel: string;
  created_at: string;
  updated_at: string;
}

const Reminders = () => {

  const { signOut, refreshToken } = useAuth();

  const inputTitleRef = useRef<HTMLInputElement>(null);
  const inputMutedNotificantionsRef = useRef<HTMLInputElement>(null);

  const [reminders, setReminders] = useState<Reminder[]>([{}] as Reminder[]);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setSelectedReminderDateHour(null);
    setModal(!modal);
  };
  
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [reminderId, setReminderId] = useState<string | null>(null);

  const [selectedReminderDateHour, setSelectedReminderDateHour] = useState<Date | null>(null);

  const getReminders = async() => {
    try{
      const response = await api.get('reminders');

      const data: Reminder[] = response.data;

      data.map(reminder => {
        reminder.reminderDateLabel = `${
          new Date(reminder.reminder_date).toLocaleDateString('pt-BR', {day: 'numeric', weekday: 'long', month: 'long', year: 'numeric'})} 
          às 
          ${new Date(reminder.reminder_date).toLocaleTimeString('pt-BR')}`;
      });
      
      setReminders(data);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

    }catch(err){
      if (err.response && err.response.status === 401) {
        signOut();
      }
    }
  }
  
  useEffect(() => {

    getReminders();

  }, [signOut, refreshToken]);

  useTransition({
    handlers: [
      {
        path: '/meus-lembretes-e-compromissos',
        onEnter: async () => {
          await gsap.timeline().fromTo
          ( 
            '[data-reminders-main]',  
            { opacity: 0, y: 20 },  
            { duration: 0.6, stagger: 0.125, y: 0, opacity: 1 }
          ) 
        },
        onLeave: async () => {
          await gsap.timeline().to('[data-reminders-main]', {
            duration: 0.6,
            stagger: 0.125,
            opacity: 0,
            y: -20,
          })
        },
      },
    ],
  });

  const handleReminderDateChange = (date: MaterialUiPickersDate) => {
    setSelectedReminderDateHour(date);
  };

  const handleRemindersRowClicked = async (rowData: string[]) => {
    setIsUpdate(true);

    const rowReminderId = rowData[0];
    setReminderId(rowReminderId);

    try {
      const response = await api.get(`reminders/${rowReminderId}`);
      toggleModal();
      
      inputTitleRef.current!.value = response.data.title;
      setSelectedReminderDateHour(response.data.reminder_date);
      inputMutedNotificantionsRef.current!.checked = response.data.muted;

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }
    }catch(err) {
      swal("Oppss!", err, "error");
    }
  }

  const handleSubmit = async() => {

    setIsSaving(true);

    const data = {
      title: inputTitleRef.current!.value,
      reminder_date: selectedReminderDateHour,
      muted: inputMutedNotificantionsRef.current!.checked
    }

    console.log(data);

    try {

      const schema = Yup.object().shape({
        title:
          Yup.string()
          .required('o título do lembrete não pode ficar vazio.'),
          reminder_date:
          Yup.date()
          .nullable(true)
          .required('A data e hora não podem deixar de ser informadas.')
      });

      await schema.validate(data, {abortEarly: false} );

    }catch (err) {
      if (err.errors) {
        swal("Oppss!", err.errors[0], "error");
      }else {
        swal("Oppss! o servidor retornou o seguinte erro:", err, "error");
      }
      setIsSaving(false);
    }

    if (isUpdate) {
      try {
        const response = await api.put(`reminders/${reminderId}`, data);
        toggleModal();
        swal("Tudo certo :)", "O lembrete foi atualizado!", "success");

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }
      }catch(err) {
        swal("Oppss!", err, "error");
      }
    }else{
      try {
        const response = await api.post('reminders', data);
        toggleModal();
        swal("Feito!", "O lembrete foi adicionado!", "success");

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }
      }catch(err) {
        swal("Oppss!", err, "error");
      }
    }
    
    setIsSaving(false);

    getReminders();

  }

  return (
    <React.Fragment>
      <GlobalStyle /> 
      <DefaultNavbar />
      <div data-reminders-main className="header pb-8 pt-5 pt-md-4">
        <Container fluid>

          <Modal className="modal-lg" isOpen={modal} toggle={toggleModal}>
            <ModalHeader className="bg-secondary" toggle={toggleModal}>
              { isUpdate ? 'Editar Lembrete' : 'Adicionar Lembrete' }
            </ModalHeader>
            <ModalBody className="bg-secondary">

              <Row>
                <Col sm="12">
                  <Form role="form">

                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <MdTitle/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input 
                          id="inputTitle"
                          innerRef={inputTitleRef} 
                          name="title" 
                          placeholder="O que deve ser lembrado?" 
                          type="text"
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <SiGooglecalendar/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <MuiPickersUtilsProvider locale={ptBrLocale} utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Quando deve ser lembrado?"
                            format="dd/MM/yyyy"
                            cancelLabel="Cancelar"
                            invalidDateMessage="Formato inválido"
                            value={selectedReminderDateHour}
                            onChange={handleReminderDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <SiGooglecalendar/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <MuiPickersUtilsProvider locale={ptBrLocale} utils={DateFnsUtils}>
                          <KeyboardTimePicker
                            ampm={false}
                            margin="normal"
                            id="time-picker"
                            label="Em qual horário?"
                            cancelLabel="Cancelar"
                            invalidDateMessage="Formato inválido"
                            value={selectedReminderDateHour}
                            onChange={handleReminderDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Label check>
                          <Input
                            type="checkbox"
                            innerRef={inputMutedNotificantionsRef}
                          />{' '}
                          silenciar notificações
                        </Label>
                      </InputGroup>
                    </FormGroup>

                  </Form>
                </Col>
              </Row>
              
            </ModalBody>
            <ModalFooter className="bg-secondary">
              <Button color="primary" disabled={isSaving} onClick={handleSubmit}>
                { isSaving
                  ? <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> Salvando...
                    </>
                  : 'Salvar'
                }
              </Button>{' '}
              <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
            </ModalFooter>
          </Modal>

          <div className="header-body">
            <Row>
              <Col lg="9" xl="9">
              <MUIDataTable
                title={"Meus Compromissos e Lembretes"}
                data={reminders}
                columns={
                  [
                    {
                      name: "id",
                      label: "id",
                      options: {
                        filter: false,
                        display: "excluded"
                      }
                    },
                    {
                      name: "title",
                      label: "Lembrar-me de",
                      options: {
                        filter: false,
                        sort: true
                      }
                    },
                    {
                      name: "reminderDateLabel",
                      label: "Lembrar-me em",
                      options: {
                       filter: false,
                       sort: true
                      }
                    }
                  ]
                }
                options={
                  {
                    selectableRows: 'none',
                    onRowClick: (rowData: string[]) => {handleRemindersRowClicked(rowData)},
                    textLabels: {
                      body: {
                        noMatch: "Desculpe, nenhum registro encontrado",
                        toolTip: "Ordernar",
                        columnHeaderTooltip: (column: any) => `Ordernar por ${column.label}`
                      },
                      pagination: {
                        next: "Próxima Página",
                        previous: "Página Anterior",
                        rowsPerPage: "Registros por página:",
                        displayRows: "de",
                      },
                      toolbar: {
                        search: "Buscar",
                        downloadCsv: "Baixar CSV",
                        print: "Imprimir",
                        viewColumns: "Visualizar Colunas",
                        filterTable: "Filtrar Tabela",
                      },
                      filter: {
                        all: "Tudo",
                        title: "FILTROS",
                        reset: "LIMPAR",
                      },
                      viewColumns: {
                        title: "Exibir Colunas",
                        titleAria: "Exibir/Ocultar Colunas da Tabela",
                      },
                      selectedRows: {
                        text: "registro(s) selecionados",
                        delete: "Apagar",
                        deleteAria: "Apagar registros selecionados",
                      },
                    }
                  }
                }
              />
              </Col>
              <Col lg="3" xl="3">
                <Button color="primary" onClick={toggleModal}>
                  <BsPlusCircle/> Incluir Lembrete
                </Button>
                <br/><br/>
                <TasksNumbersCard 
                  title="Lembretes" 
                  description="Lembretes e compromissos"
                  icon={BsAlarm} 
                  circleColor="primary"
                  tasksQuantity={ reminders.length }
                />
              </Col>
            </Row>

          </div>
        </Container>
      </div>
      <GradientFooter/>
    </React.Fragment>
  );
}

export default Reminders;