import React from "react";
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import { useTransition, useTransitionHistory } from 'react-route-transition';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import { FaRegEnvelope } from 'react-icons/fa';

import { GlobalStyle, Logo } from './styles';

import LoginNavbar from '../../../components/Navbars/LoginNavbar';
import SimpleFooter from '../../../components/Footers/SimpleFooter'

import logoDefault from '../../../assets/img/logo/logo-orcoma-default.png';

const Login = () => {

  const history = useTransitionHistory();

  useTransition({
    handlers: [
      {
        path: '/resetar-senha',
        onEnter: async () => {
          await gsap.timeline().to('[data-login-container] > *, [data-login-links]', {
            duration: 0.3,
            stagger: 0.125,
            opacity: 0,
            xPercent: 100,
          }).reverse(0.3)
        },
        onLeave: async () => {
          await gsap.timeline().to('[data-login-container] > *, [data-login-links]', {
            duration: 0.3,
            stagger: 0.125,
            opacity: 0,
            yPercent: -100,
          });
        }
      }
    ],
  });

  async function handleLogin(e: React.FormEvent) {
    e.preventDefault();
    history.push('/dashboard');
  }

  async function handleGoBackLogin(e: React.FormEvent) {
    e.preventDefault();
    history.push('/');
  }

  async function handleRegisterUser(e: React.FormEvent) {
    e.preventDefault();
    history.push('/registrar-usuario');
  }

  return (
    <React.Fragment>
      <GlobalStyle />
      <LoginNavbar />
      <section className="section section-shaped section-lg">
        <Container data-login-container className="pt-lg-7">
          <Row className="justify-content-center">
            <Col lg="5">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-white pb-3">
                  <div className="text-center">
                    <Logo src={logoDefault} />
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form">
                    <p>
                      Esqueceu sua senha? A gente cuida disso pra você: vamos te enviar um link para que você possa criar uma nova.
                    </p>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <FaRegEnvelope/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email" type="email" />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button onClick={handleLogin}
                        className="my-4"
                        color="primary"
                        type="button"
                      >
                        Recuperar senha
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row data-login-links className="mt-3">
                <Col className="text-light" xs="6">
                  <Link className="text-light" to="#" onClick={ handleGoBackLogin } >
                    <small>Fazer login</small>
                  </Link>
                </Col>
                <Col className="text-right" xs="6">
                  <Link className="text-light" to="#" onClick={ handleRegisterUser }>
                    <small>Criar uma nova conta</small>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <SimpleFooter/>
    </React.Fragment>
  );
}

export default Login;