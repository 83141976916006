import React, { useState, useEffect, useCallback, useRef } from 'react';
import gsap from 'gsap';
import { useTransition } from 'react-route-transition';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Popover,
  PopoverHeader,
  PopoverBody,
  Label,
  Progress,
  Badge,
  Alert,
  Tooltip
} from 'reactstrap';
import swal from 'sweetalert';

import { v4 as uuidv4 } from 'uuid';

import Select from 'react-select'
import makeAnimated from 'react-select/animated';

import * as Yup from 'yup';

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useTransitionHistory } from 'react-route-transition';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getMonth, getYear ,startOfMonth, endOfMonth } from 'date-fns';
import ptBrLocale from "date-fns/locale/pt-BR";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import useAuth from '../../../hooks/useAuth';
import api from '../../../services/api';

import DefaultNavbar from '../../../components/Navbars/DefaultNavbar';
import GradientFooter from '../../../components/Footers/GradientFooter';

import StatusTasksContainer from '../../../components/Containers/StatusTasksContainer';
import TaskCard from '../../../components/Cards/TaskCard';
import CommentCard from '../../../components/Cards/CommentCard';

import CommentsContainer from '../../../components/Containers/CommentsContainer';
import ChecklistTaskContainer from '../../../components/Containers/ChecklistTaskContainer';

import { GlobalStyle } from './styles';

import { 
  BsStopwatch, 
  BsArchive, 
  BsCheckSquare, 
  BsPlusCircleFill, 
  BsCardText
} from 'react-icons/bs';
import { AiOutlineSync, AiOutlineBlock } from 'react-icons/ai';
import { MdTitle } from 'react-icons/md';
import { GrStatusInfoSmall } from 'react-icons/gr';
import { FiRefreshCcw, FiUsers, FiUser } from 'react-icons/fi';
import { BiCalendar } from 'react-icons/bi';
import { FaComments, FaRegCalendarCheck } from 'react-icons/fa';

import { User } from '../../../context/AuthContext';

interface TaskData {
  id: string;
  title: string;
  description: string;
  status: string;
  priority: string;
  private_task: boolean;
  created_at: Date;
  createdAtLabel: string;
  updated_at: Date;
  deleted_at?: Date;
  priorityColor: string;
  users: User[];
  taskComments: TaskComment[];
  completion_deadline: Date;
  completionDeadlineLabel: string;
  recurrent_type: string;
  client: Client;
  taskChecklists: ChecklistItem[];
  checklistPercentage: number;
  deadline_group: string;
  openByUser: User;
  completed_at: Date;
  taskTemplate?: TaskTemplate;
  personService?: PersonService;
  repeat_date_limit?: Date;
  repeatDateLimitLabel?: string;
}

interface TaskTemplate {
  id: string;
  title: string;
}

interface PersonService {
  id: string;
  service: Service;
}

interface Service {
  id: string;
  title: string;
}

interface TaskComment {
  id: string;
  comment: string;
  created_at: Date;
  createdAtLabel: string;
  updated_at: Date;
  deleted_at?: Date;
  commentedByUser: User;
}

interface Client {
  id: string;
  fantasy_name: string;
}

interface ChecklistItem {
  id: string;
  item: string;
  is_checked: boolean;
  item_order?: number;
  created_at?: Date;
}

interface SelectDataFormat {
  value: string;
  label: string;
}

interface Mention {
  text: string;
  value: string;
  url: string;
}

const MyTasks = () => {

  const { signOut, user, refreshToken } = useAuth();

  const history = useTransitionHistory();

  const [isUpdate, setIsUpdate] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isRecurrentTask, setIsRecurrentTask] = useState(false);
  const [taskChecklistPercentage, setTaskChecklistPercentage] = useState(0);

  const [firstDayOfMonth, setFirstDayOfMonth] = useState<Date>(startOfMonth(new Date()));
  const [lastDayOfMonth, setLastDayOfMonth] = useState<Date>(endOfMonth(new Date()));

  const [selectedCompletionDateHour, setSelectedCompletionDateHour] = useState<Date | null>(null);
  const [selectedRepeatDateLimit, setSelectedRepeatDateLimit] = useState<Date | null>(null);

  const [dropdownRepetitionOpen, setDropdownRepetitionOpen] = useState(false);
  const [dropdownRepetitionValue, setDropdownRepetitionValue] = useState('no-selected');
  const [dropdownRepetitionLabel, setDropdownRepetitionLabel] = useState('selecione a recorrência');
  const toggleDropdownRepetition = () => setDropdownRepetitionOpen(prevState => !prevState);

  const [dropdownStatusOpen, setDropdownStatusOpen] = useState(false);
  const [dropdownStatusValue, setDropdownStatusValue] = useState('em aberto');
  const toggleDropdownStatus = () => setDropdownStatusOpen(prevState => !prevState);

  const [dropdownPriorityOpen, setDropdownPriorityOpen] = useState(false);
  const [dropdownPriorityValue, setDropdownPriorityValue] = useState('normal');
  const toggleDropdownPriority = () => setDropdownPriorityOpen(prevState => !prevState);

  const [dropdownMonthSelectionOpen, setDropdownMonthSelectionOpen] = useState(false);
  const [dropdownMonthSelectionValue, setDropdownMonthSelectionValue] = useState(() => {
    
    const currentMonth = getMonth(new Date()) + 1;

    switch (currentMonth) {
      case 1:
        return 'Janeiro';
      case 2:
        return 'Fevereiro';
      case 3:
        return 'Março';
      case 4:
        return 'Abril';
      case 5:
        return 'Maio';
      case 6:
        return 'Junho';
      case 7:
        return 'Julho';
      case 8:
        return 'Agosto';
      case 9:
        return 'Setembro';
      case 10:
        return 'Outubro';
      case 11:
        return 'Novembro';
      case 12:
        return 'Dezembro';
      default:
        return 'Janeiro';
    }
  });
  const toggleDropdownMonthSelection = () => setDropdownMonthSelectionOpen(prevState => !prevState);

  const [dropdownYearSelectionOpen, setDropdownYearSelectionOpen] = useState(false);
  const [dropdownYearSelectionValue, setDropdownYearSelectionValue] = useState(getYear(new Date()));
  const toggleDropdownYearSelection = () => setDropdownYearSelectionOpen(prevState => !prevState);

  const [dropdownClientSelectionOpen, setDropdownClientSelectionOpen] = useState(false);
  const [dropdownClientSelectionValue, setDropdownClientSelectionValue] = useState('Todos os clientes');
  const [clientFilterSelectionId, setClientFilterSelectionId] = useState('all');
  const toggleDropdownClientSelection = () => setDropdownClientSelectionOpen(prevState => !prevState);

  const [repeatDateLimitLabel, setRepeatDateLimitLabel] = useState('');

  const inputChecklistItemRef = useRef<HTMLInputElement>(null);

  const inputTitleRef = useRef<HTMLInputElement>(null);
  const inputDescriptionRef = useRef<HTMLInputElement>(null);

  const [taskId, setTaskId] = useState<string | undefined>('');
  const [inputTitle, setInputTitle] = useState<string | undefined>('');
  const [inputDescription, setInputDescription] = useState<string | undefined>('');

  const [clients, setClients] = useState<SelectDataFormat[]>([] as SelectDataFormat[]);
  const [selectedClient, setSelectedClient] = useState<SelectDataFormat | null>(null);

  const [usersInTask, setUsersInTask] = useState<SelectDataFormat[]>([] as SelectDataFormat[]);
  const [selectedUsersInTask, setSelectedUsersInTask] = useState<SelectDataFormat[]>([] as SelectDataFormat[]);

  const [usersForMention, setUsersForMention] = useState<Mention[]>([] as Mention[]);

  const [taskChecklist, setTaskChecklist] = useState<ChecklistItem[]>([] as ChecklistItem[]);

  const [cardTasksWaitingVisibility, setCardTasksWaitingVisibility] = useState('');
  const [cardTasksInProgressVisibility, setCardTasksInProgressVisibility] = useState('');
  const [cardTasksArchivedVisibility, setCardTasksArchivedVisibility] = useState('');
  const [cardTasksDoneVisibility, setCardTasksDoneVisibility] = useState('');

  const [tasksWaiting, setTasksWaiting] = useState<TaskData[]>([]);
  const [tasksInProgress, setTasksInProgress] = useState<TaskData[]>([]);
  const [tasksArchived, setTasksArchived] = useState<TaskData[]>([]);
  const [tasksDone, setTasksDone] = useState<TaskData[]>([]);

  const [taskTemplateTitle, setTaskTemplateTitle] = useState<string | undefined | null>(null);
  const [personServiceTitle, setPersonServiceTitle] = useState<string | undefined | null>(null);
  const [openByUsername, setOpenByUsername] = useState<string | undefined | null>(null);

  const [taskCommentsToShow, setTaskCommentsToShow] = useState<TaskComment[]>([]);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const [popoverChecklistOpen, setPopoverChecklistOpen] = useState(false);
  const toggleChecklistPopover = () => setPopoverChecklistOpen(!popoverChecklistOpen);

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  const [modal, setModal] = useState(false);
  
  const toggleModal = () => {

    if (modal){
      setPopoverOpen(false);
      setPopoverChecklistOpen(false);
      setTaskChecklist([]);
    }

    setEditorState(EditorState.createEmpty());
    setModal(!modal);
  }

  const [tooltipRepetionDateLimitOpen, setTooltipRepetionDateLimitOpen] = useState(false);
  const [tooltipCompletionDeadlineOpen, setTooltipCompletionDeadlineOpen] = useState(false);

  const toggleTooltip = (item: string) => {

    switch (item) {
      case 'repeat_date_limit':
        setTooltipRepetionDateLimitOpen(!tooltipRepetionDateLimitOpen);
        break;
      case 'completion_deadline':
        setTooltipCompletionDeadlineOpen(!tooltipCompletionDeadlineOpen);
        break;
      default:
        break;
    }
  }

  const animatedComponents = makeAnimated();

  const update = () => {

    const getTasks = async() => {

      setIsUpdating(true)

      const username = user.username;

      try{
        const response = await api.get(`users/tasks/${username}/?startingAt=${firstDayOfMonth}&endingAt=${lastDayOfMonth}&client=${clientFilterSelectionId}`);

        const populate = (status: string) => {
          
          const data: TaskData[] = response.data;
          const tasks: TaskData[] = [];          

          data.map(task => {

            task.taskChecklists.sort((a, b) => a.item_order! - b.item_order!);
            
            var quantityChecked = 0;
            task.taskChecklists.map(checklistItem => {
              if (checklistItem.is_checked){
                quantityChecked = quantityChecked + 1;
              }
            });
            const percentage = quantityChecked * 100 / task.taskChecklists.length;
            task.checklistPercentage = percentage;

            if (task.repeat_date_limit) {
              task.repeatDateLimitLabel = `${
              new Date(task.repeat_date_limit).toLocaleDateString('pt-BR', {day: 'numeric', weekday: 'long', month: 'long', year: 'numeric'})} 
              às 
              ${new Date(task.repeat_date_limit).toLocaleTimeString('pt-BR')}`;
              setRepeatDateLimitLabel(task.repeatDateLimitLabel);
            }

            task.createdAtLabel = `${
            new Date(task.created_at).toLocaleDateString('pt-BR', {day: 'numeric', weekday: 'long', month: 'long', year: 'numeric'})} 
            às 
            ${new Date(task.created_at).toLocaleTimeString('pt-BR')}`;

            task.completionDeadlineLabel = `${
              new Date(task.completion_deadline).toLocaleDateString('pt-BR', {day: 'numeric', weekday: 'long', month: 'long', year: 'numeric'})} 
              às 
              ${new Date(task.completion_deadline).toLocaleTimeString('pt-BR')}`;

            if ( task.status === status ) {
              switch (task.priority) {
                case 'baixa':
                  task.priorityColor = 'success';
                  break;
                case 'normal':
                  task.priorityColor = 'yellow';
                  break;
                case 'alta':
                  task.priorityColor = 'danger';
                  break;
                default:
                  break;
              }
              tasks.push(task);
            }
          });
          return tasks;
        }

        setTasksWaiting(populate('em aberto'));
        setTasksInProgress(populate('em andamento'));
        setTasksArchived(populate('arquivada'));
        setTasksDone(populate('concluída'));

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }

      }catch(err: any){
        if (err.response && err.response.status === 401) {
          signOut();
        }
      }

      setIsUpdating(false);

    }

    getTasks();

  }

  const getClients = async() => {
    try{
      const response = await api.get<Client[]>('persons/types/cliente');

      const clients: SelectDataFormat[] = [];
      response.data.map((client) => {
        clients.push({value: client.id, label: client.fantasy_name});
      });
      setClients(clients);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

    }catch(err: any){
      if (err.response && err.response.status === 401) {
        signOut();
      }
    }
  }

  const handleChangeSelectUsersInTask = (selectedOption: any) => {
    setSelectedUsersInTask(selectedOption);
  }

  const handleCompletionDateChange = (date: MaterialUiPickersDate) => {
    setSelectedCompletionDateHour(date);
  };

  const handleRepeatDateLimit = (date: MaterialUiPickersDate) => {
    setSelectedRepeatDateLimit(date);
  };

  const handleChangeSelectClient = (selectedOption: SelectDataFormat | null) => {
    if (selectedOption !== undefined && selectedOption !== null) {
      setSelectedClient(selectedOption);
    }
  }

  useEffect(() => {

    update();
    getUsersForMention();
    getClients();
    calculateChecklistPercentage();

  }, [firstDayOfMonth, lastDayOfMonth, taskChecklist]);

  useTransition({
    handlers: [
      {
        path: '/tarefas/minhas-tarefas',
        onEnter: async () => {

          setCardTasksWaitingVisibility('');
          setCardTasksInProgressVisibility('d-none');
          setCardTasksArchivedVisibility('d-none');
          setCardTasksDoneVisibility('d-none');

          await gsap.timeline().to('[data-animation-tasks-waiting]', {
            duration: 0.3,
            stagger: 0.125,
            opacity: 0,
            xPercent: 100,
          }).reverse(0.3);
          setCardTasksInProgressVisibility('visible');

          await gsap.timeline().to('[data-animation-tasks-inprogress]', {
            duration: 0.3,
            stagger: 0.125,
            opacity: 0,
            xPercent: 100,
          }).reverse(0.3);
          setCardTasksArchivedVisibility('visible');

          await gsap.timeline().to('[data-animation-tasks-archived]', {
            duration: 0.3,
            stagger: 0.125,
            opacity: 0,
            xPercent: 100,
          }).reverse(0.3);
          setCardTasksDoneVisibility('visible');

          await gsap.timeline().to('[data-animation-tasks-done]', {
            duration: 0.3,
            stagger: 0.125,
            opacity: 0,
            xPercent: 100,
          }).reverse(0.3);

        },
        onLeave: async () => {
          
          await gsap.timeline().to(
            '[data-animation-tasks-waiting] > *, [data-animation-tasks-inprogress] > *, [data-animation-tasks-archived] > *, [data-animation-tasks-done]', 
          {
            duration: 0.6,
            stagger: 0.125,
            opacity: 0,
            y: -20,
          });

        },
      },
    ],
  });

  const handleEditTask = useCallback(async (
    id, title, description, status, priority, users, taskComments: TaskComment[], taskChecklists: ChecklistItem[] ,
    completion_deadline, recurrent_type, client: Client, openByUser: User, taskTemplate?: TaskTemplate, personService?: PersonService, repeat_date_limit?: Date) => {

    if (openByUser) {
      setOpenByUsername(openByUser.username);
    }

    if (taskTemplate) {
      setTaskTemplateTitle(taskTemplate.title);
    }

    if (personService) {
      setPersonServiceTitle(personService.service.title);
    }

    setIsUpdate(true);
    setTaskId(id);
    setInputTitle(title);
    setInputDescription(description);
    setDropdownStatusValue(status);
    setDropdownPriorityValue(priority);
    setSelectedCompletionDateHour(null);
    setSelectedCompletionDateHour(completion_deadline);
    setDropdownRepetitionValue(recurrent_type);
    setSelectedClient({ value: client.id, label: client.fantasy_name });

    if (repeat_date_limit) {
      setSelectedRepeatDateLimit(repeat_date_limit);
    }

    setTaskChecklist(taskChecklists);

    switch (recurrent_type) {
      case 'no':
        setDropdownRepetitionLabel('não é recorrente');
        setIsRecurrentTask(false);
      break;
      case '7d':
        setDropdownRepetitionLabel('repete-se a cada 7 dias');
        setIsRecurrentTask(true);
      break;
      case '15d':
        setDropdownRepetitionLabel('repete-se a cada 15 dias');
        setIsRecurrentTask(true);
      break;
      case '30d':
        setDropdownRepetitionLabel('repete-se a cada 30 dias');
        setIsRecurrentTask(true);
      break;
      case '2m':
        setDropdownRepetitionLabel('repete-se a cada 2 meses');
        setIsRecurrentTask(true);
      break;
      case '4m':
        setDropdownRepetitionLabel('repete-se a cada 4 meses');
        setIsRecurrentTask(true);
      break;
      case '6m':
        setDropdownRepetitionLabel('repete-se a cada 6 meses');
        setIsRecurrentTask(true);
      break;
      case '12m':
        setDropdownRepetitionLabel('repete-se a cada 12 meses');
        setIsRecurrentTask(true);
      break;
      default:
      break;
    }

    taskComments.map(comment => {
      comment.createdAtLabel = `${
        new Date(comment.created_at).toLocaleDateString('pt-BR', {day: 'numeric', weekday: 'long', month: 'long', year: 'numeric'})} 
        às 
        ${new Date(comment.created_at).toLocaleTimeString('pt-BR')}`;
    });

    setTaskCommentsToShow(taskComments);

    const dataForUsersInTask: SelectDataFormat[] = [];
    users.map((user: User) => {
      dataForUsersInTask.push( {value: user.id, label: `@${user.username}`} );
    });
    setSelectedUsersInTask(dataForUsersInTask);

    toggleModal();

  }, [toggleModal]);

  const handleNewTask = () => {
    setIsUpdate(false);
    setTaskId('');
    setInputTitle('');
    setInputDescription('');
    setDropdownStatusValue('em aberto');
    setDropdownPriorityValue('normal');
    setSelectedUsersInTask([{value: user.id, label: `@${user.username}`}]);
    setSelectedCompletionDateHour(null);
    setSelectedRepeatDateLimit(null);
    setEditorState(EditorState.createEmpty());
    setTaskCommentsToShow([]);
    setIsRecurrentTask(false);
    setDropdownRepetitionValue('no-selected');
    setDropdownRepetitionLabel('selecione a recorrência');
    setSelectedClient(null);
    setTaskChecklist([]);
    setRepeatDateLimitLabel('');

    toggleModal();
  };

  const calculateChecklistPercentage = () => {
    var quantityChecked = 0;
    taskChecklist.map(checklistItem => {
      if (checklistItem.is_checked){
        quantityChecked = quantityChecked + 1;
      }
    });

    const percentage = quantityChecked * 100 / taskChecklist.length;

    setTaskChecklistPercentage(percentage);

    if (percentage === 100 && dropdownStatusValue !== 'concluída') {
      swal({
        title: "Marcar tarefa como concluída?",
        text: "Parece que 100% da atividade foi concluída. Posso salvar e marcar esta tarefa como concluída por você? ",
        icon: "info",
        buttons: ['Agora não', 'Ok, vamos lá :)'],
        dangerMode: false,
      })
      .then(async (willDoneTask) => {
        if (willDoneTask && dropdownStatusValue !== 'concluída') {
          setDropdownStatusValue('concluída');
          await swal("Uhu! Parabéns! Assinalei esta tarefa como concluída. Agora é só salvar quando terminar de editá-la :)", {
            icon: "success",
          });
        } else {
          swal("Ok, talvez queira fazer isto depois.");
        }
      });
    }
    
  }

  const getUsersForMention = async () => {

    try{

      const response = await api.get('users');

      setUsersInTask(() => {
        
        const data: User[] = response.data;
        const dataForUsersInTask: SelectDataFormat[] = [];

        data.map(user => {
          dataForUsersInTask.push( {value: user.id, label: `@${user.username}`} );
        });

        return dataForUsersInTask;
      });

      setUsersForMention(() => {
        
        const data: User[] = response.data;
        const dataForMentions: Mention[] = [];

        data.map(user => {
          dataForMentions.push( {text: user.show_name, value: user.username, url: `usuarios/${user.username}`} );
        });

        return dataForMentions;
      });

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

    }catch(err: any){
      if (err.response && err.response.status === 401) {
        signOut();
      }
    }

  }

  const handleSubmitTaskChecklistItem = async() => { 
    
    const item = inputChecklistItemRef.current!.value;

    if (!item.length){
      swal("Oppss!", 'O campo de descrição da etapa não pode ficar vazio. Descreva-o em uma única frase.', "error");
      return;
    }

    if (!isUpdate) {
      const checklistItems = taskChecklist;
      checklistItems.push(
        { id: uuidv4(), 
          item, 
          is_checked: false 
        }
      );
      setTaskChecklist(checklistItems);
      toggleChecklistPopover();
      return;
    }

    try {

      const data = {
        item,
        is_checked: false,
        checklisted_task: taskId
      }

      const response = await api.post(`task-checklists`, data);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

      if (response.data){
        const { id, item, is_checked} = response.data;
        const checklistItems = taskChecklist;
        checklistItems.push(
          { id, 
            item, 
            is_checked 
          }
        );
        setTaskChecklist(checklistItems);
        toggleChecklistPopover();
        calculateChecklistPercentage();
      }

    }catch(err: any) {
      if (err.errors) {
        swal("Oppss!", err.errors[0], "error");
      }else {
        swal("Oppss! ocorreu o seguinte erro:", err, "error");
      }
    }

  }

  const handleSubmitTaskComment = async() => {

    const htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const data = {
      comment: htmlContent,
      commented_task: taskId
    }

    const updateTaskComments = async() => {
      try {
        const response = await api.get<TaskComment[]>(`task-comments/${taskId}`);
        setTaskCommentsToShow(response.data);

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }
      }catch(err: any) {
        swal("Oppss!", err, "error");
      }
    }

    try {
      const response = await api.post('task-comments', data);
      swal("Feito!", "O comentário foi incluído!", "success");

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

      togglePopover();
      toggleChecklistPopover();
      updateTaskComments();
      update();
    }catch(err: any) {
      swal("Oppss!", err, "error");
    }
  }

  const handleSubmit = async() => {

    const schema = Yup.object().shape({
      title:
        Yup.string()
        .required('O título da tarefa precisa ser informado'),
      description:
        Yup.string()
        .required('Uma descrição para essa tarefa precisa ser atribuída'),
      completion_deadline:
        Yup.date()
        .required('Uma data prevista de entrega precisa ser infomada').nullable(),
      client:
        Yup.string()
        .required('Parece que você não vinculou esta tarefa a nenhum cliente existente.')
    });

    if (dropdownRepetitionValue === 'no-selected') {
      swal("Oppss!", 'O tipo de recorrência não foi selecionado.', "error");
      return;
    }

    let users: [{ id: string }] | null = null;

    selectedUsersInTask.map((user) => {
      users?.push({ id: user.value })
    });

    if (!users) {
      swal("Oppss!", 'Uma tarefa não pode ficar sem pelo menos um usuário responsável por ela.', "error");
      return;
    }

    setIsSaving(true);

    if (isUpdate) {

      const data = {
        title: inputTitle,
        description: inputDescription,
        status: dropdownStatusValue,
        priority: dropdownPriorityValue,
        users,
        completion_deadline: selectedCompletionDateHour,
        client: selectedClient?.value
      }

      if (taskChecklist.length){
        if (data.status === 'concluída' && taskChecklistPercentage !== 100) {
          swal("Oppss!", 'Parece que você está tentando concluir uma tarefa que não tem todos os itens do checklist assinalados.', "error");
          setIsSaving(false);
          return;
        }
      }

      try {

        await schema.validate(data, {abortEarly: false} );

        const response = await api.put(`tasks/${taskId}`, data);
        toggleModal();
        swal("Tudo certo :)", "A tarefa foi atualizada!", "success");

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }
      }catch(err: any) {
        if (err.errors) {
          swal("Oppss!", err.errors[0], "error");
        }else {
          swal("Oppss! ocorreu o seguinte erro:", err, "error");
        }
        setIsSaving(false);
      }
    }else{

      const data = {
        title: inputTitle,
        description: inputDescription,
        status: dropdownStatusValue,
        priority: dropdownPriorityValue,
        users,
        completion_deadline: selectedCompletionDateHour,
        recurrent_type: dropdownRepetitionValue,
        client: selectedClient?.value,
        task_checklists: taskChecklist,
        repeat_date_limit: selectedRepeatDateLimit
      }

      try {

        await schema.validate(data, {abortEarly: false} );

        const response = await api.post('tasks', data);
        toggleModal();
        swal("Feito!", "A tarefa foi incluída!", "success");

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }
      }catch(err: any) {
        if (err.errors) {
          swal("Oppss!", err.errors[0], "error");
        }else {
          swal("Oppss! ocorreu o seguinte erro:", err, "error");
        }
        setIsSaving(false);
      }
    }
    
    setIsSaving(false);

    update();

  }

  const handleChecklistCheckboxItemChange = async (checklistItemId: string, isChecked: boolean) => {
    const checklist = taskChecklist;
    checklist.map((item) => {
      if (item.id === checklistItemId){
        item.is_checked = !isChecked;
      }
    })
    setTaskChecklist(checklist);
    calculateChecklistPercentage();

    const data = {
      is_checked: !isChecked
    }

    if (isUpdate){
      await api.put(`task-checklists/${checklistItemId}`, data);
    }
  }

  const handleChangeSelectedYear = (year: number) => {

    const monthName = dropdownMonthSelectionValue;

    setDropdownYearSelectionValue(year);
    setFilterInterval(monthName, year);
  }

  const handleChangeSelectedClient = (id?: string, client?: string) => {

    if (!client || !id){
      setDropdownClientSelectionValue('Todos os clientes');
      setClientFilterSelectionId('all');
      return;
    }

    setClientFilterSelectionId(id);
    setDropdownClientSelectionValue(client);

  }

  const setFilterInterval = (monthName: string, year: number) => {
    
    switch (monthName) {
      case 'Janeiro':
        setFirstDayOfMonth(startOfMonth(
          new Date(`${year.toString()}-01-01T03:00:00.000Z`
        )));
        setLastDayOfMonth(endOfMonth(
          new Date(`${year.toString()}-01-01T03:00:00.000Z`
        )));
        break;
      case 'Fevereiro':
        setFirstDayOfMonth(startOfMonth(
          new Date(`${year.toString()}-02-01T03:00:00.000Z`
        )));
        setLastDayOfMonth(endOfMonth(
          new Date(`${year.toString()}-02-01T03:00:00.000Z`
        )));
        break;
      case 'Março':
        setFirstDayOfMonth(startOfMonth(
          new Date(`${year.toString()}-03-01T03:00:00.000Z`
        )));
        setLastDayOfMonth(endOfMonth(
          new Date(`${year.toString()}-03-01T03:00:00.000Z`
        )));
        break;
      case 'Abril':
        setFirstDayOfMonth(startOfMonth(
          new Date(`${year.toString()}-04-01T03:00:00.000Z`
        )));
        setLastDayOfMonth(endOfMonth(
          new Date(`${year.toString()}-04-01T03:00:00.000Z`
        )));
        break;
      case 'Maio':
        setFirstDayOfMonth(startOfMonth(
          new Date(`${year.toString()}-05-01T03:00:00.000Z`
        )));
        setLastDayOfMonth(endOfMonth(
          new Date(`${year.toString()}-05-01T03:00:00.000Z`
        )));
        break;
      case 'Junho':
        setFirstDayOfMonth(startOfMonth(
          new Date(`${year.toString()}-06-01T03:00:00.000Z`
        )));
        setLastDayOfMonth(endOfMonth(
          new Date(`${year.toString()}-06-01T03:00:00.000Z`
        )));
        break;
      case 'Julho':
        setFirstDayOfMonth(startOfMonth(
          new Date(`${year.toString()}-07-01T03:00:00.000Z`
        )));
        setLastDayOfMonth(endOfMonth(
          new Date(`${year.toString()}-07-01T03:00:00.000Z`
        )));
        break;
      case 'Agosto':
        setFirstDayOfMonth(startOfMonth(
          new Date(`${year.toString()}-08-01T03:00:00.000Z`
        )));
        setLastDayOfMonth(endOfMonth(
          new Date(`${year.toString()}-08-01T03:00:00.000Z`
        )));
        break;
      case 'Setembro':
        setFirstDayOfMonth(startOfMonth(
          new Date(`${year.toString()}-09-01T03:00:00.000Z`
        )));
        setLastDayOfMonth(endOfMonth(
          new Date(`${year.toString()}-09-01T03:00:00.000Z`
        )));
        break;
      case 'Outubro':
        setFirstDayOfMonth(startOfMonth(
          new Date(`${year.toString()}-10-01T03:00:00.000Z`
        )));
        setLastDayOfMonth(endOfMonth(
          new Date(`${year.toString()}-10-01T03:00:00.000Z`
        )));
        break;
      case 'Novembro':
        setFirstDayOfMonth(startOfMonth(
          new Date(`${year.toString()}-11-01T03:00:00.000Z`
        )));
        setLastDayOfMonth(endOfMonth(
          new Date(`${year.toString()}-11-01T03:00:00.000Z`
        )));
        break;
      case 'Dezembro':
        setFirstDayOfMonth(startOfMonth(
          new Date(`${year.toString()}-12-01T03:00:00.000Z`
        )));
        setLastDayOfMonth(endOfMonth(
          new Date(`${year.toString()}-12-01T03:00:00.000Z`
        )));
        break;
      default:
        return null;
    }

    update();

  }

  const handleChangeSelectedMonth = (monthName: string) => {

    const year = dropdownYearSelectionValue;

    setDropdownMonthSelectionValue(monthName);
    setFilterInterval(monthName, year);

  }

  return (
    <React.Fragment>
      <GlobalStyle />
      <DefaultNavbar />
      <div className="header pb-4 pt-5 pt-md-2">
        <Container fluid>

          <Modal className="modal-lg" isOpen={modal} toggle={toggleModal}>
            <ModalHeader className="bg-secondary" toggle={toggleModal}>
              { isUpdate ? 'Editar Tarefa' : 'Adicionar Tarefa' }
            </ModalHeader>
            <ModalBody className="bg-secondary">
              <Row>
                <Col xs="7" sm="7">
                  <Form role="form">

                    <h5> <strong>Dados da tarefa </strong> </h5>

                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <MdTitle/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input 
                          id="inputTitle"
                          innerRef={inputTitleRef} 
                          name="title" 
                          placeholder="Dê um título a esta tarefa" 
                          type="text"
                          value={inputTitle}
                          onChange={() => { setInputTitle(inputTitleRef.current?.value) }}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <BsCardText/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input 
                          id="inputDescription" 
                          innerRef={inputDescriptionRef} 
                          name="description" 
                          placeholder="Fale um pouco mais sobre o que precisa ser feito. Mais detalhes ajudarão a entender essa tarefa mais tarde." 
                          type="textarea"
                          value={inputDescription}
                          onChange={() => { setInputDescription(inputDescriptionRef.current?.value) }}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <Select
                        options={usersInTask} 
                        placeholder="Quem faz parte dessa tarefa?" 
                        components={animatedComponents}
                        noOptionsMessage={ () => "Ninguém foi encontrado :(" }
                        isMulti
                        value={selectedUsersInTask}
                        onChange={ handleChangeSelectUsersInTask }
                      />  
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <Select 
                        placeholder = "Selecione o cliente"
                        noOptionsMessage={() => "Sem mais opções"}
                        options={clients} 
                        onChange={ handleChangeSelectClient }
                        value={selectedClient}
                      />
                    </FormGroup>

                    <br/>
                    <h5> <strong>Prioridade, repetição e entrega </strong> </h5>

                    <FormGroup className="mb-3">
                      <InputGroup>
                        <Dropdown isOpen={dropdownStatusOpen} toggle={toggleDropdownStatus}>
                          <DropdownToggle caret>
                            { dropdownStatusValue === 'em aberto' ? <BsStopwatch className="text-yellow"/> : undefined }
                            { dropdownStatusValue === 'em andamento' ? <AiOutlineSync className="text-info"/> : undefined }
                            { dropdownStatusValue === 'arquivada' ? <BsArchive className="text-warning"/> : undefined }
                            { dropdownStatusValue === 'concluída' ? <BsCheckSquare className="text-success"/> : undefined }
                            &nbsp;{ dropdownStatusValue }
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              className="text-muted" 
                              header
                            >
                              selecione o status
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => { setDropdownStatusValue('em aberto') }}
                            >
                              <BsStopwatch className="text-yellow"/>em aberto
                            </DropdownItem>
                            <DropdownItem 
                              disabled={!isUpdate}
                              onClick={() => { setDropdownStatusValue('em andamento') }}
                            >
                              <AiOutlineSync className="text-info"/>em andamento
                            </DropdownItem>
                            <DropdownItem 
                              disabled={!isUpdate}
                              onClick={() => { setDropdownStatusValue('arquivada') }}
                            >
                              <BsArchive className="text-warning"/>arquivada
                            </DropdownItem>
                            <DropdownItem 
                              disabled={!isUpdate}
                              onClick={() => { setDropdownStatusValue('concluída') }}
                            >
                              <BsCheckSquare className="text-success"/>concluída
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>

                        &nbsp;&nbsp;
                        <Dropdown isOpen={dropdownPriorityOpen} toggle={toggleDropdownPriority}>
                          <DropdownToggle caret>
                            { dropdownPriorityValue === 'baixa' ? <GrStatusInfoSmall className="text-success"/> : undefined }
                            { dropdownPriorityValue === 'normal' ? <GrStatusInfoSmall className="text-yellow"/> : undefined }
                            { dropdownPriorityValue === 'alta' ? <GrStatusInfoSmall className="text-danger"/> : undefined }
                            &nbsp;{ dropdownPriorityValue }
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              className="text-muted" 
                              header
                            >
                              selecione a prioridade
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => { setDropdownPriorityValue('baixa') }}
                            >
                              <GrStatusInfoSmall className="text-success"/>baixa
                            </DropdownItem>
                            <DropdownItem 
                              onClick={() => { setDropdownPriorityValue('normal') }}
                            >
                              <GrStatusInfoSmall className="text-yellow"/>normal
                            </DropdownItem>
                            <DropdownItem 
                              onClick={() => { setDropdownPriorityValue('alta') }}
                            >
                              <GrStatusInfoSmall className="text-danger"/>alta
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>

                        <br/><br/>

                        <Dropdown isOpen={dropdownRepetitionOpen} toggle={toggleDropdownRepetition}>
                          <DropdownToggle caret>
                            { dropdownRepetitionValue === 'no-selected' ? <BsCheckSquare className="text-primary"/> : undefined }
                            { dropdownRepetitionValue === 'no' ? <BsCheckSquare className="text-danger"/> : undefined }
                            { dropdownRepetitionValue === '7d' ? <AiOutlineSync className="text-success"/> : undefined }
                            { dropdownRepetitionValue === '15d' ? <AiOutlineSync className="text-success"/> : undefined }
                            { dropdownRepetitionValue === '30d' ? <AiOutlineSync className="text-success"/> : undefined }
                            { dropdownRepetitionValue === '2m' ? <AiOutlineSync className="text-success"/> : undefined }
                            { dropdownRepetitionValue === '4m' ? <AiOutlineSync className="text-success"/> : undefined }
                            { dropdownRepetitionValue === '6m' ? <AiOutlineSync className="text-success"/> : undefined }
                            { dropdownRepetitionValue === '12m' ? <AiOutlineSync className="text-success"/> : undefined }
                            &nbsp;{ dropdownRepetitionLabel }
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              className="text-muted" 
                              header
                            >
                              selecione a recorrência
                            </DropdownItem>
                            <DropdownItem
                              disabled={isUpdate}
                              onClick={() => { setDropdownRepetitionValue('no'); setDropdownRepetitionLabel('não é recorrente'); }}
                            >
                              <BsCheckSquare className="text-danger"/>não é recorrente
                            </DropdownItem>
                            <DropdownItem 
                              disabled={isUpdate}
                              onClick={() => { setDropdownRepetitionValue('7d'); setDropdownRepetitionLabel('repete-se a cada 7 dias'); }}
                            >
                              <AiOutlineSync className="text-success"/>repete-se a cada 7 dias
                            </DropdownItem>
                            <DropdownItem 
                              disabled={isUpdate}
                              onClick={() => { setDropdownRepetitionValue('15d'); setDropdownRepetitionLabel('repete-se a cada 15 dias'); }}
                            >
                              <AiOutlineSync className="text-success"/>repete-se a cada 15 dias
                            </DropdownItem>
                            <DropdownItem 
                              disabled={isUpdate}
                              onClick={() => { setDropdownRepetitionValue('30d'); setDropdownRepetitionLabel('repete-se a cada 30 dias'); }}
                            >
                              <AiOutlineSync className="text-success"/>repete-se a cada 30 dias
                            </DropdownItem>
                            <DropdownItem 
                              disabled={isUpdate}
                              onClick={() => { setDropdownRepetitionValue('2m'); setDropdownRepetitionLabel('repete-se a cada 2 meses (bimestral)'); }}
                            >
                              <AiOutlineSync className="text-success"/>repete-se a cada 2 meses (bimestral)
                            </DropdownItem>
                            <DropdownItem 
                              disabled={isUpdate}
                              onClick={() => { setDropdownRepetitionValue('4m'); setDropdownRepetitionLabel('repete-se a cada 4 meses (quadrimestral)'); }}
                            >
                              <AiOutlineSync className="text-success"/>repete-se a cada 4 meses (quadrimestral)
                            </DropdownItem>
                            <DropdownItem 
                              disabled={isUpdate}
                              onClick={() => { setDropdownRepetitionValue('6m'); setDropdownRepetitionLabel('repete-se a cada 6 meses (semestral)'); }}
                            >
                              <AiOutlineSync className="text-success"/>repete-se a cada 6 meses (semestral)
                            </DropdownItem>
                            <DropdownItem 
                              disabled={isUpdate}
                              onClick={() => { setDropdownRepetitionValue('12m'); setDropdownRepetitionLabel('repete-se a cada 12 meses (anual)'); }}
                            >
                              <AiOutlineSync className="text-success"/>repete-se a cada 12 meses (anual)
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </InputGroup>
                    </FormGroup>

                    {
                      !isUpdate && (dropdownRepetitionValue !== 'no-selected' && dropdownRepetitionValue !== 'no')
                      ?
                        <FormGroup className="mb-3">
                          <InputGroup id="TooltipRepetitionDateLimit" className="input-group-alternative">
                            <MuiPickersUtilsProvider locale={ptBrLocale} utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disabled={isRecurrentTask}
                                margin="normal"
                                id="date-picker-dialog"
                                label="Se repete até no máximo..."
                                format="dd/MM/yyyy"
                                cancelLabel="Cancelar"
                                invalidDateMessage="Formato inválido"
                                value={selectedRepeatDateLimit}
                                onChange={handleRepeatDateLimit}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </InputGroup>
                          <Tooltip placement="auto" isOpen={tooltipRepetionDateLimitOpen} target="TooltipRepetitionDateLimit" toggle={() => toggleTooltip('repeat_date_limit')}>
                            Até quando essa tarefa deve se repetir? Normalmente, deve-se configurar até o final do contrato. Podendo ser estendida, se necessário, em caso de renovação, por exemplo. Não confundir com a data de entrega.
                          </Tooltip>
                        </FormGroup>   
                      : null
                    }

                    <Row id="TooltipCompletionDeadline" form>
                      <Col md={7}>
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <MuiPickersUtilsProvider locale={ptBrLocale} utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disabled={isRecurrentTask}
                                margin="normal"
                                id="date-picker-dialog"
                                label="Até quando deve ser feito?"
                                format="dd/MM/yyyy"
                                cancelLabel="Cancelar"
                                invalidDateMessage="Formato inválido"
                                value={selectedCompletionDateHour}
                                onChange={handleCompletionDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={5}>
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <MuiPickersUtilsProvider locale={ptBrLocale} utils={DateFnsUtils}>
                              <KeyboardTimePicker
                                disabled={isRecurrentTask}
                                ampm={false}
                                margin="normal"
                                id="time-picker"
                                label="Em qual horário?"
                                cancelLabel="Cancelar"
                                invalidDateMessage="Formato inválido"
                                value={selectedCompletionDateHour}
                                onChange={handleCompletionDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Tooltip placement="auto" isOpen={tooltipCompletionDeadlineOpen} target="TooltipCompletionDeadline" toggle={() => toggleTooltip('completion_deadline')}>
                      É aqui que configuramos até que dia e horário essa tarefa deve ser realizada. Em caso de tarefas recorrentes, deve-se selecionar a primeira entrega e o sistema cuida do restante :)
                    </Tooltip>
                    
                    <Row form>
                      <Col xs="12" md="12" sm="12">
                        {
                          isUpdate
                          ?
                          <>
                            {
                              personServiceTitle && taskTemplateTitle 
                              ? <Alert className="alert-default">
                                  Esta tarefa foi gerada automaticamente a partir do modelo de tarefa <strong>{personServiceTitle} </strong> e do serviço <strong>{taskTemplateTitle}</strong>
                                </Alert>
                              : openByUsername === user.username
                              ? <Alert className="alert-default">
                                  Esta tarefa foi criada manualmente por <strong>você.</strong>
                                </Alert>
                              : <Alert className="alert-default">
                                  {`Esta tarefa foi criada manualmente por <strong>${openByUsername}</strong>.`}
                                </Alert>
                            }
                            {
                              isRecurrentTask 
                              ? <Alert className="alert-default">
                                  {`Esta tarefa está configurada para se repetir até, no máximo ${repeatDateLimitLabel}.`}
                                </Alert>
                              : null
                            }
                          </>
                          : null
                        }
                      </Col>
                    </Row>

                  </Form>
                </Col>
                
                <Col xs="5" sm="5">

                  <React.Fragment>
                    <h5> <strong>Checklist | lista de etapas</strong> </h5>
                    <ChecklistTaskContainer 
                      icon={FaComments} 
                      statusColor="primary"
                    >
                      <React.Fragment>

                        {!taskChecklist.length 
                          ? <i><small>ainda não existem itens de checklist para exibir...</small></i>
                          : taskChecklist.map((checklistItem) => (
                          <FormGroup check>
                            <Label check>
                              <Input 
                                key={ checklistItem.id } 
                                type="checkbox" 
                                defaultChecked={ checklistItem.is_checked } 
                                onChange={() => {handleChecklistCheckboxItemChange(checklistItem.id, checklistItem.is_checked)}} 
                              />{' '}
                              { checklistItem.item }
                            </Label>
                          </FormGroup>
                        ))}

                      </React.Fragment>

                    </ChecklistTaskContainer>

                    <div className="text-center"><small> <strong>{Math.round(taskChecklistPercentage)}% concluído</strong> </small></div>
                    {
                      taskChecklistPercentage === 100
                      ? <Progress color='success' value={Math.round(taskChecklistPercentage)} />
                      : <Progress color='primary' value={Math.round(taskChecklistPercentage)} />
                    }

                    <Button id="PopoverWriteChecklist" size="sm" color="primary" type="button">
                      Adicionar etapa
                    </Button>
                    <Popover placement="bottom" isOpen={popoverChecklistOpen} target="PopoverWriteChecklist" toggle={toggleChecklistPopover}>
                      <PopoverHeader>Adicionar etapa 
                        <a onClick={toggleChecklistPopover}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        x
                        </a>
                      </PopoverHeader>
                      <PopoverBody>
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <MdTitle/>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input 
                              id="inputChecklistItem"
                              innerRef={inputChecklistItemRef} 
                              name="item" 
                              placeholder="Descreva em uma frase" 
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>
                        <br/>
                        <Button size="sm" onClick={handleSubmitTaskChecklistItem} color="primary" type="button">
                          Confirmar etapa
                        </Button>
                      </PopoverBody>
                    </Popover>
                  </React.Fragment>

                  <br/><br/>

                  <React.Fragment>
                    <h5> <strong>Comentários </strong> </h5>
                    <CommentsContainer 
                      icon={FaComments} 
                      statusColor="primary"
                    >
                      <React.Fragment>
                        {!taskCommentsToShow.length 
                          ? <i><small>ainda não existem comentários para exibir...</small></i>
                          : taskCommentsToShow.map((comment) => (
                          <CommentCard
                            key={ comment.id }
                            onClick={() => {}}
                            comment={ comment.comment }
                            username= { comment.commentedByUser.username }
                            openAtLabel={ comment.createdAtLabel }
                          />
                        ))}
                      </React.Fragment>
                      
                    </CommentsContainer>
                    <Button id="PopoverWriteComment" disabled={!isUpdate} size="sm" color="primary" type="button">
                      Escrever Comentário
                    </Button>
                    <Popover placement="bottom" isOpen={popoverOpen} target="PopoverWriteComment" toggle={togglePopover}>
                      <PopoverHeader>Escrever comentário 
                        <a onClick={togglePopover}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;x
                        </a>
                      </PopoverHeader>
                      <PopoverBody>
                        <Editor
                          editorState={editorState}
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class"
                          onEditorStateChange={setEditorState}
                          locale="pt"
                          mention={{
                            separator: ' ',
                            trigger: '@',
                            suggestions: usersForMention,
                          }}
                          toolbar={
                            {
                              options: ['inline', 'link'],
                              inline: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: [
                                  'bold',
                                  'italic',
                                  'underline',
                                  'strikethrough'
                                ],
                                
                              },
                            }
                          }
                        />
                        <br/>
                        <Button size="sm" onClick={handleSubmitTaskComment} color="primary" type="button">
                          Comentar
                        </Button>
                      </PopoverBody>
                    </Popover>
                  </React.Fragment>

                </Col>

              </Row>
            </ModalBody>
            <ModalFooter className="bg-secondary">
              <Button color="primary" disabled={isSaving} onClick={handleSubmit}>
                { isSaving
                  ? <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> Salvando...
                    </>
                  : 'Salvar'
                }
              </Button>{' '}
              <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
            </ModalFooter>
          </Modal>

          <div className="header-body">
            <h3>
              Minhas Tarefas&nbsp;
              <Button onClick={handleNewTask} className="btn-icon btn-3" size="sm" color="primary" type="button">
                <BsPlusCircleFill/>
                <span className="btn-inner--text">Nova tarefa</span>
              </Button>
              <Button onClick={update} disabled={isUpdating} className="btn-icon btn-3" size="sm" color="primary" type="button">
                {
                  isUpdating
                  ? <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  : <FiRefreshCcw />
                }
              </Button>

              <Dropdown isOpen={dropdownMonthSelectionOpen} toggle={toggleDropdownMonthSelection}>
                <DropdownToggle caret>
                  <FaRegCalendarCheck className="text-primary"/>
                  &nbsp;{ dropdownMonthSelectionValue }
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className="text-muted" 
                    header
                  >
                    selecione o mês
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => { handleChangeSelectedMonth('Janeiro') }}
                  >
                    <BiCalendar className="text-primary"/>Janeiro
                  </DropdownItem>
                  <DropdownItem 
                    onClick={() => { handleChangeSelectedMonth('Fevereiro') }}
                  >
                    <BiCalendar className="text-primary"/>Fevereiro
                  </DropdownItem>
                  <DropdownItem 
                    onClick={() => { handleChangeSelectedMonth('Março') }}
                  >
                    <BiCalendar className="text-primary"/>Março
                  </DropdownItem>
                  <DropdownItem 
                    onClick={() => { handleChangeSelectedMonth('Abril') }}
                  >
                    <BiCalendar className="text-primary"/>Abril
                  </DropdownItem>
                  <DropdownItem 
                    onClick={() => { handleChangeSelectedMonth('Maio') }}
                  >
                    <BiCalendar className="text-primary"/>Maio
                  </DropdownItem>
                  <DropdownItem 
                    onClick={() => { handleChangeSelectedMonth('Junho') }}
                  >
                    <BiCalendar className="text-primary"/>Junho
                  </DropdownItem>
                  <DropdownItem 
                    onClick={() => { handleChangeSelectedMonth('Julho') }}
                  >
                    <BiCalendar className="text-primary"/>Julho
                  </DropdownItem>
                  <DropdownItem 
                    onClick={() => { handleChangeSelectedMonth('Agosto') }}
                  >
                    <BiCalendar className="text-primary"/>Agosto
                  </DropdownItem>
                  <DropdownItem 
                    onClick={() => { handleChangeSelectedMonth('Setembro') }}
                  >
                    <BiCalendar className="text-primary"/>Setembro
                  </DropdownItem>
                  <DropdownItem 
                    onClick={() => { handleChangeSelectedMonth('Outubro') }}
                  >
                    <BiCalendar className="text-primary"/>Outubro
                  </DropdownItem>
                  <DropdownItem 
                    onClick={() => { handleChangeSelectedMonth('Novembro') }}
                  >
                    <BiCalendar className="text-primary"/>Novembro
                  </DropdownItem>
                  <DropdownItem 
                    onClick={() => { handleChangeSelectedMonth('Dezembro') }}
                  >
                    <BiCalendar className="text-primary"/>Dezembro
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <Dropdown isOpen={dropdownYearSelectionOpen} toggle={toggleDropdownYearSelection}>
                <DropdownToggle caret>
                  <FaRegCalendarCheck className="text-primary"/>
                  &nbsp;{ dropdownYearSelectionValue }
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className="text-muted" 
                    header
                  >
                    selecione o ano
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => { handleChangeSelectedYear(2021) }}
                  >
                    <BiCalendar className="text-primary"/>2021
                  </DropdownItem>
                  <DropdownItem 
                    onClick={() => { handleChangeSelectedYear(2022) }}
                  >
                    <BiCalendar className="text-primary"/>2022
                  </DropdownItem>
                  
                </DropdownMenu>
              </Dropdown>

              <Dropdown isOpen={dropdownClientSelectionOpen} toggle={toggleDropdownClientSelection}>
                <DropdownToggle caret>
                  { dropdownClientSelectionValue === 'Todos os clientes'
                    ? <FiUsers className="text-primary"/>
                    : <FiUser className="text-primary"/>
                  }
                  
                  &nbsp;{ dropdownClientSelectionValue }
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className="text-muted" 
                    header
                  >
                    Filtrar por cliente
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => { handleChangeSelectedClient() }}
                  >
                    <FiUsers className="text-primary"/>Todos os clientes
                  </DropdownItem>

                  {!clients.length 
                    ? <i>ainda não existem clientes para filtrar...</i>
                    : clients.map((client) => (
                    <DropdownItem
                    onClick={() => { handleChangeSelectedClient(client.value, client.label) }}
                    >
                      <FiUser className="text-primary"/>{ client.label }
                    </DropdownItem>
                  ))}
                  
                </DropdownMenu>
              </Dropdown>
              
              <div className='float-sm-right'>
                <Button onClick={() => { history.push('/tarefas/templates') }} className="btn-icon btn-3" size="sm" color="primary" type="button">
                  <AiOutlineBlock/>
                  <span className="btn-inner--text">Modelos de tarefas</span>
                </Button>
              </div>

            </h3>
            <Row>
              <Col lg="6" xl="3">
                <div className={cardTasksWaitingVisibility} data-animation-tasks-waiting>
                  <StatusTasksContainer 
                    title="Em aberto" 
                    icon={BsStopwatch} 
                    statusColor="yellow"
                  >
                    <React.Fragment>
                      {!tasksWaiting.length 
                        ? <i>ainda não existem lançamentos para exibir...</i>
                        : tasksWaiting.map((task) => (
                        <TaskCard
                          isDone={false}
                          key={ task.id }
                          onClick={() => { handleEditTask(
                            task.id,
                            task.title,
                            task.description,
                            task.status,
                            task.priority,
                            task.users,
                            task.taskComments,
                            task.taskChecklists,
                            task.completion_deadline,
                            task.recurrent_type,
                            task.client,
                            task.openByUser,
                            task.taskTemplate,
                            task.personService
                          )}}
                          title={ task.title }
                          description= { task.description }
                          openAt={ task.createdAtLabel }
                          priority={ task.priority }
                          priorityColor={ task.priorityColor }
                          deadlineDate={task.completionDeadlineLabel}
                          checklistPercentage={task.checklistPercentage}
                        />
                      ))}
                    </React.Fragment>
                  </StatusTasksContainer>
                </div>
              </Col>
              <Col lg="6" xl="3">
                <div className={cardTasksInProgressVisibility} data-animation-tasks-inprogress>
                  <StatusTasksContainer 
                    title="Em andamento" 
                    icon={AiOutlineSync} 
                    statusColor="info"
                  >
                    <React.Fragment>
                      {!tasksInProgress.length 
                        ? <i>ainda não existem lançamentos para exibir...</i>
                        : tasksInProgress.map((task) => (
                        <TaskCard
                          isDone={false}
                          key={ task.id }
                          onClick={() => { handleEditTask(
                            task.id,
                            task.title,
                            task.description,
                            task.status,
                            task.priority,
                            task.users,
                            task.taskComments,
                            task.taskChecklists,
                            task.completion_deadline,
                            task.recurrent_type,
                            task.client,
                            task.openByUser,
                            task.taskTemplate,
                            task.personService
                          )}}
                          title={ task.title }
                          description= { task.description }
                          openAt={ task.createdAtLabel }
                          priority={ task.priority }
                          priorityColor={ task.priorityColor }
                          deadlineDate={task.completionDeadlineLabel}
                          checklistPercentage={task.checklistPercentage}
                        />
                      ))}
                    </React.Fragment>
                  </StatusTasksContainer>
                </div>
              </Col>
              <Col lg="6" xl="3">
                <div className={cardTasksArchivedVisibility} data-animation-tasks-archived>
                  <StatusTasksContainer 
                    title="Arquivadas" 
                    icon={BsArchive} 
                    statusColor="warning"
                  >
                    <React.Fragment>
                      {!tasksArchived.length 
                        ? <i>ainda não existem lançamentos para exibir...</i>
                        : tasksArchived.map((task) => (
                        <TaskCard
                          isDone={true}
                          key={ task.id }
                          onClick={() => { handleEditTask(
                            task.id,
                            task.title,
                            task.description,
                            task.status,
                            task.priority,
                            task.users,
                            task.taskComments,
                            task.taskChecklists,
                            task.completion_deadline,
                            task.recurrent_type,
                            task.client,
                            task.openByUser,
                            task.taskTemplate,
                            task.personService
                          )}}
                          title={ task.title }
                          description= { task.description }
                          openAt={ task.createdAtLabel }
                          priority={ task.priority }
                          priorityColor={ task.priorityColor }
                          deadlineDate={task.completionDeadlineLabel}
                          checklistPercentage={task.checklistPercentage}
                        />
                      ))}
                    </React.Fragment>
                  </StatusTasksContainer>
                </div>
              </Col>
              <Col lg="6" xl="3">
                <div className={cardTasksDoneVisibility} data-animation-tasks-done>
                  <StatusTasksContainer 
                    title="Concluídas" 
                    icon={BsCheckSquare} 
                    statusColor="success"
                  >
                    <React.Fragment>
                      {!tasksDone.length 
                        ? <i>ainda não existem lançamentos para exibir...</i>
                        : tasksDone.map((task) => (
                        <TaskCard
                          isDone={true}
                          key={ task.id }
                          onClick={() => { handleEditTask(
                            task.id,
                            task.title,
                            task.description,
                            task.status,
                            task.priority,
                            task.users,
                            task.taskComments,
                            task.taskChecklists,
                            task.completion_deadline,
                            task.recurrent_type,
                            task.client,
                            task.openByUser,
                            task.taskTemplate,
                            task.personService
                          )}}
                          title={ task.title }
                          description= { task.description }
                          openAt={ task.createdAtLabel }
                          priority={ task.priority }
                          priorityColor={ task.priorityColor }
                          deadlineDate={task.completionDeadlineLabel}
                          checklistPercentage={task.checklistPercentage}
                        />
                      ))}
                    </React.Fragment>
                  </StatusTasksContainer>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <GradientFooter/>
    </React.Fragment>
  );
}

export default MyTasks;