import React from 'react';
import {
  Progress,
  Toast,
  ToastHeader,
  ToastBody
} from 'reactstrap';

import { BsCalendar } from 'react-icons/bs';

import { Task, CustomToast } from './styles';

interface IProps {
  title: string;
  isDone: boolean;
  priority: string;
  priorityColor: string;
  description: string;
  openAt: string;
  deadlineDate: string;
  checklistPercentage: number;
  onClick(): void;
}

const TaskCard = (props: IProps) => {

  return (
    <Task isDone={props.isDone} onClick={ props.onClick } >
      <CustomToast className="card-stats shadow mb-4 mb-xl-0">
        <ToastHeader icon={props.priorityColor}>
          {
            props.title.length < 50
            ? props.title
            : `${props.title.substr(0,47)}...`
          }
        </ToastHeader>
        <ToastBody>
          {
            props.description.length < 75
            ? props.description
            : `${props.description.substr(0,72)}...`
          }
          <br/>
          <small className="text-muted text-sm">
            <BsCalendar/> criada { props.openAt }
          </small>
          <br/>
          <small className="text-muted text-sm"><BsCalendar/> deve ser entregue <strong>{ props.deadlineDate } </strong></small>
          {
            props.checklistPercentage
            ? 
            <>
              <br/><br/>
              {
                props.checklistPercentage === 100
                ? <>
                    <Progress color='success' value={Math.round(props.checklistPercentage)} />
                    <div className="text-center text-success"><small> <strong>{Math.round(props.checklistPercentage)}% concluído</strong> </small></div>
                  </>
                : <>
                    <Progress color='primary' value={Math.round(props.checklistPercentage)} />
                    <div className="text-center text-primary"><small> <strong>{Math.round(props.checklistPercentage)}% concluído</strong> </small></div>
                  </>
              }
            </>
            : 
            <></>
          }
        </ToastBody>
      </CustomToast>
    </Task>
  );

}

export default TaskCard;