import { createContext } from 'react';

interface SignInCredentials {
  email: string;
  password: string
}

export interface Base64Image {
  source: string;
}

export interface User {
  id: string;
  email: string;
  fullname: string;
  show_name: string;
  created_at: Date;
  deleted_at: Date;
  is_active: boolean;
  is_admin: boolean;
  is_sup: boolean;
  phone: string;
  updated_at: Date;
  username: string;
  profession: string;
  bio: string;
  profile_pic: string;
}

interface AuthContextData {
  user: User;
  token: string;
  profilePicBase64: Base64Image;
  authenticated: boolean;
  signIn(data: SignInCredentials): Promise<void>;
  signOut(): void;
  refreshToken( newToken: string ): void;
}

const AuthContext = createContext<AuthContextData>( {} as AuthContextData );

export default AuthContext;